import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import {
  Container,
  Button,
  Table,
  Form as BSForm,
  Modal,
} from "react-bootstrap";
import Icon from "@mdi/react";
import {
  mdiFloppy,
  mdiPlus,
  mdiClose,
  mdiFolderOpen,
  mdiOfficeBuilding,
  mdiHospitalBuilding,
  mdiTrashCan,
} from "@mdi/js";

import {
  FormErrors,
  Place,
  HousePlace,
  HealthBuildingPlace,
  PlaceType,
} from "../types";
import { useStores } from "../hooks/useStores";

import { TextInput } from "../components/TextInput";
import { ToggleInput } from "../components/ToggleInput";
import {
  TabbedRadioInput,
  TabbedRadioInputContainer,
  TabbedRadioInputSeparator,
} from "../components/TabbedRadioInput";
import { ErrorLabel } from "../components/ErrorLabel";
import { RadioInputOption } from "../components/RadioInput";
import { CheckboxInput } from "../components/CheckboxInput";
import { LoadingMessage } from "../components/LoadingMessage";
import { ToggleInputList } from "../components/ToggleInputList";
import { AddressInput } from "../components/AddressInput";

export const UserPlaces: React.FC = observer(() => {
  const [placeToEdit, setPlaceToEdit] = useState<Place>();
  const [placeToDelete, setPlaceToDelete] = useState<Place>();
  const [isAddingPlace, setIsAddingPlace] = useState(false);
  const { t } = useTranslation();
  const { session } = useStores();

  useEffect(() => {
    session.fetchPlaces();
    session.fetchAllPredefinedOptions();
  }, []);

  const onEditPlace = (place: Place) => {
    session.updatePlace(place);
    setPlaceToEdit(undefined);
  };

  const onCancelEditPlace = () => setPlaceToEdit(undefined);

  const onAddPlace = (place: Place) => {
    session.addPlace(place);
    setIsAddingPlace(false);
  };

  const onCancelAddPlace = () => setIsAddingPlace(false);

  const cancelDeletePlace = () => {
    setPlaceToDelete(undefined);
  };

  const confirmDeletePlace = () => {
    if (placeToDelete) {
      session.deletePlace(placeToDelete.id);
      setPlaceToDelete(undefined);
    }
  };

  const canEditPlaces = !session.isLoadingPredefinedOptions;

  return (
    <Container>
      <div className="main-title">
        <h4>{t("reservedArea.addresses")}</h4>
        <Button
          variant="outline-secondary"
          onClick={() => setIsAddingPlace(true)}
        >
          <Icon size={1} path={mdiPlus} /> {t("common.add").toUpperCase()}
        </Button>
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th>
              <h4 className="light">{t("place.name")}</h4>
            </th>
            <th>
              <h4 className="light">{t("place.address")}</h4>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {session.places.map((place) => (
            <tr key={place.id}>
              <td>
                <strong>{place.name}</strong>
              </td>
              <td>{place.address}</td>
              <td className="text-right">
                <Button
                  variant="outline-secondary"
                  disabled={!canEditPlaces}
                  onClick={() => setPlaceToEdit(place)}
                >
                  <Icon size={1} path={mdiFolderOpen} />
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  disabled={!canEditPlaces}
                  title={t("common.delete")}
                  onClick={() => setPlaceToDelete(place)}
                >
                  <Icon size={1} path={mdiTrashCan} />
                </Button>
              </td>
            </tr>
          ))}
          {!session.places.length && !session.isLoadingPlaces && (
            <tr>
              <td colSpan={10}>{t("common.noElementsToShow")}</td>
            </tr>
          )}
        </tbody>
      </Table>

      {!session.places.length && session.isLoadingPlaces && (
        <LoadingMessage description={t("common.loading")} />
      )}

      <Modal show={isAddingPlace} onHide={onCancelAddPlace}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("common.add")} {t("trip.address")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAddingPlace && (
            <AddOrEditPlaceForm
              onSubmit={onAddPlace}
              onCancel={onCancelAddPlace}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={!!placeToEdit} onHide={onCancelEditPlace}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("common.edit")} {t("trip.address")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {placeToEdit && (
            <AddOrEditPlaceForm
              place={placeToEdit}
              onSubmit={onEditPlace}
              onCancel={onCancelEditPlace}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={!!placeToDelete} onHide={cancelDeletePlace}>
        <Modal.Header>
          <Modal.Title>
            {t("common.delete")} {t("common.patient")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("reservedArea.deletePlaceConfirmation", {
              name: placeToDelete?.name,
              address: placeToDelete?.address,
            })}
          </p>
          <div className="text-center">
            <Button variant="outline-secondary" onClick={cancelDeletePlace}>
              {t("common.cancel")} <Icon size={1} path={mdiClose} />
            </Button>
            <Button variant="danger" onClick={confirmDeletePlace}>
              {t("common.delete")} <Icon size={1} path={mdiTrashCan} />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
});

type FormProps = {
  place?: Place;
  onSubmit: (place: Place) => void;
  onCancel: () => void;
};

export const AddOrEditPlaceForm: React.FC<FormProps> = observer((props) => {
  const { t } = useTranslation();

  const { session } = useStores();
  const { place, onCancel, onSubmit } = props;

  return (
    <Form
      initialValues={{
        // When editing, this will retain the place ID on submit
        ...place,
      }}
      validate={validateForm}
      onSubmit={onSubmit}
      render={(formProps) => (
        <BSForm onSubmit={formProps.handleSubmit}>
          <AddressInput
            name={"a"}
            names={{
              address: "address",
              city: "city",
              country: "country",
              province: "province",
              zipCode: "zipCode",
            }}
            suggestions={session.placesAddressSuggestions}
          />
          {/* TODO: tutte le altre parti dell'indirizzo? fare Input apposito con versione compact per la prima parte del wizard? */}

          <TextInput name="name" label={t("place.name")} />

          <TabbedRadioInputContainer>
            <TabbedRadioInput
              name="type"
              label={t(`place.${PlaceType.HOUSE}`)}
              value={PlaceType.HOUSE}
              icon={mdiOfficeBuilding}
              iconSize={2}
            />
            <TabbedRadioInputSeparator />
            <TabbedRadioInput
              name="type"
              label={t(`place.${PlaceType.HEALTH_FACILITY}`)}
              value={PlaceType.HEALTH_FACILITY}
              icon={mdiHospitalBuilding}
              iconSize={2}
            />
          </TabbedRadioInputContainer>
          <ErrorLabel name="type" />

          <BSForm.Group>
            <BSForm.Label>{t("place.floor")}</BSForm.Label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "baseline",
              }}
            >
              <RadioInputOption
                name="floor"
                label={t("place.groundFloor")}
                value={"Piano terra"}
              />
              <TextInput name="floor" />
            </div>
          </BSForm.Group>

          {formProps.values.type === PlaceType.HEALTH_FACILITY && (
            <>
              <TextInput name="hospitalWard" label={t("place.hospitalWard")} />
              <TextInput
                name="hospitalBuilding"
                label={t("place.hospitalBuilding")}
              />
              {/* <TextInput
                name="hospitalWardReference"
                label={t('place.hospitalWardReference')}
              /> */}
            </>
          )}

          <ToggleInputList
            name="details"
            label={t("place.buildingDetails")}
            options={session.predefinedOptions.buildingDetail.map((option) => ({
              label: t(`place.details.${option.value}`),
              value: option.value,
            }))}
            block={true}
          />

          <div className="text-center" style={{ marginTop: "2rem" }}>
            <Button variant="outline-secondary" onClick={onCancel}>
              {t("common.cancel")}
              <Icon size={1} path={mdiClose} />
            </Button>
            <Button type="submit" variant="primary">
              {t("common.save")} <Icon size={1} path={mdiFloppy} />
            </Button>
          </div>
        </BSForm>
      )}
    />
  );
});

const validateForm = (values: Place) => {
  const errors: FormErrors<Place> = {};

  if (!values.address) {
    errors.address = "Imposta un indirizzo";
  }
  if (!values.name) {
    errors.name = "Il nome è richiesto";
  }

  if (!values.type || !Object.values(PlaceType).includes(values.type)) {
    errors.type = "Scegli il tipo di edificio";
    return errors;
  }

  if (values.type === PlaceType.HOUSE) {
    const house = values as Partial<HousePlace>;
    const houseErrors = errors as FormErrors<typeof house>;

    if (house.floor === undefined) {
      houseErrors.floor = "Il piano è richiesto";
    }
  } else if (values.type === PlaceType.HEALTH_FACILITY) {
    const health = values as Partial<HealthBuildingPlace>;
    const healthErrors = errors as FormErrors<typeof health>;

    // if (!health.hospitalWard) {
    //   healthErrors.hospitalWard = "Il reparto dell'ospedale è richiesto";
    // }
    // if (!health.hospitalWardReference) {
    //   healthErrors.hospitalWardReference =
    //     "Il riferimento al reparto dell'ospedale è richiesto";
    // }
  }

  return errors;
};
