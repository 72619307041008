import env from "../config/env";
import { getLatLng } from "react-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";

export interface Coords {
  lng: number;
  lat: number;
}

export interface FullAddress {
  formatted_address?: string;
  address: string;
  city: string;
  province: string;
  country: string;
  zipCode: string;
  coords: Coords;
}

export const romeCenter: Coords = {
  lat: 41.883409,
  lng: 12.492731,
};

export const homePlace: FullAddress = {
  address: "Via Giovanni Savelli 30",
  city: "Padova",
  province: "PD",
  country: "Italy",
  zipCode: "35120",
  coords: {
    lat: 45.410539,
    lng: 11.912313,
  },
};

// Ospedale Padova
export const hospitalPlace: FullAddress = {
  address: "Via Nicolò Giustiniani 2",
  city: "Padova",
  province: "PD",
  country: "Italy",
  zipCode: "35120",
  coords: {
    lat: 45.403579,
    lng: 11.887572,
  },
};

const getPlace = (() => {
  let index = 0;
  let places = [homePlace, hospitalPlace];
  return () => places[index++ % places.length];
})();

export const mapGeocoderResultToFullAddress = (
  result: google.maps.GeocoderResult
): FullAddress => {
  // https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
  const getAddressComponent = (type: string): string => {
    const found = result.address_components.find((component) =>
      component.types.includes(type)
    );

    return found?.long_name || "";
  };

  const getAddressComponentShort = (type: string): string => {
    const found = result.address_components.find((component) =>
      component.types.includes(type)
    );

    return found?.short_name || "";
  };

  return {
    // formatted_address: getAddressComponent("formatted_address"),
    address: getAddressComponent("route"),
    city: getAddressComponent("administrative_area_level_3"), // TODO: Corresponds to city name?
    province: getAddressComponentShort("administrative_area_level_2"), // TODO
    zipCode: getAddressComponent("postal_code"),
    country: getAddressComponent("country"),
    coords: {
      lat: result.geometry.location.lat(),
      lng: result.geometry.location.lng(),
    },
  };
};

const geocodeAddress = async (address: string): Promise<Coords | undefined> => {
  if (env.MOCK_MAPS) {
    await new Promise((res) => setTimeout(res, 500));
    return address.toLowerCase().search("nicol") !== -1
      ? hospitalPlace.coords
      : homePlace.coords;
  }

  try {
    const results = await geocodeByAddress(address);
    const coords = await getLatLng(results[0]);
    return coords;
  } catch (err: any) {
    console.warn(err);
  }
};

const searchAddress = async (address: string): Promise<FullAddress[]> => {
  if (env.MOCK_MAPS) {
    await new Promise((res) => setTimeout(res, 500));
    return address.toLowerCase().search("nicol") !== -1
      ? [hospitalPlace]
      : [homePlace];
  }

  try {
    const results = await geocodeByAddress(address);

    return results.map((item: any) => {
      console.log("ITEM: ", item);
      return mapGeocoderResultToFullAddress(item);
    });
  } catch (err: any) {
    console.warn(err);
    return [];
  }
};

const reverseGeocode = async (
  coords: Coords
): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: coords }, (results: any, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

const locateMyPosition = async (): Promise<FullAddress[] | undefined> => {
  if (env.MOCK_MAPS) {
    await new Promise((res) => setTimeout(res, 500));
    const place = getPlace();
    return [place];
  }

  try {
    const geolocation = await new Promise<GeolocationPosition>((res, rej) => {
      // @ts-ignore res is Position
      navigator.geolocation.getCurrentPosition(res, rej, {
        // require high accuracy to ensure it's the correct address
        enableHighAccuracy: true,
      });
    });

    const coords = {
      lat: geolocation.coords.latitude,
      lng: geolocation.coords.longitude,
    };

    const results = await reverseGeocode(coords);

    if (results.length) {
      return results.map(mapGeocoderResultToFullAddress);
    }
  } catch (err: any) {
    console.warn("locateMyPosition failed", err);
  }
};

export const maps = {
  geocodeAddress,
  searchAddress,
  locateMyPosition,
};
