import { PlaceType, PatientWeightRange, PatientRelationship } from "../types";

export default {
  common: {
    or: "oppure",
    and: "e",
    date: "Data",
    patient: "Paziente",
    address: "Indirizzo",
    building: "Abitazione",
    buildings: "Abitazioni",
    newPatient: "Nuovo paziente",
    typology: "Tipologia",
    denomination: "Denominazione",
    details: "Dettagli",
    nominative: "Nominativo",
    chooseAnOption: "Scegli un opzione",
    noElementsToShow: "Nessun elemento da mostrare",
    loading: "Caricamento",
    waitAMoment: "Attendi un momento",
    noResults: "Nessun risultato",
    type: "Tipo",
    time: "Orario",
    note: "Note",
    month: "Mese",
    year: "Anno",
    status: "Stato",
    // Actions
    view: "Visualizza",
    enter: "Accedi",
    continue: "Continua",
    continueToCash: "Continua per accettare",
    goBack: "Torna indietro",
    add: "Aggiungi",
    remove: "Rimuovi",
    replicate: "Replica",
    edit: "Modifica",
    delete: "Elimina",
    cancel: "Annulla",
    save: "Salva",
    code: "Codice",
    refuses: "Rifiuta",
    refuse: "Rifiuta",
    confirm: "Conferma",
    registrationError: "Errore registrazione",
    subscribe: "Iscriviti",
    close: "Chiudi",
  },
  session: {
    email: "Email",
    password: "Password",
    newPassword: "Nuova password",
    confirmPassword: "Conferma password",
    currentPassword: "Password corrente",
    changePassword: "Cambia password",
    login: "Accedi",
    signUp: "Registrazione",
    signMeUp: "Registrami",
    alreadySignedUp: "Sono già registrato",
    wantToRegister: "Desidero registrarmi",
    forUser: "Per i clienti",
    forPartner: "Per i partner",
    notRegisteredPartner:
      "Non sei ancora un operatore?\nFai riferimento al tuo referente per farti aggiungere",
  },
  trip: {
    route: "Percorso",
    from: "Da",
    to: "A",
    address: "Indirizzo",
    addresses: "Abitazioni",
    departure: "Partenza",
    departureAddress: "Indirizzo di partenza",
    arrivalAddress: "Indirizzo di arrivo",
    departureDate: "Data di partenza",
    changeDepartureDate: "Sposta data di partenza",
    section: "Tratta",
    type: "Tipo",
    reason: "Motivazione",
    examinations: "Visita da eseguire",
    roundTrip: "Tratta",
    roundTripTitle: "Andata e ritorno",
    roundTripYes: "Andata e ritorno",
    roundTripNo: "Solo andata",
    modalNoVehicleTitle: "Nessun mezzo disponibile",
    modalNoVehicleSubTitle:
      "Purtroppo non siamo attivi in questa zona, ma lo saremo presto.",
    modalNoVehicleFormLabel: "Iscrivi alla mailing list per essere informato!",
    modalNoVehicleFormPLaceholder: "Insericsi la tua email",
  },
  person: {
    givenName: "Nome",
    familyName: "Cognome",
    bornOn: "Nato il",
    bornAt: "Nato a",
    bornAtPlaceholder: "Luogo/Città di nascita",
    place: "Residenza",
    fiscalCode: "Codice Fiscale",
    healthInsuranceCard: "Numero Tessera Sanitaria",
    mobilePhone: "Telefono cellulare",
    phoneNumber: "Telefono fisso",
    personalData: "Dati anagrafici",
    residentialAddress: "Indirizzo di residenza",
    nation: "Nazione",
    province: "Provincia",
    city: "Città",
    postalCode: "Codice postale",
    company: "Azienda",
    vatNumber: "P. IVA",
    sdi: "SDI",
    companyName: "Denominazione / Ragione Sociale",
    gdprConsentPrefix: "Acconsento al trattamento del",
    relationship: "Relazione",
    relationships: {
      [PatientRelationship.RELATIVE]: "Familiare",
      [PatientRelationship.PATIENT]: "Paziente",
      [PatientRelationship.ME]: "Io",
    },
    movementLabel: "Il paziente è",
    mobility: {
      AMBULANTE: "Cammina",
      ALLETTATO: "Allettato",
      IN_CARROZZINA: "In carrozzina",
    },
    weightLabel: "Stima il suo peso",
    weights: {
      [PatientWeightRange.LESS_50]: "Meno di 50 kg",
      [PatientWeightRange.RANGE_50_80]: "50 - 80 kg",
      [PatientWeightRange.RANGE_80_110]: "80 - 110 kg",
      [PatientWeightRange.MORE_110]: "Più di 110 kg",
    },
    hasSpecialNeeds: "Ha qualche necessità particolare? (opzionale)",
    specialNeeds: {
      NECESSITA_OSSIGENO: "OSSIGENO (patologie respiratorie)",
      NECESSITA_CARROZZINA: "CARROZZINA (nel caso di uso vettura attrezzata)",
      NECESSITA_ASSISTENZA_CONTINUA: "ASSISTENZA CONTINUA",
      NECESSITA_ACCOMPAGNATORE: "NECESSITA ACCOMPAGNATORE",
    },
    diseasesType: "Seleziona le tipologie di patologie (opzionale)",
    diseases: {
      PATOLOGIA_METABOLICA: "Medico",
      PATOLOGIA_TRAUMA: "Traumatico",
      PATOLOGIA_INFARTUATO: "Cronico",
      PATOLOGIA_INFETTIVO: "Infettivo",
    },
    immobilizationAidsType: "Seleziona le tipologie di presidi (opzionale)",
    immobilizationAids: {
      SPINALE: "Spinale",
      MATERASSINO_A_DEPRESSIONE: "Materassino a depressione",
      STECCOBENDA: "Steccobenda",
      COLLARE_CERVICALE: "Collare cervicale",
      SEDIA_CARDIOPATICA: "Sedia cardiopatica",
    },
  },
  place: {
    type: "Tipologia",
    name: "Nome",
    address: "Indirizzo",
    denomination: "Denominazione",
    [PlaceType.HOUSE]: "Abitazione",
    [PlaceType.HEALTH_FACILITY]: "Struttura Sanitaria",
    myBuildings: "I miei posti",
    floor: "Piano",
    groundFloor: "Piano terra",
    details: {
      ACCESSO_DIFFICOLTOSO: "L'accesso è un pò difficoltoso",
      SCALE_STRETTE: "Scale con larghezza inferiore a 120 cm",
      LETTO_OSPEDALIERO:
        "La stanza è attrezzata con presidi come letto ospedaliero",
      ASCENSORE_BARELLE: "La struttura è attrezzata con ascensore per barelle",
      ASCENSORE_CARROZZINE:
        "La struttura è attrezzata con ascensore per carrozzina",
    },
    buildingDetails: "Nell'edificio troveremo",
    // Hospital
    hospitalName: "Denominazione ospedale",
    hospitalWard: "Reparto (Possibilmente dichiararlo)",
    hospitalWardReference: "Riferimento reparto",
    hospitalBuilding: "Palazzina",
  },
  // Screens
  transport: {
    transportRequest: "Richiesta di transporto",
    newTransportRequest: "Nuova richiesta di transporto",
    addRequestMessage: "Inserisci qui un messaggio, se lo desideri",
    loginGuardDescription:
      "Accedi o Registrati in HAgile per completare la richiesta di trasporto",
    // TODO: reformat to title/descr
    stages: {
      route: "Percorso",
      patient: "Paziente",
      transport: "Trasporto",
      departurePlace: "Struttura Partenza",
      arrivalPlace: "Struttura Arrivo",
      notes: "Richieste aggiuntive",
    },
    additionalRequests: "Note aggiuntive facoltative o prescrizioni",
    forMe: "Per me",
    forAnotherPerson: "Per un'altra persona",
    newPatient: "Nuovo paziente",
    patientStageFormDescription: "Dati del Paziente",
    transportStageFormDescription: "Tratta",
    patientExtraStageFormDescription: "Informazioni sul Paziente",
    autofillPatientStageDescription: "Stai richiedendo il servizio",
    autofillFromProfile:
      "I dati anagrafici saranno presi automaticamente dal profilo di registrazione",
    requestForCompanion:
      "Richiedo la presenza di un accompagnatore per assistenza nelle procedure",
    hasCompanion:
      "Il paziente sarà accompagnato da un accompagnatore o familiare",
    visitType: "Seleziona la visita da eseguire",
    orExecutionTime: "Oppure il tempo previsto per l'esecuzione",
  },
  bookings: {
    confirmed: "Prenotazione confermata",
    confirmedDescr: "La tua prenotazione è stata confermata correttamente",
    bookingCode: "Codice prenotazione",
    bookingDetails: "Dettagli prenotazione",
    similarToBookedRequest: "Prenotazione simile ad una già eseguita",
    chooseBooking: "Seleziona una prenotazione che vuoi richiedere nuovamente",
    replicateTransportRequest: "Replica richiesta di transporto",
    replicateTransportRequestDescr:
      "Mediante la replica della prenotazione... TODO",
  },
  home: {
    whatDoYouNeed: "Di cosa hai bisogno?",
    description: "Inserisci le informazioni ed entra in Hagile",
    enter: "Trova un mezzo",
    contacts: "Contatti",
    transparency: "Transparenza",
    contributing: "Contribusci",
    news: "News",
  },
  footer: {
    termsOfUse: "Termini e Condizioni",
    whoWeAre: "Chi siamo",
    howItWors: "Come funziona",
    faq: "Domande frequenti",
    insurance: "Assicurazione",
    jobOffers: "Offerte di lavoro",
    contactUs: "Contattaci",
    press: "Stampa",
  },
  reservedArea: {
    personalArea: "Area personale",
    personalAreaOf: "Area personale di",
    profile: "Mio profilo",
    bookings: "Prenotazioni",
    patients: "Pazienti",
    addresses: "Indirizzi",
    paymentMethods: "Metodi di pagamento",
    invoices: "Fatture",
    transportRequest: "Richiesta di trasporto",
    newTransportRequest: "Richiedi trasporto",
    myAddresses: "I miei indirizzi",
    deletePatientConfirmation:
      "Sei sicuro di voler cancellare il paziente {{name}}?",
    deletePlaceConfirmation:
      'Sei sicuro di voler cancellare il posto "{{name}}" che si trova in {{address}}?',
    reminderUpcomingBookings: "Promemoria prossime prenotazioni",
  },
  payment: {
    invoices: "Fatture",
    viewInvoice: "Visualizza la fattura",
    yourCards: "Le tue carte",
    paymentMethod: "Metodo di pagamento",
    paymentMethods: "Metodi di pagamento",
    endsWith: "termina con",
    cardNumber: "Numero carta",
    securityCode: "Codice di sicurezza",
    cardHolder: "Intestatario carta",
  },
  quotation: {
    quotation: "Preventivo",
    applicant: "Richiedente",
    transported: "Trasportato",
    conventions: "Convenzioni",
    conventionNumber: "Numero della convenzione",
    loading: "Caricamento del preventivo",

    timeToWaitIs: "Ci vediamo tra",
    timeToWaitSpec:
      "Tranquillo, siamo SEMPRE a tua disposizione per qualsiasi bisogno o aggiornamento e non dubitare a chiamarci.",
    invoiceHolderDescription: "Il servizio sarà fatturato a",
    maxCostIs: "Il costo massimo previsto per il servizio è",
    costAlert1:
      "Accettando ti chiederemo uno strumento di pagamento digitale ed effettueremo SOLO una prenotazione dell'importo.",
    costAlert2: "ATTENZIONE il vero addebito avverrà solo DOPO il servizio.",

    findHowCalculated: "Scopri come è calcolato il posto",
    refuseQuotation: "Rifiuto il preventivo",
    viewQuotation: "Visualizza preventivo",
    confirmRefuseQuotation:
      "Confermi di voler rifiutare il preventivo del trasporto?",
    refuseQuotationMotivation:
      "Per migliorare il nostro servizio, indicaci la motivazione (del tutto facoltativa)",
    refusePriceTooHigh: "Prezzo troppo alto",
    refuseBestPriceOnMarket: "Miglior prezzo sul mercato",
    button: "Rifiuta preventivo",
    confirmQuotation: "Conferma il preventivo",
    choosePaymentMethod: "Scegli il metodo di pagamento",
    paymentDescription: "Descrizione pagamenti",
    paypalError:
      "L'ordine non può essere pagato con PayPal a causa di un errore",
    paymentError: "Errore durante il pagamento",
    stripeError:
      "L'ordine non può essere pagato con Stripe a causa di un errore",
    payWithStripe: "Paga con Stripe",
    payWithSuccess: "Pagamento effettuato",
  },
  invoice: {
    date: "Data",
    amount: "Importo",
    download: "Scarica",
  },
};
