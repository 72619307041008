import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import { Container, Button, Table, Modal, Row, Col } from "react-bootstrap";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { mdiTrashCan, mdiCreditCard, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";

import { useStores } from "../hooks/useStores";
import { TextInput } from "../components/TextInput";
import { SelectInput } from "../components/SelectInput";
import { range } from "../utils/array";
import {
  ValidateForm,
  FormErrors,
  PaymentMethod,
  PaymentMethodProvider,
} from "../types";
import {
  TabbedRadioInput,
  TabbedRadioInputContainer,
  TabbedRadioInputSeparator,
} from "../components/TabbedRadioInput";
import { ErrorLabel } from "../components/ErrorLabel";
import { LoadingMessage } from "../components/LoadingMessage";

export const UserPaymentMethods: React.FC = observer(() => {
  const { session } = useStores();

  const [isAddingPayment, setIsAddingPayment] = useState(false);
  const [paymentToEdit, setPaymentToEdit] = useState<PaymentMethod>();

  const { t } = useTranslation();

  useEffect(() => {
    session.fetchPaymentMethods();
  }, []);

  return (
    <Container>
      <div className="main-title">
        <h4>{t("reservedArea.paymentMethods")}</h4>
        <Button
          variant="outline-secondary"
          title={t("common.add")}
          onClick={() => setIsAddingPayment(true)}
        >
          <Icon size={1} path={mdiPlus} /> {t("common.add").toUpperCase()}
        </Button>
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th>
              <h4 className="light">{t("payment.yourCards")}</h4>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {session.paymentMethods.map((paymentMethod) => (
            <tr key={paymentMethod.id}>
              <td>
                <Icon size={1} path={mdiCreditCard} /> {paymentMethod.provider}
                <span> {t("payment.endsWith")} </span>
                <strong>{paymentMethod.number.slice(-4)}</strong>
              </td>
              <td className="text-right">
                <Button
                  variant="outline-secondary"
                  onClick={() => setPaymentToEdit(paymentMethod)}
                >
                  <Icon size={1} path={mdiPencil} title={t("common.edit")} />
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => session.removePaymentMethod(paymentMethod)}
                >
                  <Icon
                    size={1}
                    path={mdiTrashCan}
                    title={t("common.remove")}
                  />
                </Button>
              </td>
            </tr>
          ))}
          {!session.paymentMethods.length && !session.isLoadingPaymentMethods && (
            <tr>
              <td colSpan={10}>{t("common.noElementsToShow")}</td>
            </tr>
          )}
        </tbody>
      </Table>

      {!session.paymentMethods.length && session.isLoadingPaymentMethods && (
        <LoadingMessage description={t("common.loading")} />
      )}

      {isAddingPayment && (
        <PaymentMethodEditForm
          onSubmit={(payment) => {
            session.addPaymentMethod(payment);
            setIsAddingPayment(false);
          }}
          onCancel={() => setIsAddingPayment(false)}
        />
      )}

      {paymentToEdit && (
        <PaymentMethodEditForm
          payment={paymentToEdit}
          onSubmit={(payment) => {
            session.updatePaymentMethod(payment);
            setPaymentToEdit(undefined);
          }}
          onCancel={() => setPaymentToEdit(undefined)}
        />
      )}
    </Container>
  );
});

const currentYear = new Date().getUTCFullYear();
const yearsOptions = range(currentYear - 10, currentYear + 40)
  .map((year) => String(year))
  .map((year) => ({
    key: year,
    label: year,
  }));

const monthOptions = range(1, 12).map((month) => ({
  key: `${month}`,
  label: `${month}`,
}));

type PaymentMethodEditFormValues = PaymentMethod;

type PMEProps = {
  payment?: PaymentMethod;
  onSubmit: (payment: PaymentMethod) => void;
  onCancel: () => void;
};

const PaymentMethodEditForm: React.FC<PMEProps> = ({
  payment,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();

  const isNew = !payment;

  const initialValues = useMemo(() => {
    if (payment) {
      return {
        ...payment,
      };
    }

    const today = new Date();
    return {
      expiryMonth: (today.getMonth() + 1).toString(),
      expiryYear: today.getFullYear().toString(),
    } as PaymentMethodEditFormValues;
  }, [payment]);

  return (
    <Modal show={true} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isNew ? t("common.add") : t("common.edit")}{" "}
          {t("payment.paymentMethod")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          initialValues={initialValues}
          validate={validatePaymentForm}
          onSubmit={onSubmit}
          render={(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <TabbedRadioInputContainer>
                <TabbedRadioInput
                  icon={mdiCreditCard}
                  name="provider"
                  label={PaymentMethodProvider.MASTERCARD}
                  value={PaymentMethodProvider.MASTERCARD}
                />
                <TabbedRadioInputSeparator />
                <TabbedRadioInput
                  name="provider"
                  icon={mdiCreditCard}
                  label={PaymentMethodProvider.VISA}
                  value={PaymentMethodProvider.VISA}
                />
              </TabbedRadioInputContainer>
              <ErrorLabel name="provider" />
              <Row>
                <Col>
                  <TextInput
                    name="number"
                    label={t("payment.cardNumber")}
                    placeholder="1111222233334444"
                  />
                </Col>
                <Col>
                  <TextInput
                    name="code"
                    label={t("payment.securityCode")}
                    placeholder="5678"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    name="holder"
                    label={t("payment.cardHolder")}
                    placeholder="Mario Rossi"
                  />
                </Col>
                <Col>
                  {/* TODO: group this as single input with error label? */}
                  <div style={{ display: "flex" }}>
                    <SelectInput
                      name="expiryMonth"
                      label={t("common.month")}
                      options={monthOptions}
                    />
                    <div style={{ width: "1rem" }}></div>
                    <SelectInput
                      name="expiryYear"
                      label={t("common.year")}
                      options={yearsOptions}
                    />
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  type="submit"
                  variant="primary"
                  className={"submitFormBtt"}
                >
                  {isNew ? t("common.add") : t("common.save")}
                </Button>
              </div>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

const validatePaymentForm: ValidateForm<PaymentMethodEditFormValues> = (
  values
) => {
  const errors: FormErrors<PaymentMethodEditFormValues> = {};
  if (!values.number) {
    errors.number = "Inserire il numero della carta";
  }
  if (!values.provider) {
    errors.provider = "Scegliere un fornitore";
  }
  if (!values.code) {
    errors.code = "Inserire il codice della carta";
  }
  if (!values.holder) {
    errors.holder = "Inserire l'intestatario della carta";
  }
  // TODO: check card expiration date is not expired
  return errors;
};
