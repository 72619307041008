import React, { useEffect } from "react";
import { FormRenderProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  FormErrors,
  ValidateForm,
  TransportRequestDraftValues,
  PatientWeightRange,
} from "../../types";
import { useStores } from "../../hooks/useStores";

import { RadioInput } from "../../components/RadioInput";
import { ToggleInputList } from "../../components/ToggleInputList";
import { LoadingMessage } from "../../components/LoadingMessage";

export const PatientExtraStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = observer((props) => {
  const { session } = useStores();

  useEffect(() => {
    session.fetchAllPredefinedOptions();
  }, []);

  const { t } = useTranslation();

  if (session.isLoading) {
    return <LoadingMessage />;
  }

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">{t("transport.stages.patient")}</h4>
      <p>{t("transport.patientExtraStageFormDescription")}</p>
      <div className={"form-container-sm"}>
        <RadioInput
          label={t("person.movementLabel")}
          name="patient.mobility"
          options={session.predefinedOptions.patientMobility.map((option) => ({
            label: t(`person.mobility.${option.value}`),
            value: option.value,
          }))}
        />
        <RadioInput
          label={t("person.weightLabel")}
          name="patient.weight"
          options={[
            {
              label: t(`person.weights.${PatientWeightRange.LESS_50}`),
              value: PatientWeightRange.LESS_50,
            },
            {
              label: t(`person.weights.${PatientWeightRange.RANGE_50_80}`),
              value: PatientWeightRange.RANGE_50_80,
            },
            {
              label: t(`person.weights.${PatientWeightRange.RANGE_80_110}`),
              value: PatientWeightRange.RANGE_80_110,
            },
            {
              label: t(`person.weights.${PatientWeightRange.MORE_110}`),
              value: PatientWeightRange.MORE_110,
            },
          ]}
        />

        <ToggleInputList
          label={t("person.hasSpecialNeeds")}
          name="patient.specialNeeds"
          options={session.predefinedOptions.patientSpecialNeed.map(
            (option) => ({
              value: option.value,
              label: t(`person.specialNeeds.${option.value}`),
            })
          )}
          block={true}
        />

        <ToggleInputList
          name="patient.immobilizationAids"
          label={t("person.immobilizationAidsType")}
          options={session.predefinedOptions.patientImmobilizationAid.map(
            (option) => ({
              value: option.value,
              label: t(`person.immobilizationAids.${option.value}`),
            })
          )}
        />

        <ToggleInputList
          name="patient.diseases"
          label={t("person.diseasesType")}
          options={session.predefinedOptions.patientDisease.map((option) => ({
            value: option.value,
            label: t(`person.diseases.${option.value}`),
          }))}
        />
      </div>
    </div>
  );
});

export const validatePatientExtraStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  const patientErrors: FormErrors<TransportRequestDraftValues["patient"]> = {};
  const errors: FormErrors<TransportRequestDraftValues> = {
    patient: patientErrors,
  };

  if (!values.patient) {
    patientErrors.mobility = "È necessario compilare tutti i campi";
    return errors;
  }

  if (!values.patient.mobility) {
    patientErrors.mobility = "Il movimento del paziente è richiesto";
  }
  if (!values.patient.weight) {
    patientErrors.weight = "La stima del peso del paziente è richiesta";
  }

  return errors;
};
