import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Form, FormRenderProps } from "react-final-form";
import GoogleMapReact, {
  ChildComponentProps as GoogleMapReactMarker,
} from "google-map-react";
import { observer } from "mobx-react";

import {
  FormErrors,
  TransportRequestDraftValues,
  ValidateForm,
  Place,
} from "../../types";
import { maps, Coords, romeCenter } from "../../services/maps";
import keys from "../../config/keys";

import { DateTimeInput } from "../../components/DateTimeInput";
import { useStores } from "../../hooks/useStores";
import env from "../../config/env";
import { CheckboxInput } from "../../components/CheckboxInput";
import Alert from "@material-ui/lab/Alert";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { TextInput } from "../../components/TextInput";
import { mdiHomeFloor3 } from "@mdi/js";
import { TransportsStore } from "../../stores/TransportsStore";
import api from "../../services/api";
import moment from "moment";
import { AddressInput } from "../../components/AddressInput";

const halfHour = 30 * 60 * 1000;

const createStaticMapOptions = () => ({
  zoomControl: true,
  rotateControl: false,
  panControl: false,
  gestureHandling: "none",
});

export const RouteStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = observer((props) => {
  const { values } = props;
  const [fromCoords, setFromCoords] = useState<Coords | undefined>();
  const [toCoords, setToCoords] = useState<Coords | undefined>();
  const { t, i18n } = useTranslation();
  const { session, transports } = useStores();
  const [isVehiclesAvailable, setIsVehicleAvailable] = useState(false);

  const updateFromAddressCoords = (address: string) =>
    maps.geocodeAddress(address).then(setFromCoords).catch(console.warn);

  const updateToAddressCoords = (address: string) =>
    maps.geocodeAddress(address).then(setToCoords).catch(console.warn);

  useEffect(() => {
    if (session.isLogged) {
      session.fetchPlaces().catch(console.warn);
    }
  }, [session.isLogged]);

  useEffect(() => {
    if (values.departurePlace && values.departurePlace.address) {
      let name_departure =
        values.departurePlace?.name && values.departurePlace?.name !== undefined
          ? values.departurePlace?.name + " - "
          : "";
      let address_departure =
        values.departurePlace?.address &&
        values.departurePlace?.address !== undefined
          ? values.departurePlace?.address + ", "
          : "";
      let city_departure =
        values.departurePlace?.city && values.departurePlace?.city !== undefined
          ? values.departurePlace?.city + ", "
          : "";
      let province_departure =
        values.departurePlace?.province &&
        values.departurePlace?.province !== undefined
          ? values.departurePlace?.province
          : "";

      updateFromAddressCoords(
        name_departure + address_departure + city_departure + province_departure
      );
    }
    if (values.arrivalPlace && values.arrivalPlace.address) {
      let name_arrivalPlace =
        values.arrivalPlace?.name && values.arrivalPlace?.name !== undefined
          ? values.arrivalPlace?.name + " - "
          : "";
      let address_arrivalPlace =
        values.arrivalPlace?.address &&
        values.arrivalPlace?.address !== undefined
          ? values.arrivalPlace?.address + ", "
          : "";
      let city_arrivalPlace =
        values.arrivalPlace?.city && values.arrivalPlace?.city !== undefined
          ? values.arrivalPlace?.city + ", "
          : "";
      let province_arrivalPlace =
        values.arrivalPlace?.province &&
        values.arrivalPlace?.province !== undefined
          ? values.arrivalPlace?.province
          : "";

      updateToAddressCoords(
        name_arrivalPlace +
          address_arrivalPlace +
          city_arrivalPlace +
          province_arrivalPlace
      );
    }
    // Update markers just once, useful when coming back from a subsequent stage
  }, []);

  const isVehiclesAvailableCall = useCallback(async () => {
    if (
      values.departurePlace &&
      values.arrivalPlace &&
      // values.transportType &&
      values.trip &&
      values.trip.departureTime &&
      values.departurePlace.province
    ) {
      await transports.isVehiclesAvailablePublic(
        moment(values.trip.departureTime).format("YYYY/MM/DD HH:mm:ss"),
        moment(values.trip.departureTime)
          .add(1, "hour")
          .format("YYYY/MM/DD HH:mm:ss"),
        values.departurePlace.province
      );
    }
  }, [values.departurePlace, values.arrivalPlace, values.trip, transports]);

  useEffect(() => {
    isVehiclesAvailableCall();
  }, [isVehiclesAvailableCall]);

  return (
    <div className="stage-form route-stage-form--container">
      <div className="route-stage-form--wrapper">
        <h4>{t("transport.stages.route")}</h4>

        <AddressInput
          name={`departurePlace`}
          names={{
            address: "departurePlace.address",
            city: "departurePlace.city",
            province: "departurePlace.province",
            country: "departurePlace.country",
            zipCode: "departurePlace.zipCode",
            id: "departurePlace.id",
          }}
          label={t("trip.from")}
          placeholder={t("trip.address")}
          suggestions={session.placesAddressSuggestions}
        />

        <AddressInput
          name={`arrivalPlace`}
          names={{
            address: "arrivalPlace.address",
            city: "arrivalPlace.city",
            province: "arrivalPlace.province",
            country: "arrivalPlace.country",
            zipCode: "arrivalPlace.zipCode",
            id: "arrivalPlace.id",
          }}
          label={t("trip.to")}
          placeholder={t("trip.address")}
          suggestions={session.placesAddressSuggestions}
        />

        <DateTimeInput
          name="trip.departureTime"
          label={t("trip.departureDate")}
        />

        <CheckboxInput
          name="trip.roundTrip"
          description={t("trip.roundTripTitle")}
        />
        {/* to control when will be arrive the api */}
        {transports.vehiclesAvailablePublic === true ? (
          <Alert severity="success">Sono presenti mezzi disponibili</Alert>
        ) : transports.vehiclesAvailablePublic === false ? (
          <Alert severity="error">Non sono presenti mezzi disponibili</Alert>
        ) : null}
      </div>
      <div className="route-stage-form--map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: keys.MAPS_API_KEY,
            language: i18n.language,
            region: "IT",
          }}
          options={createStaticMapOptions}
          center={fromCoords}
          defaultCenter={romeCenter}
          defaultZoom={13}
        >
          {fromCoords && (
            <MapMarker lat={fromCoords.lat} lng={fromCoords.lng} color="red" />
          )}
          {toCoords && (
            <MapMarker lat={toCoords.lat} lng={toCoords.lng} color="blue" />
          )}
        </GoogleMapReact>
      </div>
      {/* to control when will be arrive the api */}
      <Modal show={false} onHide={() => {}}>
        <Modal.Header closeButton>
          <Modal.Title>{t("trip.modalNoVehicleTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{t("trip.modalNoVehicleSubTitle")}</h6>
          <Form
            onSubmit={() => {}}
            render={(formProps) => (
              <form onSubmit={formProps.handleSubmit}>
                <Row>
                  <Col>
                    <TextInput
                      name="email"
                      label={t("trip.modalNoVehicleFormLabel")}
                      placeholder={t("trip.modalNoVehicleFormPLaceholder")}
                    />
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    type="submit"
                    variant="primary"
                    className={"submitFormBtt"}
                  >
                    {t("common.subscribe")}
                  </Button>
                </div>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
});

// GoogleMapReactMarker requires lat/lng props to be specified
const MapMarker: React.FC<
  GoogleMapReactMarker & {
    color: string;
  }
> = ({ color }) => {
  return (
    <div
      style={{
        height: "10px",
        width: "10px",
        backgroundColor: color,
        border: "2px solid white",
        borderRadius: "100%",
      }}
    ></div>
  );
};

export const validateRouteStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  const routeErrors: FormErrors<TransportRequestDraftValues["trip"]> = {};

  if (!values.trip.departureTime) {
    routeErrors.departureTime = "Orario di partenza mancante";
  } else if (
    new Date(values.trip.departureTime).getTime() <
    Date.now() - halfHour
  ) {
    routeErrors.departureTime = "Orario di partenza troppo vecchio";
  }

  const departurePlaceErrors: FormErrors<Place> = {};
  const arrivalPlaceErrors: FormErrors<Place> = {};

  if (!values.departurePlace || !values.departurePlace.address) {
    departurePlaceErrors.address = "Specifica l'indirizzo di partenza";
  }
  if (!values.arrivalPlace || !values.arrivalPlace.address) {
    arrivalPlaceErrors.address = "Specifica l'indirizzo di arrivo";
  }
  if (values.departurePlace?.address === values.arrivalPlace?.address) {
    arrivalPlaceErrors.address =
      "L'indirizzo di partenza e l'indirizzo di arrivo devono essere diversi";
  }

  return {
    trip: routeErrors,
    departurePlace: departurePlaceErrors,
    arrivalPlace: arrivalPlaceErrors,
  };
};
