import React from "react";
import { observer } from "mobx-react";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiLogout } from "@mdi/js";

import { useStores } from "../hooks/useStores";
import history from "../history";

import HAgileBrandLogo from "../assets/images/hagile_exe_01b.png";
import HAgileLogo from "../assets/images/hagile_exe_02.png";

export const Header: React.FC = observer(() => {
  const { session } = useStores();

  const logout = () => {
    session.logout();
    history.push("/");
  };

  const goToLogin = () => {
    history.push("/login");
  };

  return (
    <Container className="header--container">
      <nav
        style={{ display: "flex", flexGrow: 1 }}
        className={"justify-content-between"}
      >
        <Link
          to={"/"}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={HAgileBrandLogo}
            alt={"HAgile"}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />
          <img
            src={HAgileLogo}
            alt={"HAgile"}
            height="30"
            className="d-inline-block align-top"
            style={{ marginBottom: "-6px" }}
          />
        </Link>

        <div style={{ display: "flex", alignItems: "center" }}>
          {session.user && (
            <Link to={"/reserved-area"}>
              <Button
                variant="outline-secondary"
                className="px-2 d-block"
                style={{ wordBreak: "break-word" }}
              >
                {session.user.name} {session.user.surname}
              </Button>
            </Link>
          )}
          {session.isLogged ? (
            <Button variant="secondary" onClick={logout} className="ml-2 px-3">
              <span className="d-none d-md-inline mr-2">Logout</span>
              <Icon path={mdiLogout} size={1} />
            </Button>
          ) : (
            <Button variant="primary" onClick={goToLogin}>
              Login
            </Button>
          )}
        </div>
      </nav>
    </Container>
  );
});
