import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { useStores } from "../../hooks/useStores";
import { useQueryParams } from "../../hooks/useQueryParams";

export const TransportRequestNew: React.FC = observer((props) => {
  const queryParams = useQueryParams();
  const { t } = useTranslation();

  const [isLoadingReplica, setIsLoadingReplica] = useState(false);
  const { transports } = useStores();

  useEffect(() => {
    const replicateFromId = queryParams.get("replicateFromId");
    const replicateDepartureTime = queryParams.get("replicateDepartureTime");

    if (transports.requestDraft) {
      return;
    } else if (replicateFromId && replicateDepartureTime) {
      setIsLoadingReplica(true);
      transports
        .replicateAsDraft(replicateFromId, {
          departureTime: replicateDepartureTime,
        })
        .then(() => {
          setIsLoadingReplica(false);
        })
        .catch((err) => {
          // TODO: not found, invalid ID or no internet or authorization
        });
    } else {
      transports.getOrCreateRequestDraft();
    }
  }, [transports, queryParams]);

  if (transports.requestDraft) {
    return <Redirect to="/transport/wizard" />;
  }

  return isLoadingReplica ? <LoadingReplica /> : null;
});

const LoadingReplica: React.FC = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      padding: "5em",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.6)",
    }}
  >
    <h1 style={{ color: "white" }}>
      Caricamento replica della precedente request in corso...
    </h1>
  </div>
);
