import React from "react";
import { Spinner } from "react-bootstrap";

export const FullscreenLoading: React.FC<{ message?: string }> = ({
  message,
}) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Spinner animation="border" role="status" />
      <br />
      {message && <h2>{message}</h2>}
    </div>
  );
};
