import React from "react";
import { Field } from "react-final-form";
import { Form } from "react-bootstrap";

import { ErrorLabel } from "./ErrorLabel";

export type RadioInputOptionType = {
  value: string | number;
  label: string;
};

type Props = {
  name: string;
  options: RadioInputOptionType[];
  label?: string;
};

export const RadioInput: React.FC<Props> = ({ name, label, options }) => (
  <Form.Group>
    {label && <Form.Label>{label}</Form.Label>}
    <div style={{ flexDirection: "row", justifyContent: "space-around" }}>
      {/* // TODO radio as buttons https://stackoverflow.com/questions/5523735/how-to-make-a-radio-button-look-like-a-toggle-button */}
      {options.map((option) => (
        <RadioInputOption name={name} key={option.value} {...option} />
      ))}
    </div>
    <ErrorLabel name={name} />
  </Form.Group>
);

type OptionProps = {
  name: string;
} & RadioInputOptionType;

export const RadioInputOption: React.FC<OptionProps> = ({
  name,
  label,
  value,
}) => (
  <Field
    key={value}
    name={name}
    type="radio"
    value={value}
    parse={(newValue) =>
      typeof value === "number" ? Number(newValue) : newValue
    }
    render={({ input }) => (
      <label
        className={`radio-input--container ${input.checked ? "checked" : ""}`}
      >
        <input {...input} type="radio" value={value} hidden />
        <span>{label}</span>
      </label>
    )}
  />
);
