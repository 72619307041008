import React from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import { useStores } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";

export const CompletedStage: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    transports: { requestDraft },
  } = useStores();

  if (!requestDraft) {
    return <Redirect to="/transport" />;
  }

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">{t("bookings.confirmed")}</h4>
      <div className="form-container-sm">
        <div className="text-center">{t("bookings.confirmedDescr")}</div>
        <div className="text-center">
          <label>{t("bookings.bookingCode")}</label>
          <h3>PR20191220</h3>
        </div>
        <div className="text-center">
          <a href="#fattura.pdf">{t("payment.viewInvoice")}</a>
        </div>
      </div>
    </div>
  );
});
