import React, { useEffect, useState } from "react";
import { FormRenderProps } from "react-final-form";
import { Form as BSForm } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { mdiOfficeBuilding, mdiHospitalBuilding } from "@mdi/js";

import {
  FormErrors,
  ValidateForm,
  TransportRequestDraftValues,
  HealthBuildingPlace,
  PlaceType,
} from "../../types";
import { useStores } from "../../hooks/useStores";

import { TextInput } from "../../components/TextInput";
import {
  TabbedRadioInput,
  TabbedRadioInputContainer,
  TabbedRadioInputSeparator,
} from "../../components/TabbedRadioInput";
import { ErrorLabel } from "../../components/ErrorLabel";
import { RadioInputOption } from "../../components/RadioInput";
import { LoadingMessage } from "../../components/LoadingMessage";
import { ToggleInputList } from "../../components/ToggleInputList";
import env from "../../config/env";
import { AddressInput } from "../../components/AddressInput";

export const ArrivalPlaceStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = (props) => <PlaceStageForm {...props} placeKey="arrivalPlace" />;

export const DeparturePlaceStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = (props) => <PlaceStageForm {...props} placeKey="departurePlace" />;

const PlaceStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues> & {
    placeKey: "arrivalPlace" | "departurePlace";
  }
> = observer((props) => {
  const { values, placeKey, form } = props;
  const placeValues = values[placeKey];

  const [isLoadingAutofill, setIsLoadingAutofill] = useState(false);

  const { t } = useTranslation();
  const { session } = useStores();

  const autofillSavedPlace = (placeId: string) => {
    const savedPlace = session.places.find((place) => place.id === placeId);
    if (savedPlace) {
      console.log("autofill saved place", placeId, toJS(savedPlace));
      setIsLoadingAutofill(true);
      // FIXME: form changes not carried out by either form.reset or form.batch changes
      // Workaround: timeout and loading screen
      setTimeout(() => {
        form.reset({
          ...values,
          [placeKey]: {
            ...placeValues,
            ...savedPlace,
          },
        });
        setIsLoadingAutofill(false);
      }, 1000);
    }
  };

  useEffect(() => {
    const shouldAutofillSavedPlace = placeValues?.id && placeValues?.address;
    if (shouldAutofillSavedPlace && placeValues?.id) {
      autofillSavedPlace(placeValues.id);
    }
  }, [placeValues?.id]);

  if (isLoadingAutofill) {
    return <LoadingMessage description={t("common.loading")} />;
  }

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">
        {placeKey === "arrivalPlace"
          ? t("transport.stages.arrivalPlace")
          : t("transport.stages.departurePlace")}
      </h4>
      <div className="form-container-sm">
        <AddressInput
          name={placeKey}
          names={{
            address: `${placeKey}.address`,
            city: `${placeKey}.city`,
            province: `${placeKey}.province`,
            country: `${placeKey}.country`,
            zipCode: `${placeKey}.zipCode`,
            id: `${placeKey}.id`,
          }}
          label={placeKey === "departurePlace" ? t("trip.from") : t("trip.to")}
          disabled
        />

        <TextInput name={`${placeKey}.name`} label={t("place.name")} />

        <TabbedRadioInputContainer>
          <TabbedRadioInput
            name={`${placeKey}.type`}
            label={t(`place.${PlaceType.HOUSE}`)}
            value={PlaceType.HOUSE}
            icon={mdiOfficeBuilding}
            iconSize={2}
          />
          <TabbedRadioInputSeparator />
          <TabbedRadioInput
            name={`${placeKey}.type`}
            label={t(`place.${PlaceType.HEALTH_FACILITY}`)}
            value={PlaceType.HEALTH_FACILITY}
            icon={mdiHospitalBuilding}
            iconSize={2}
          />
        </TabbedRadioInputContainer>
        <ErrorLabel name={`${placeKey}.type`} />

        <BSForm.Group>
          <BSForm.Label>{t("place.floor")}</BSForm.Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            <RadioInputOption
              name={`${placeKey}.floor`}
              label={t("place.groundFloor")}
              value={"Piano terra"}
            />
            <TextInput name={`${placeKey}.floor`} />
          </div>
        </BSForm.Group>

        {placeValues?.type === PlaceType.HEALTH_FACILITY && (
          <>
            <TextInput
              name={`${placeKey}.hospitalWard`}
              label={t("place.hospitalWard")}
            />
            <TextInput
              name={`${placeKey}.hospitalBuilding`}
              label={t("place.hospitalBuilding")}
            />
            {/* <TextInput
                name="hospitalWardReference"
                label={t('place.hospitalWardReference')}
              /> */}
          </>
        )}

        <ToggleInputList
          name={`${placeKey}.details`}
          label={t("place.buildingDetails")}
          options={session.predefinedOptions.buildingDetail.map((option) => ({
            label: t(`place.details.${option.value}`),
            value: option.value,
          }))}
          block={true}
        />
      </div>
    </div>
  );
});

export const validateDepartureplaceStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  return {
    departurePlace: validatePlaceStageForm(values["departurePlace"]),
  };
};

export const validateArrivalPlaceStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  return {
    arrivalPlace: validatePlaceStageForm(values["arrivalPlace"]),
  };
};

type Values =
  | TransportRequestDraftValues["departurePlace"]
  | TransportRequestDraftValues["arrivalPlace"];

const validatePlaceStageForm = (values?: Values) => {
  const errors: FormErrors<Values> = {};

  // TODO validate address here as well?

  if (!values || !values.type) {
    errors.type = "Scegli il tipo di edificio";
    return errors;
  }

  if (values.floor === undefined) {
    errors.floor = "Il piano è richiesto";
  }

  if (values.type === PlaceType.HEALTH_FACILITY) {
    const health = values as Partial<HealthBuildingPlace>;
    const healthErrors = errors as FormErrors<typeof health>;

    if (!health.name) {
      healthErrors.name = "Il nome dell'ospedale è richiesto";
    }
    // if (!health.hospitalWard) {
    //   healthErrors.hospitalWard = "Il reparto dell'ospedale è richiesto";
    // }
    // if (!health.hospitalWardReference) {
    //   healthErrors.hospitalWardReference =
    //     "Il riferimento al reparto dell'ospedale è richiesto";
    // }
  }

  return errors;
};
