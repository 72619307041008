import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, Redirect } from "react-router-dom";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Icon from "@mdi/react";
import {
  mdiCancel,
  mdiCash,
  mdiArrowLeftCircle,
  mdiFileDownload,
} from "@mdi/js";

import { useStores } from "../../hooks/useStores";
import {
  RefuseQuotationModal,
  RefuseValues,
} from "../../components/RefuseQuotationModal";
import { RadioInput } from "../../components/RadioInput";
import { TextInput } from "../../components/TextInput";
import {
  ValidateForm,
  FormErrors,
  InvoiceHolder,
  InvoiceHolderType,
} from "../../types";
import { PaymentModal } from "../../components/PaymentModal";
import generateQuotation from "../../components/pdfs/Quotation";

type ConfirmationFormValues = InvoiceHolder;

export const ConfirmationStage: React.FC = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const [refuseQuotationModalShow, setRefuseQuotationModalShow] =
    useState(false);

  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const invoiceHolders = [
    {
      label: t("quotation.applicant"),
      value: InvoiceHolderType.APPLICANT,
    },
    {
      label: t("quotation.transported"),
      value: InvoiceHolderType.PATIENT,
    },
    {
      label: t("quotation.conventions"),
      value: InvoiceHolderType.CONVENTIONS,
    },
  ];

  const { transports, session } = useStores();
  const { requestDraft } = transports;

  useEffect(() => {
    if (!requestDraft?.quotation) {
      requestDraft?.submitAndEstimate().catch((err) => {
        window.alert(err.message);
        history.goBack();
        // TODO show modal for failed validation suggesting to retry
      });
    }
  }, []);

  const onConfirmAndPay = (values: InvoiceHolder) => {
    // Ensure there's no conventionNumber left
    if (values.type !== InvoiceHolderType.CONVENTIONS) {
      delete values.conventionNumber;
    }
    requestDraft?.setInvoiceHolder(values);

    requestDraft?.requestQuotationVerificationCode();

    // TODO: ...show input to confirm verification code
    // then proceeed with the following...

    requestDraft?.confirmQuotationVerificationCode("somecode");
    // Replace history, don't permit to go back to this screen
    // history.replace('/transport/completed');

    setPaymentModalShow(true);
  };

  const onPay = (values: InvoiceHolder) => {
    // PAYMENT METHOD

    history.replace("/transport/completed");
  };

  const onRefuse = () => {
    setRefuseQuotationModalShow(true);
  };

  const onSubmitRefuse = async (values: RefuseValues) => {
    const cancelReasons =
      session.predefinedOptions.quotationCancelReason.filter((r) =>
        values.reasons.includes(r.value)
      );

    await requestDraft?.cancelQuotation(cancelReasons);

    transports.clearRequestDraft();
    history.push("/");
  };

  if (!requestDraft) {
    return <Redirect to="/transport" />;
  }

  const quotation = requestDraft.quotation;
  if (!quotation) {
    return (
      <div className="stage-form">
        <h4 className="stage-form--title">{t("quotation.loading")}...</h4>
        <p>{t("common.waitAMoment")}</p>
      </div>
    );
  }

  const waitingTime = {
    hours: Math.floor(quotation.waitingTime / (60 * 60)),
    minutes: Math.floor(quotation.waitingTime / 60) % 60,
  };

  return (
    <Form<ConfirmationFormValues>
      initialValues={{
        type: InvoiceHolderType.APPLICANT,
      }}
      validate={validateConfirmationForm}
      onSubmit={onConfirmAndPay}
      render={({ values, handleSubmit }) => (
        <form className="stage-form" onSubmit={handleSubmit}>
          <h4 className="stage-form--title">{t("quotation.quotation")}</h4>
          <div className="form-container-sm">
            <div style={{ textAlign: "center" }}>
              <label>{t("quotation.maxCostIs")}:</label>

              <h3 style={{ fontWeight: "bold" }}>€ {quotation.cost}</h3>

              <label style={{ fontSize: "small" }}>
                {t("quotation.costAlert1")}
              </label>
              <label style={{ fontSize: "small", color: "red" }}>
                {t("quotation.costAlert2")}
              </label>
              <br />
              <a href="#how-it-works">{t("quotation.findHowCalculated")}</a>
            </div>
            <div className="text-center">
              <label>{t("quotation.timeToWaitIs")}:</label>
              <h3>
                {waitingTime.hours.toString().padStart(2, "0")}h{" "}
                {waitingTime.minutes.toString().padStart(2, "0")}m
              </h3>
              <label>{t("quotation.timeToWaitSpec")}</label>
            </div>

            <div>
              <RadioInput
                name="type"
                options={invoiceHolders}
                label={t("quotation.invoiceHolderDescription")}
              />
              {values.type === InvoiceHolderType.CONVENTIONS && (
                <div>
                  <TextInput
                    name="conventionNumber"
                    label={t("quotation.conventionNumber")}
                  />
                </div>
              )}
            </div>

            <div style={{ textAlign: "center", margin: "2rem auto" }}>
              <button
                type="button"
                onClick={() =>
                  generateQuotation(
                    requestDraft,
                    session.user,
                    values.type,
                    values.conventionNumber
                  )
                }
              >
                <strong>{t("quotation.viewQuotation")}</strong>{" "}
                <Icon size={1} path={mdiFileDownload} />
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => history.push("/transport")}
              className={"back-btt"}
            >
              <Icon path={mdiArrowLeftCircle} title={"Go back"} />
            </Button>
            <Button
              onClick={onRefuse}
              variant="danger"
              className="submitFormBtt"
            >
              {t("common.refuse")}
              <Icon path={mdiCancel} />
            </Button>
            <Button type="submit" variant="primary" className="submitFormBtt">
              {t("common.continue")}
              <Icon path={mdiCash} />
            </Button>
          </div>

          <RefuseQuotationModal
            show={refuseQuotationModalShow}
            quotationCancelReasons={
              session.predefinedOptions.quotationCancelReason
            }
            onSubmit={onSubmitRefuse}
            onHide={() => setRefuseQuotationModalShow(false)}
          />

          <PaymentModal
            show={paymentModalShow}
            quotationId={requestDraft.quotationRequest?.id}
            quotation={quotation}
            onHide={() => setPaymentModalShow(false)}
          />
        </form>
      )}
    />
  );
});

const validateConfirmationForm: ValidateForm<ConfirmationFormValues> = (
  values
) => {
  const errors: FormErrors<ConfirmationFormValues> = {};

  if (!values.type) {
    errors.type = "Scegli un soggetto";
  }
  if (
    values.type === InvoiceHolderType.CONVENTIONS &&
    !values.conventionNumber
  ) {
    errors.conventionNumber = "Il numero della convenzione è richiesto";
  }

  return errors;
};
