import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { FORM_ERROR, FormApi } from "final-form";
import { Container, Button, Form as BSForm, Col } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiFloppy } from "@mdi/js";

import { useStores } from "../hooks/useStores";
import { UserProfile, ValidateForm, FormErrors } from "../types";

import { TextInput } from "../components/TextInput";
import { DateInput } from "../components/DateInput";
import { TextArea } from "../components/TextArea";
import { ErrorLabel } from "../components/ErrorLabel";

type ChangePasswordValues = {
  currentPassword: string;
  newPassword: string;
};

export const UserProfilePage: React.FC = observer(() => {
  const { t } = useTranslation();
  const { session } = useStores();

  const onChangePasswordSubmit = async (
    values: ChangePasswordValues,
    formProps: FormApi<ChangePasswordValues>
  ) => {
    try {
      await session.changePassword(values.currentPassword, values.newPassword);
      // Clear the form to prevent the user from submitting the same, now old, values
      // @ts-ignore
      setTimeout(() => formProps.restart());
    } catch (err: any) {
      return {
        [FORM_ERROR]: `Cambio password fallito: ${err.message}`,
      };
    }
  };

  if (!session.user) {
    return null;
  }

  return (
    <Container>
      <div className="main-title">
        <h4>{t("person.personalData")}</h4>
      </div>

      <Form
        initialValues={{ ...session.user }}
        validate={validateProfileForm}
        onSubmit={(values: UserProfile) => session.updateMyPatient(values)}
        render={(formProps) => (
          <BSForm
            onSubmit={formProps.handleSubmit}
            className="form-container-sm"
          >
            <BSForm.Row>
              <Col>
                <TextInput
                  name="companyName"
                  label={t("person.companyName")}
                  placeholder="Tech S.R.L."
                />
              </Col>
            </BSForm.Row>
            <BSForm.Row>
              <Col>
                <TextInput
                  name="name"
                  label={t("person.givenName")}
                  placeholder="Mario"
                />
              </Col>
              <Col>
                <TextInput
                  name="surname"
                  label={t("person.familyName")}
                  placeholder="Rossi"
                />
              </Col>
            </BSForm.Row>
            <TextInput
              name="email"
              type="email"
              label={t("session.email")}
              placeholder="mario.rossi@example.com"
              autocomplete="username"
            />

            <BSForm.Row>
              <Col>
                <DateInput name="birthDate" label={t("person.bornOn")} />
              </Col>
              <Col>
                <TextInput
                  name="birthPlace"
                  label={t("person.bornAt")}
                  placeholder={t("person.bornAtPlaceholder")}
                />
              </Col>
            </BSForm.Row>

            <TextInput
              name="fiscalCode"
              label={t("person.fiscalCode")}
              placeholder="RSSBBR69C48F839A"
            />
            <TextInput
              name="healthInsuranceCard"
              label={t("person.healthInsuranceCard")}
              placeholder="1234567…"
            />
            <BSForm.Row>
              <Col>
                <TextInput
                  name="vatNumber"
                  label={t("person.vatNumber")}
                  placeholder="00000000000"
                />
              </Col>
            </BSForm.Row>
            <BSForm.Row>
              <Col>
                <TextInput
                  name="sdi"
                  label={t("person.sdi")}
                  placeholder="5ED7F9"
                />
              </Col>
            </BSForm.Row>

            <BSForm.Row>
              <Col>
                <TextInput
                  name="mobilePhone"
                  label={t("person.mobilePhone")}
                  placeholder="+393334445555"
                />
              </Col>
              <Col>
                <TextInput
                  name="phoneNumber"
                  label={t("person.phoneNumber")}
                  placeholder="+390494445555"
                />
              </Col>
            </BSForm.Row>

            <TextInput
              name="residentialAddress"
              label={t("person.residentialAddress")}
              placeholder="Via Tal dei Tali"
            />
            <BSForm.Row>
              <Col>
                <TextInput name="country" label={t("person.nation")} />
              </Col>
              <Col>
                <TextInput name="province" label={t("person.province")} />
              </Col>
            </BSForm.Row>
            <BSForm.Row>
              <Col>
                <TextInput name="city" label={t("person.city")} />
              </Col>
              <Col>
                <TextInput name="zipCode" label={t("person.postalCode")} />
              </Col>
            </BSForm.Row>

            <TextArea name="notes" label={t("common.note")} />

            <div className="text-center">
              <Button
                type="submit"
                variant="primary"
                disabled={formProps.pristine || formProps.submitting}
              >
                {t("common.save")}{" "}
                <Icon size={1} path={mdiFloppy} title={t("common.save")} />
              </Button>
            </div>
          </BSForm>
        )}
      />
      <hr />
      <br />
      <h4>{t("session.changePassword")}</h4>
      <br />
      <Form<ChangePasswordValues>
        validate={validatePasswordForm}
        onSubmit={onChangePasswordSubmit}
        render={(formProps) => (
          <BSForm
            onSubmit={formProps.handleSubmit}
            className="form-container-sm"
          >
            {/* Username hidden input, to help password manager; not used in practice */}
            <input
              name="username"
              type="text"
              value={session.user?.username}
              autoComplete="username"
              style={{ display: "none" }}
              readOnly
            />
            <TextInput
              type="password"
              name="currentPassword"
              label={t("session.currentPassword")}
              autocomplete="current-password"
            />
            <TextInput
              type="password"
              name="newPassword"
              label={t("session.newPassword")}
              autocomplete="new-password"
            />
            <div className="text-center">
              <Button
                type="submit"
                variant="primary"
                disabled={formProps.pristine || formProps.submitting}
              >
                {t("common.save")}{" "}
                <Icon size={1} path={mdiFloppy} title={t("common.save")} />
              </Button>
            </div>
            <ErrorLabel name={FORM_ERROR} />
          </BSForm>
        )}
      />
    </Container>
  );
});

// Used in registration and profile editing
export const validateProfileForm: ValidateForm<UserProfile> = (values) => {
  const errors: FormErrors<UserProfile> = {};

  if (!values.name) {
    errors.name = "Il nome è richiesto";
  }
  if (!values.surname) {
    errors.surname = "Il cognome è richiesto";
  }
  if (!values.birthPlace) {
    errors.birthPlace = "La città di nascita è richiesta";
  }
  if (!values.birthDate) {
    errors.birthDate = "La data di nascita è richiesta";
  }
  if (!values.residentialAddress) {
    errors.residentialAddress = "L'indirizzo di residenza è richiesto";
  }
  if (!values.fiscalCode) {
    errors.fiscalCode = "Il codice fiscale è richiesto";
  }
  if (!values.mobilePhone && !values.phoneNumber) {
    errors.phoneNumber = "Almeno un numero di telefono è richiesto";
    errors.mobilePhone = "Almeno un numero di telefono è richiesto";
  }
  if (!values.email) {
    errors.email = "L'email è richiesta";
  }

  // TODO: other data validation

  return errors;
};

const validatePasswordForm: ValidateForm<ChangePasswordValues> = (values) => {
  const errors: FormErrors<ChangePasswordValues> = {};
  if (!values.currentPassword) {
    errors.currentPassword = "Inserire la password attuale per sicurezza";
  }
  if (!values.newPassword) {
    errors.newPassword = "La nuova password non può essere vuota";
  }
  return errors;
};
