import React from "react";
import { Field } from "react-final-form";
import Form from "react-bootstrap/Form";

import { ErrorLabel } from "./ErrorLabel";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
};

export const TextArea: React.FC<Props> = ({ name, label, placeholder }) => (
  <Field
    name={name}
    render={({ input }) => {
      return (
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Control as="textarea" placeholder={placeholder} {...input} />
          <ErrorLabel name={name} />
        </Form.Group>
      );
    }}
  />
);
