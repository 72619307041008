import { makeAutoObservable } from "mobx";

import {
  Patient,
  Stores,
  UserProfile,
  Place,
  NewUserProfile,
  PaymentMethod,
  AllPredefinedOptions,
} from "../types";

import api from "../services/api";
import { FullAddressInputSuggestion } from "../components/AddressInput";

export class SessionStore {
  stores: Stores;

  sessionToken?: string = undefined;

  user?: UserProfile = undefined;

  otherPatients: Patient[] = [];
  isLoadingPatients = false;

  places: Place[] = [];
  isLoadingPlaces = false;

  paymentMethods: PaymentMethod[] = [];
  isLoadingPaymentMethods = false;

  predefinedOptions: AllPredefinedOptions = {
    buildingDetail: [],
    buildingType: [],
    patientDisease: [],
    patientImmobilizationAid: [],
    patientMobility: [],
    patientSpecialNeed: [],
    quotationCancelReason: [],
    quotationStatus: [],
    tripExamination: [],
    tripReason: [],
  };
  isLoadingPredefinedOptions = false;

  get isLoading(): boolean {
    return (
      this.isLoadingPatients ||
      this.isLoadingPlaces ||
      this.isLoadingPredefinedOptions
    );
  }

  get isLogged(): boolean {
    return this.sessionToken != null && this.user != null;
  }

  constructor(stores: Stores) {
    this.stores = stores;

    makeAutoObservable(this);
  }

  init = async () => {
    this.restoreSession();
  };

  login = async (usernameOrEmail: string, password: string) => {
    try {
      const response = await api.login(usernameOrEmail, password);
      this.sessionToken = response.Token;
      await this.fetchUserData();
      this.persistSession();
    } catch (err: any) {
      this.reset();
      throw err;
    }
  };

  logout = async () => {
    try {
      await api.logout();
    } finally {
      this.reset();
    }
  };

  register = async (newUser: NewUserProfile) => {
    await api.registerNewUser(newUser);
  };

  fetchAllPredefinedOptions = async () => {
    const hasAnyEmpty = Object.values(this.predefinedOptions).some(
      (predefinedOptions) => !predefinedOptions.length
    );
    if (!hasAnyEmpty) {
      // Already fetched all, probably won't change, no need to re-fetch them
      return;
    }

    this.isLoadingPredefinedOptions = true;
    try {
      this.predefinedOptions = await api.getAllPredefinedOptions();
    } finally {
      this.isLoadingPredefinedOptions = false;
    }
  };

  fetchPatients = async () => {
    this.isLoadingPatients = true;
    this.otherPatients = [];
    try {
      this.otherPatients = await api.getPatients();
    } finally {
      this.isLoadingPatients = false;
    }
  };

  addPatient = async (patient: Patient) => {
    const allPredefinedOptions = await api.getAllPredefinedOptions();
    const added = await api.addPatient(patient, allPredefinedOptions);
    this.otherPatients.push(added);
  };

  updatePatient = async (patient: Patient) => {
    const allPredefinedOptions = await api.getAllPredefinedOptions();
    const updated = await api.updatePatient(patient, allPredefinedOptions);
    const index = this.otherPatients.findIndex((p) => p.id === patient.id);
    if (index >= 0) {
      this.otherPatients[index] = updated;
    }
  };

  deletePatient = async (patientId: string) => {
    await api.deletePatient(patientId);
    this.otherPatients = this.otherPatients.filter((p) => p.id !== patientId);
  };

  fetchPlaces = async () => {
    this.isLoadingPlaces = true;
    this.places = [];
    try {
      this.places = await api.getPlaces();
    } finally {
      this.isLoadingPlaces = false;
    }
  };

  addPlace = async (place: Place) => {
    const allPredefinedOptions = await api.getAllPredefinedOptions();
    const added = await api.addPlace(place, allPredefinedOptions);
    this.places.push(added);
  };

  updatePlace = async (place: Place) => {
    const allPredefinedOptions = await api.getAllPredefinedOptions();
    const updated = await api.updatePlace(place, allPredefinedOptions);
    const index = this.places.findIndex((p) => p.id === place.id);
    if (index >= 0) {
      this.places[index] = updated;
    }
  };

  deletePlace = async (placeId: string) => {
    await api.deletePlace(placeId);
    this.places = this.places.filter((p) => p.id !== placeId);
  };

  get placesAddressSuggestions(): FullAddressInputSuggestion[] {
    return this.places.map((place) => ({
      address: place.address,
      city: place.city,
      country: place.country,
      province: place.province,
      zipCode: place.zipCode,
      name: place.name,
      id: place.id,
    }));
  }

  updateMyPatient = async (updatedPatient: UserProfile) => {
    await api.editUser(updatedPatient);
    await this.fetchUserData();
  };

  changePassword = async (currentPassword: string, newPassword: string) => {
    await api.changeUserPassword(currentPassword, newPassword);
  };

  fetchPaymentMethods = async () => {
    this.isLoadingPlaces = true;
    this.places = [];
    try {
      this.paymentMethods = await api.getPaymentMethods();
    } finally {
      this.isLoadingPlaces = false;
    }
  };

  addPaymentMethod = async (payment: PaymentMethod) => {
    // TODO: update on backend

    this.paymentMethods.push(payment);
  };

  updatePaymentMethod = async (payment: PaymentMethod) => {
    // TODO: update on backend

    this.paymentMethods = this.paymentMethods.map((p) =>
      p.id === payment.id ? payment : p
    );
  };

  removePaymentMethod = async (payment: PaymentMethod) => {
    // TODO: update on backend

    this.paymentMethods = this.paymentMethods.filter(
      (p) => p.id !== payment.id
    );
  };

  private restoreSession = async () => {
    const sessionItem = localStorage.getItem("session");

    if (!sessionItem) {
      return;
    }

    const session = JSON.parse(sessionItem);
    this.sessionToken = session.token;
    api.setSession(session.token);

    try {
      await this.fetchUserData();
    } catch (err: any) {
      // Abort and reset session
      this.reset();
      throw err;
    }
  };

  private persistSession = async () => {
    const data = {
      token: this.sessionToken,
    };
    localStorage.setItem("session", JSON.stringify(data));
  };

  private reset() {
    this.otherPatients = [];
    this.places = [];
    this.paymentMethods = [];

    this.sessionToken = undefined;
    this.user = undefined;
    api.clearSession();
    localStorage.removeItem("session");
  }

  private fetchUserData = async () => {
    const user = await api.getUser();
    this.user = user;
  };
}
