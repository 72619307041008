import moment from "moment";
import {
  RefuseReason,
  BookedTransportRequest,
  QuotationStatus,
  PredefinedOption,
} from "../types";
import { TransportRequestDraft } from "./TransportRequestDraft";
import api from "../services/api";

/** Transport request previously done, obtained from backend. */
export class TransportRequest {
  data: BookedTransportRequest;

  get id(): string {
    return this.data.id;
  }

  get status(): QuotationStatus | null {
    return this.data.quotation.status
      ? (this.data.quotation.status.value as QuotationStatus)
      : null;
  }

  get isCanceled(): boolean {
    const isAlreadyCanceled =
      this.status === QuotationStatus.CANCELED ||
      this.status === QuotationStatus.REFUSED;
    return !!this.data.cancelReasons.length || isAlreadyCanceled;
  }

  get isExpired(): boolean {
    return moment().isAfter(this.data.trip.departureTime);
  }

  get isCancelable(): boolean {
    return !this.isCanceled || this.isExpired;
  }

  get isExecuted(): boolean {
    return (
      this.isExpired &&
      !this.isCanceled &&
      // if expired but just inserted and not accepted => not executed
      this.status === QuotationStatus.ACCEPTED
    );
  }

  constructor(data: BookedTransportRequest) {
    this.data = data;
  }

  cloneAsDraft(alterations: { departureTime: string }): TransportRequestDraft {
    const draftRequest = new TransportRequestDraft();

    draftRequest.editValues({
      // TODO: should map over values more clearly?
      departurePlace: {
        ...this.data.departurePlace,
      },
      arrivalPlace: {
        ...this.data.arrivalPlace,
      },
      trip: {
        ...this.data.trip,
        departureTime: alterations.departureTime,
      },
      patient: {
        ...this.data.patient,
        consentGdpr: true,
      },
    });

    return draftRequest;
  }

  refuse = async (reason: PredefinedOption, note?: string) => {
    // TODO: tell backend to delete this request
    console.log(
      `TransportRequest ${this.id} canceled, reason:`,
      reason,
      "note:",
      note
    );
    // this.cancelable = false;

    await api.deleteMission(this.id, reason, note);
  };

  redate = async (newDepartureTime: string) => {
    // TODO: tell backend to re-date this request
    this.data.trip.departureTime = newDepartureTime;
  };
}
