import React from "react";
import { Field, FieldProps, FieldRenderProps } from "react-final-form";

export const ErrorLabel: React.FC<FieldProps<any, FieldRenderProps<"div">>> = ({
  name,
}) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true, submitFailed: true }}
    render={({ meta: { touched, error, submitFailed, submitError } }) =>
      (touched && error) || submitFailed ? (
        <span className="form--error-label">{error || submitError}</span>
      ) : null
    }
  />
);
