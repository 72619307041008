import React, { useEffect } from "react";
import { FormRenderProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  FormErrors,
  TransportRequestDraftValues,
  ValidateForm,
} from "../../types";
import { useStores } from "../../hooks/useStores";
import { poValueToLabel } from "../../utils/predefinedOptions";

import { RadioInput } from "../../components/RadioInput";
import { ToggleInputList } from "../../components/ToggleInputList";
import { LoadingMessage } from "../../components/LoadingMessage";
import { Col, Row } from "react-bootstrap";
import { DateInput } from "../../components/DateInput";
import { TextInput } from "../../components/TextInput";
import { CheckboxInput } from "../../components/CheckboxInput";
import { TermsAndConditions } from "../../components/TermsAndConditions";

export const TripStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = observer((props) => {
  const { t } = useTranslation();
  const { session } = useStores();
  useEffect(() => {
    session.fetchAllPredefinedOptions();
  }, []);

  if (session.isLoading) {
    return <LoadingMessage />;
  }

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">{t("transport.stages.transport")}</h4>
      {/* <label>{t('transport.transportStageFormDescription')}</label> */}
      <div className="form-container-sm">
        <RadioInput
          label={t("trip.reason")}
          name="trip.reason"
          options={session.predefinedOptions.tripReason.map((option) => ({
            label: poValueToLabel(option.value),
            value: option.value,
          }))}
        />
        {/* 
        <RadioInput
          label={t('trip.examinations')}
          name="trip.examinations"
          options={session.predefinedOptions.tripExamination.map((option) => ({
            label: poValueToLabel(option.value),
            value: option.value,
          }))}
        /> */}

        {props.values.trip.reason === "VISITA_CON_ESAMI" && (
          <>
            <CheckboxInput
              name="trip.consentCompanion"
              description={t("transport.requestForCompanion")}
            />
            <ToggleInputList
              label={t("trip.examinations")}
              name="trip.examinations"
              options={session.predefinedOptions.tripExamination.map(
                (option) => ({
                  label: poValueToLabel(option.value),
                  value: option.value,
                })
              )}
            />
            <TextInput
              name="trip.orExecutionTime"
              label={t("transport.orExecutionTime")}
            />
          </>
        )}
      </div>
    </div>
  );
});

export const validateTransportStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  const routeErrors: FormErrors<TransportRequestDraftValues["trip"]> = {};

  if (!values.trip.reason) {
    routeErrors.reason = "Scegli il tipo di tratta";
  }
  // if (!values.trip.examinations) {
  //   routeErrors.examinations = 'Scegli una motivazione';
  // }

  return {
    trip: routeErrors,
  };
};
