import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Button, Form as BSForm } from "react-bootstrap";
import { observer } from "mobx-react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiEmail, mdiMicrophone, mdiAccountGroup, mdiGauge } from "@mdi/js";

import BackgroundImage from "../assets/images/home_background.jpg";

import { useStores } from "../hooks/useStores";
import { TransportRequestDraft } from "../models/TransportRequestDraft";
import history from "../history";
import { TransportRequestDraftValues, FormErrors } from "../types";

import { SelectInput } from "../components/SelectInput";
import { DateTimeInput } from "../components/DateTimeInput";
import { CheckboxInput } from "../components/CheckboxInput";
import { AddressInput } from "../components/AddressInput";

export const Home: React.FC = observer(() => {
  const [initialDraft] = useState(() => new TransportRequestDraft());

  const { t } = useTranslation();
  const { transports, session } = useStores();

  const handleSubmit = (values: TransportRequestDraftValues) => {
    initialDraft.editValues(values);
    transports.setRequestDraft(initialDraft);
    history.push("/transport");
  };

  return (
    <Container
      fluid
      className="main--container"
      style={{
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      <Container
        fluid
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container>
          <Row>
            <Col xs={12} md={8} lg={5} className="home-form-container">
              <h2>{t("home.whatDoYouNeed")}</h2>
              <h3 style={{ marginBottom: "1rem" }}>{t("home.description")}</h3>
              <Form
                initialValues={initialDraft.values}
                validate={validateHomeForm}
                onSubmit={handleSubmit}
                render={(formProps) => (
                  <BSForm onSubmit={formProps.handleSubmit}>
                    <SelectInput
                      name="transportType"
                      options={[{ key: "ambulance", label: "Ambulanza" }]}
                    />

                    <AddressInput
                      name={`departurePlace`}
                      names={{
                        address: "departurePlace.address",
                        city: "departurePlace.city",
                        province: "departurePlace.province",
                        country: "departurePlace.country",
                        zipCode: "departurePlace.zipCode",
                        id: "departurePlace.id",
                      }}
                      label={t("trip.from")}
                      placeholder={t("trip.address")}
                      suggestions={session.placesAddressSuggestions}

                      // TODO: suggestions from where?
                    />
                    <AddressInput
                      name={`arrivalPlace`}
                      names={{
                        address: "arrivalPlace.address",
                        city: "arrivalPlace.city",
                        province: "arrivalPlace.province",
                        country: "arrivalPlace.country",
                        zipCode: "arrivalPlace.zipCode",
                        id: "arrivalPlace.id",
                      }}
                      label={t("trip.to")}
                      placeholder={t("trip.address")}
                      suggestions={session.placesAddressSuggestions}
                    />

                    <DateTimeInput
                      name="trip.departureTime"
                      label={t("common.date")}
                    />
                    <CheckboxInput
                      name="trip.roundTrip"
                      description={t("trip.roundTripTitle")}
                    />
                    <Button variant="primary" type="submit" block>
                      {t("home.enter")}
                    </Button>
                  </BSForm>
                )}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className="home--primary-links-wrapper">
        <Row>
          <Col>
            <Link to="/" className="secondary-link">
              <Icon path={mdiEmail} />
              <br />
              {t("home.contacts")}
            </Link>
          </Col>
          <Col>
            <Link to="/" className="secondary-link">
              <Icon path={mdiMicrophone} />
              <br />
              {t("home.news")}
            </Link>
          </Col>
          <Col>
            <Link to="/" className="secondary-link">
              <Icon path={mdiAccountGroup} />
              <br />
              {t("home.contributing")}
            </Link>
          </Col>
          <Col>
            <Link to="/" className="secondary-link">
              <Icon path={mdiGauge} />
              <br />
              {t("home.transparency")}
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  );
});

const validateHomeForm: any = (values: any) => {
  const errors: FormErrors<TransportRequestDraftValues> = {};

  // if (!values.tripType) {
  //   errors.tripType = 'Scegli il tipo di tratta';
  // }
  // if (!values.motivation) {
  //   errors.motivation = 'Scegli una motivazione';
  // }

  return errors;
};
