import React, { useMemo } from "react";
import moment from "moment";
import { Field } from "react-final-form";
import { Col, Form } from "react-bootstrap";
import { DatePicker, TimePicker } from "@material-ui/pickers";

type Props = {
  name: string;
  label?: string;
};

export const DateTimeInput: React.FC<Props> = ({ name, label }) => {
  const setDate = (date: any) => {
    var selectedDay = moment(date);

    if (moment().diff(selectedDay, "days") == 0) {
      if (selectedDay.diff(moment(), "minutes") > 30) {
        return selectedDay.toDate();
      }

      return moment().add(30, "minutes").toDate();
    }

    return selectedDay.toDate();
  };

  return (
    <Field
      name={name}
      subscribe={{ touched: true, error: true, submitFailed: true }}
      render={({ meta, input }) => {
        const { touched, error, submitFailed } = meta;
        const showError = (touched && error) || submitFailed;

        const dateValue = input.value ? setDate(input.value) : null;
        return (
          <Form.Group>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Row>
              <Col>
                <DatePicker
                  value={dateValue}
                  onChange={(date) => input.onChange(date?.toISOString())}
                  format={"DD/MM/YYYY"}
                  autoOk={true}
                  variant="inline"
                  initialFocusedDate={new Date()}
                  disablePast={true}
                  fullWidth={true}
                  animateYearScrolling
                />
              </Col>
              <Col>
                <TimePicker
                  value={dateValue}
                  autoOk={true}
                  onChange={(date) => input.onChange(date?.toISOString())}
                  variant="inline"
                  ampm={false}
                  fullWidth={true}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                {showError && (
                  <span style={{ color: "red", fontSize: 14 }}>{error}</span>
                )}
              </Col>
            </Form.Row>
          </Form.Group>
        );
      }}
    />
  );
};
