import { SessionStore } from "./stores/SessionStore";
import { TransportsStore } from "./stores/TransportsStore";

/**
 * final-form error format: for each key there can be a string with the error message
 */
export type FormErrors<Values> = {
  // FormErrors<Values[x]> for nested object
  // Partial<Values> because every key for nested object is optional as well
  [x in keyof Partial<Values>]?: string | FormErrors<Values[x]>;
  // TODO: add `[FORM_ERROR]?: string;` for generic final-form errors, useful for submit errors
};

export type ValidateForm<Values> = (values: Values) => FormErrors<Values>;

export type Stores = {
  session: SessionStore;
  transports: TransportsStore;
};

export type User = {
  token: string;
};

export type PredefinedOption = {
  id: number;
  value: string;
};

export type AllPredefinedOptions = {
  patientMobility: PredefinedOption[];
  patientImmobilizationAid: PredefinedOption[];
  patientSpecialNeed: PredefinedOption[];
  patientDisease: PredefinedOption[];
  buildingType: PredefinedOption[];
  buildingDetail: PredefinedOption[];
  tripReason: PredefinedOption[];
  tripExamination: PredefinedOption[];
  quotationStatus: PredefinedOption[];
  quotationCancelReason: PredefinedOption[];
};

export type Trip = {
  departureTime: string;
  reason: PredefinedOption["value"] | null;
  examinations?: Array<PredefinedOption["value"]>;
  notes?: string;
  roundTrip?: boolean;
  consentCompanion?: boolean;
  orExecutionTime?: string;
};

export enum PatientWeightRange {
  LESS_50 = 50,
  RANGE_50_80 = 65, // media fra 50 e 80
  RANGE_80_110 = 95, // media fra 80 e 110
  MORE_110 = 110,
}

export enum PatientRelationship {
  ME = "me",
  RELATIVE = "relative",
  PATIENT = "patient",
}

type Person = {
  id: string;
  name: string;
  surname: string;
  fiscalCode: string;
  birthDate: string;
  birthPlace: string;
  residentialAddress: string;
  phoneNumber?: string;
  mobilePhone?: string;
  vatNumber?: string;
  sdi?: string;
  companyName?: string;
  creationDate: string;
  country: string;
  province: string;
  city: string;
  zipCode: string;
  notes?: string;
};

export type Patient = Person & {
  healthInsuranceCard?: string;
  relationship: PatientRelationship;
  weight: number;
  mobility?: PredefinedOption["value"];
  specialNeeds: Array<PredefinedOption["value"]>;
  diseases: Array<PredefinedOption["value"]>;
  immobilizationAids: Array<PredefinedOption["value"]>;
};

export type NewPatient = Patient & {
  id?: string;
  consentGdpr: boolean;
};

export enum UserRole {
  USER = "user",
  PARTNER = "partner",
}
export type UserProfile = Person & {
  email: string;
  username: string;
  healthInsuranceCard?: string;
};

export type NewUserProfile = UserProfile & {
  password: string;
};

export enum PlaceType {
  // TODO: same values as http://hagile.it/api/Places/BuildingTypesList
  HEALTH_FACILITY = "STRUTTURA_SANITARIA",
  HOUSE = "ABITAZIONE",
}

interface BasePlace {
  type: PlaceType;
  id: string;
  name: string;
  creationDate: string;
  address: string;
  country: string;
  province: string;
  city: string;
  zipCode: string;
  floor: string;
  // TODO: all predefined options as {id,value}; form inputs will consider parse/format value in Field
  details: PredefinedOption["value"][];
  notes?: string;
}

export type HealthBuildingPlace = BasePlace & {
  type: PlaceType.HEALTH_FACILITY;
  hospitalWard?: string;
  // hospitalWardReference?: string;
  hospitalBuilding?: string;
};

export type HousePlace = BasePlace & {
  type: PlaceType.HOUSE;
};

export type Place = HealthBuildingPlace | HousePlace;

export type TransportRequestValues = {
  trip: Trip;
  patient: NewPatient;
  departurePlace: Place;
  arrivalPlace: Place;
};

export type TransportRequestDraftValues = {
  // NOTE: if last entry removed by final form, whole object removed; every entry type should be optional
  trip: Partial<Trip>;
  patient?: Partial<NewPatient>;
  departurePlace?: Partial<Place>;
  arrivalPlace?: Partial<Place>;
};

export enum PaymentMethodProvider {
  MASTERCARD = "Mastercard",
  VISA = "VISA",
}

export interface PaymentMethod {
  id: string;
  provider: PaymentMethodProvider;
  number: string;
  code: string;
  holder: string;
  expiryMonth: string;
  expiryYear: string;
}

export interface Invoice {
  orderId: string;
  date: Date;
  value: number;
}

/**
 * Same values as http://hagile.it/api/Quotations/StatusList
 */
export enum QuotationStatus {
  SUBMITTED = "INSERITO",
  ACCEPTED = "ACCETTATO",
  REFUSED = "RIFIUTATO",
  CANCELED = "ANNULLATO",
}

export type Quotation = {
  /**
   * The status of the quotation.
   * The `value` prop of this option should correspond to a QuotationStatus
   */
  status: PredefinedOption | null;
  cost: number;
  estimateUrl: string;
  /** In seconds */
  examinationsTime: number;
  /** In seconds */
  waitingTime: number;
  /** In seconds */
  travelTime: number;
  /** In KM */
  travelDistance: number;
};

export enum InvoiceHolderType {
  APPLICANT = "applicant",
  PATIENT = "transported",
  CONVENTIONS = "conventions",
}

export type InvoiceHolder = {
  type: InvoiceHolderType;
  conventionNumber?: string;
};

export enum RefuseReason {
  AGGRAVATION = "aggravation",
  VISIT_CANCELED = "visit canceled",
  VISIT_REDATED = "visit redated",
  OTHER = "other",
}

export interface BookedTransportRequest {
  id: string;
  number?: string;
  cancelReasons: PredefinedOption[];
  creationDate: string;
  trip: Trip;
  patient: Patient;
  departurePlace: Place;
  arrivalPlace: Place;
  quotation: Quotation;
}

export interface PaypalPaymentData {
  paymentId: string;
  approvalURL: string;
  accessToken: string;
}

export interface ObtainKeyIDResponse {
  keyID: string;
}

export interface SatispayPaymentResponse {
  id: string;
  type: string;
  amount_unit: number;
  currency: string;
  status: string;
  expired: false;
  metadata: {
    order_id: string;
    user: string;
    payment_id: string;
    session_id: string;
    key: string;
  };
  sender: {
    id: string;
    type: string;
    profile_pictures: {};
  };
  receiver: {
    id: string;
    type: string;
  };
  insert_date: string;
  expire_date: string;
  external_code: string;
}
