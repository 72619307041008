import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import history from "../history";
import { useStores } from "../hooks/useStores";
import { TransportRequest } from "../models/TransportRequest";

import { ReplicateRequestModal } from "../components/ReplicateRequestModal";
import { RefuseRequestModal } from "../components/RefuseRequestModal";
import { TransportList } from "../components/TransportList";

export const UserBookings: React.FC = observer(() => {
  const { transports, session } = useStores();

  const [requestToReplicate, setRequestToReplicate] =
    useState<TransportRequest>();
  const [requestToCancel, setRequestToCancel] = useState<TransportRequest>();

  useEffect(() => {
    transports.fetchTransportRequests();
    session.fetchAllPredefinedOptions().catch(console.warn);
  }, []);

  const { t } = useTranslation();

  return (
    <Container>
      <div className="main-title">
        <h4>{t("reservedArea.bookings")}</h4>
      </div>

      <TransportList
        requests={transports.requests}
        onCancel={setRequestToCancel}
        onReplicate={setRequestToReplicate}
        onViewDetails={(request) =>
          history.push(`/reserved-area/bookings/${request.id}`)
        }
      />

      <ReplicateRequestModal
        requestToReplicate={requestToReplicate}
        onCancel={() => setRequestToReplicate(undefined)}
        onReplicate={(values) =>
          history.push(
            `/transport/new?replicateFromId=${values.requestId}&replicateDepartureTime=${values.departureTime}`
          )
        }
      />
      <RefuseRequestModal
        requestToCancel={requestToCancel}
        onCancel={() => setRequestToCancel(undefined)}
        onRedate={(date) => requestToCancel?.redate(date)}
        onRefuse={(motivation, note) =>
          requestToCancel?.refuse(motivation, note)
        }
        refuseOptions={session.predefinedOptions.quotationCancelReason}
      />
    </Container>
  );
});
