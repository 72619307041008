import i18n from "../services/i18n";
import { AllPredefinedOptions, Place, PlaceType } from "../types";
import { StageStatusValues } from "../components/StageStatus";
import { poValueToLabel } from "./predefinedOptions";

export const makePlaceStatusValues = (
  place: Partial<Place>,
  predefinedOptions: Pick<
    AllPredefinedOptions,
    "buildingDetail" | "buildingType"
  >
): StageStatusValues => {
  const t = (key: string) => i18n.t(key);

  const type = {
    label: t("common.typology"),
    value: t(`place.${place.type}`),
  };
  const floor = {
    label: t("place.floor"),
    value: place.floor?.toString(),
  };
  const details = {
    label: t("common.details"),
    value: place.details?.map((d) => poValueToLabel(d)).join(", ") || "",
  };

  // TODO: convert values to i18n strings
  if (place.type === PlaceType.HOUSE) {
    return [type, floor, details];
  }
  if (place.type === PlaceType.HEALTH_FACILITY) {
    const hospitalName = { label: t("place.denomination"), value: place.name };
    const hospitalWard = {
      label: t("place.hospitalWard"),
      value: place.hospitalWard!,
    };
    return [type, hospitalName, hospitalWard, floor, details];
  }
  return [];
};
