import { makeAutoObservable } from "mobx";
import {
  TransportRequestDraftValues,
  InvoiceHolder,
  Quotation,
  TransportRequestValues,
  BookedTransportRequest,
  PredefinedOption,
} from "../types";
import api from "../services/api";
import { delay } from "../utils/async";
import { validateTransportRequestValues } from "../utils/wizard";
import env from "../config/env";

/** Transport request draft used through the wizard. */
export class TransportRequestDraft {
  /** Form values to build whilist in the wizard */
  values: TransportRequestDraftValues = {
    departurePlace: {},
    arrivalPlace: {},
    patient: {},
    trip: {},
  };

  invoiceHolder?: InvoiceHolder = undefined;

  quotationRequest?: BookedTransportRequest = undefined;

  get quotation(): Quotation | undefined {
    return this.quotationRequest?.quotation;
  }

  quotationConfirmed = false;

  constructor() {
    makeAutoObservable(this);
  }

  editValues = (values: TransportRequestDraftValues) => {
    this.invalidateQuotation();
    this.values = values;
  };

  setInvoiceHolder(invoiceHolder: InvoiceHolder) {
    this.invoiceHolder = invoiceHolder;
  }

  async invalidateQuotation() {
    this.quotationRequest = undefined;
    this.invoiceHolder = undefined;
  }

  async submitAndEstimate() {
    // TODO: call API for estimantion and double-check on values provided
    // map errors received from estimation to form
    // const errors = validateTransportRequestValues(this.values);
    // if (Object.keys(errors).length > 0 && !env.MOCK_FORM_VALIDATION) {
    //   console.warn(errors);
    //   throw new Error('Il form ha degli errori, rifare il wizard');
    // }

    const values = this.values as TransportRequestValues;

    this.quotationRequest = await api.createTransportRequest(values);
  }

  async cancelQuotation(reasons?: Array<PredefinedOption>) {
    if (this.quotationRequest) {
      await api.rejectTransportRequest(
        this.quotationRequest?.id,
        reasons || []
      );

      this.invalidateQuotation();
    }
  }

  async requestQuotationVerificationCode() {
    console.log("requestQuotationVerificationCode...");
  }

  async confirmQuotationVerificationCode(code: string) {
    // TODO: real API call
    await delay(1000);

    this.quotationConfirmed = true;
  }
}
