import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Container, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { mdiFileDownload } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";

import { useStores } from "../hooks/useStores";

export const UserInvoices: React.FC = observer(() => {
  const { transports } = useStores();

  const { t } = useTranslation();

  useEffect(() => {
    transports.fetchInvoices();
  }, []);

  const handleDownloadInvoice = useCallback(
    async (orderId: string) => {
      const documentUrl = await transports.downloadInvoice(orderId);

      window.open(documentUrl);
    },
    [transports]
  );

  return (
    <Container>
      <div className="main-title">
        <h4>{t("reservedArea.invoices")}</h4>
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th>
              <h4 className="light">{t("invoice.date")}</h4>
            </th>
            <th>
              <h4 className="light">{t("invoice.amount")}</h4>
            </th>
            <th>
              <h4 className="light">{t("invoice.Scarica")}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          {transports.requestInvoices
            .slice()
            // .sort((a, b) => (a.timestamp > b.timestamp ? -1 : +1))
            .map((invoice) => (
              <tr key={invoice.orderId}>
                <td>
                  <strong>{invoice.date}</strong>
                </td>
                <td>{invoice.value} €</td>
                <td className="text-right">
                  <Button
                    variant="outline-secondary"
                    onClick={() => handleDownloadInvoice(invoice.orderId)}
                  >
                    <Icon size={1} path={mdiFileDownload} />
                  </Button>
                </td>
              </tr>
            ))}
          {!transports.requestInvoices.length && (
            <tr>
              <td colSpan={10}>{t("common.noElementsToShow")}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
});
