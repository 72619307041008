import { homePlace, hospitalPlace } from "./services/maps";
import {
  Place,
  PlaceType,
  Patient,
  PatientRelationship,
  PatientWeightRange,
  UserProfile,
  PaymentMethod,
  PaymentMethodProvider,
  User,
  AllPredefinedOptions,
  BookedTransportRequest,
  Invoice,
} from "./types";

const user: User = {
  token: "xxxtokenxxx",
};

const userPatient: UserProfile = {
  id: "6b5f9c03-f5c5-472c-845e-f8e01cc99dbe",
  username: "tomred",
  email: "mockuser@example.com",
  name: "Tommaso",
  surname: "Rossi",
  fiscalCode: "RSSTMMS96AXXXXS",
  phoneNumber: "+393334445555",
  birthDate: "1969/12/09",
  birthPlace: "Padova",
  residentialAddress: "Via Fermi, 18, 20090 Milano MI",
  healthInsuranceCard: "1234567789012344",
  city: "Milano",
  province: "MI",
  country: "Italy",
  creationDate: "2020-06-16T13:59:05.11",
  zipCode: "20090",
};

const allPredefinedOptions: AllPredefinedOptions = {
  buildingDetail: [],
  buildingType: [],
  patientDisease: [],
  patientImmobilizationAid: [],
  patientMobility: [],
  patientSpecialNeed: [],
  quotationCancelReason: [
    {
      id: 1,
      value: "TROPPO_COSTOSO",
    },
    {
      id: 2,
      value: "NON_PIU_RICHIESTO",
    },
  ],
  quotationStatus: [
    {
      id: 1,
      value: "INSERITO",
    },
    {
      id: 2,
      value: "ACCETTATO",
    },
    {
      id: 4,
      value: "RIFIUTATO",
    },
    {
      id: 8,
      value: "ANNULLATO",
    },
  ],
  tripExamination: [
    {
      id: 1,
      value: "ESAME_SANGUE",
    },
    {
      id: 2,
      value: "VACCINAZIONE",
    },
    {
      id: 4,
      value: "VISITA_OCULISTICA",
    },
    {
      id: 8,
      value: "VISITA_ORTOPEDICA",
    },
    {
      id: 16,
      value: "VISITA_GINECOLOGICA",
    },
    {
      id: 32,
      value: "VISITA_CARDIOLOGICA",
    },
  ],
  tripReason: [
    {
      id: 1,
      value: "RICOVERO",
    },
    {
      id: 2,
      value: "DIMISSIONE",
    },
    {
      id: 4,
      value: "VISITA_SPECIALISTICA",
    },
    {
      id: 8,
      value: "VISITA_CON_ESAMI",
    },
    {
      id: 16,
      value: "TRASFERIMENTO",
    },
    {
      id: 32,
      value: "TRASFERIMENTO_ASSISTITO",
    },
  ],
};

const patients: Patient[] = [
  {
    id: "a8ef9e4d-a4dc-4aed-b48c-990768db0198",
    name: "Sandro",
    surname: "Mandro",
    fiscalCode: "AOKEOTMMS96AXXXXS",
    birthDate: "1974/11/19",
    birthPlace: "Padova",
    residentialAddress: "Via Giusti, 19, 10444 Padova PD",
    healthInsuranceCard: "456778901234466743",
    city: "Padova",
    country: "Italia",
    province: "Padova",
    zipCode: "35100",
    creationDate: new Date().toISOString(),
    mobility: undefined,
    diseases: [],
    immobilizationAids: [],
    specialNeeds: [],
    weight: PatientWeightRange.RANGE_50_80,
    relationship: PatientRelationship.PATIENT,
  },
  {
    id: "8aea164b-e668-4c1d-8dbf-c50b9ce0b27a",
    name: "Lampa",
    surname: "Dina",
    fiscalCode: "IDMKEKMDKMK",
    birthDate: "1989/02/28",
    birthPlace: "Sampdoria",
    residentialAddress: "Via Marconi, 88, 14233 Valno VN",
    healthInsuranceCard: "6778901234413224",
    city: "Padova",
    country: "Italia",
    province: "Padova",
    zipCode: "35100",
    creationDate: new Date().toISOString(),
    mobility: undefined,
    diseases: [],
    immobilizationAids: [],
    specialNeeds: [],
    weight: PatientWeightRange.MORE_110,
    relationship: PatientRelationship.RELATIVE,
  },
];

const places: Place[] = [
  {
    id: "b4cd9633-59f3-476c-a803-611d4c895bd3",
    type: PlaceType.HOUSE,
    name: "Casa",
    floor: "Secondo piano",
    address: homePlace.address,
    city: homePlace.city,
    country: homePlace.country,
    province: homePlace.province,
    zipCode: homePlace.zipCode,
    creationDate: new Date().toISOString(),
    details: [],
  },
  {
    id: "386c98fc-9f3f-48b7-91fb-4a335361ac43",
    type: PlaceType.HEALTH_FACILITY,
    floor: "Piano terra",
    creationDate: new Date().toISOString(),
    address: hospitalPlace.address,
    city: hospitalPlace.city,
    province: hospitalPlace.province,
    country: hospitalPlace.country,
    zipCode: hospitalPlace.zipCode,
    hospitalBuilding: "Monoblocco padova",
    name: "San Antonio",
    hospitalWard: "Some hospital ward",
    details: [],
  },
];

const paymentMethods: PaymentMethod[] = [
  {
    id: "70f1b7e8-fe63-4d39-bef2-6b7474b66deb",
    provider: PaymentMethodProvider.MASTERCARD,
    number: "1234123400001234",
    code: "234",
    holder: "Mario Rossi",
    expiryMonth: "10",
    expiryYear: "2021",
  },
  {
    id: "fea99423-6e00-46b2-911d-e0e23a3e6fa6",
    provider: PaymentMethodProvider.VISA,
    number: "5678567800005678",
    code: "147",
    holder: "Luigi Verdi",
    expiryMonth: "2",
    expiryYear: "2023",
  },
];

const transportRequests: BookedTransportRequest[] = [
  {
    id: "1",
    number: "1234",
    patient: {
      ...patients[0],
    },
    trip: {
      departureTime: new Date().toISOString(),
      examinations: [
        allPredefinedOptions.tripExamination[0].value,
        allPredefinedOptions.tripExamination[1].value,
      ],
      reason: allPredefinedOptions.tripReason[0].value,
      notes: "some testing notes on the trip",
    },
    departurePlace: {
      ...places[0],
    },
    arrivalPlace: {
      ...places[1],
    },
    quotation: {
      cost: 200,
      estimateUrl: "https://example.com/quotation/request/121",
      waitingTime: 30 * 60,
      examinationsTime: 20 * 60,
      travelTime: 30 * 60,
      travelDistance: 5.6,
      status: allPredefinedOptions.quotationStatus[0],
    },
    cancelReasons: [],
    creationDate: new Date(2021, 2).toISOString(),
  },
];

const invoices: Invoice[] = [
  {
    orderId: "1",
    date: new Date(2020, 6, 11, 20, 10),
    value: 200,
  },
  {
    orderId: "2",
    date: new Date(2020, 6, 11, 20, 10),
    value: 200,
  },
  {
    orderId: "3",
    date: new Date(2020, 6, 11, 20, 10),
    value: 200,
  },
];

export default {
  user,
  userPatient,
  places,
  patients,
  paymentMethods,
  transportRequests,
  allPredefinedOptions,
  invoices,
};
