import React from "react";
import { Field } from "react-final-form";
import Form from "react-bootstrap/Form";

import { ErrorLabel } from "./ErrorLabel";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  /** <input> type. Defaults to 'text' */
  type?: "text" | "email" | "password";
  /**
   * Autocomplete hints for password managers.
   * If used for password requires a hidden input with autocomplete="username"
   * to be effective.
   *
   * https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
   */
  autocomplete?: "current-password" | "new-password" | "username";
};

export const TextInput: React.FC<Props> = ({
  name,
  label,
  placeholder,
  type,
  autocomplete,
}) => (
  <Field
    name={name}
    render={({ input }) => {
      return (
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Control
            type={type ?? "text"}
            placeholder={placeholder}
            autoComplete={autocomplete}
            {...input}
          />
          <ErrorLabel name={name} />
        </Form.Group>
      );
    }}
  />
);
