import React, { useState, useEffect } from "react";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { observer } from "mobx-react";

import moment from "moment";
import "moment/locale/it";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import "./services/i18n";

import history from "./history";
import { Stores } from "./types";
import { StoresContext } from "./contexts";
import env from "./config/env";

import { SessionStore } from "./stores/SessionStore";
import { TransportsStore } from "./stores/TransportsStore";

import { TransportRequestIntro } from "./pages/TransportRequestWizard/TransportRequestIntro";
import { Home } from "./pages/Home";
import { Header } from "./pages/Header";
import { LoginScreen } from "./pages/LoginScreen";
import { UserReservedArea } from "./pages/UserReservedArea";
import { RegistrationScreen } from "./pages/RegistrationScreen";
import { Footer } from "./components/Footer";
import { FullscreenLoading } from "./components/FullscreenLoading";

/**
 * PAYMENT METHODS
 */
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { initialPaypalOptions } from "./config/config";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "./services/stripe";
import { CallOperatorButton } from "./components/CallOperatorButton";

// TODO: change moment locale to current lang
moment.locale("it");

const App: React.FC = observer(() => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [stores] = useState<Stores>(() => {
    const root: Stores = {} as Stores;

    root.session = new SessionStore(root);
    root.transports = new TransportsStore(root);

    return root;
  });

  useEffect(() => {
    stores.session
      .init()
      .catch((err) => console.error("Init failed", err))
      .then(() => setInitialLoading(false));
  }, [stores]);

  useEffect(() => {
    if (env.DEBUG_ROUTER) {
      // https://stackoverflow.com/questions/34093913/how-to-debug-react-router
      console.log("initial history is: ", JSON.stringify(history, null, 2));

      history.listen((location, action) => {
        console.log(
          `The current URL is ${location.pathname}${location.search}${location.hash}`
        );

        console.log(
          `The last navigation action was ${action}`,

          JSON.stringify(history, null, 2)
        );
      });
    }
  }, []);

  return (
    // deferLoading={true}
    <Elements stripe={stripePromise}>
      <StoresContext.Provider value={stores}>
        <PayPalScriptProvider options={initialPaypalOptions}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <>
                <Header />
                {initialLoading && (
                  <FullscreenLoading message="Caricamento iniziale..." />
                )}
                {!initialLoading && (
                  <Switch>
                    <Route
                      path="/transport"
                      render={(props) => <TransportRequestIntro {...props} />}
                    />
                    <Route path="/login">
                      <LoginScreen />
                    </Route>
                    <Route path="/registration">
                      <RegistrationScreen />
                    </Route>
                    <Route path="/reserved-area">
                      <UserReservedArea />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                )}
                {env.DEV_INITIAL_ROUTE && (
                  <Redirect to={env.DEV_INITIAL_ROUTE} />
                )}
                <CallOperatorButton />
                <Footer />
              </>
            </Router>
          </MuiPickersUtilsProvider>
        </PayPalScriptProvider>
      </StoresContext.Provider>
    </Elements>
  );
});

export default App;
