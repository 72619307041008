import React from "react";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

export const StageStatusSection: React.FC = ({ children }) => (
  <div className="stage-status--section">{children}</div>
);

export const StageStatusSectionSeparator: React.FC = () => (
  <div className="stage-status--section-separator">
    <Icon path={mdiChevronRight} size={1.2} />
  </div>
);

type StageStatusHeaderProps = {
  /** Title is used as text; on small screens just as icon title */
  title: string;
  titleValue?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const StageStatusHeader: React.FC<StageStatusHeaderProps> = ({
  title,
  titleValue,
  icon,
  active,
  disabled,
  onClick,
}) => {
  let modifier = "";
  if (active && !disabled) {
    modifier += "active-stage";
  } else if (disabled) {
    modifier += "disabled";
  }

  return (
    <div
      className={`stage-status--header ${modifier}`}
      onClick={!disabled ? onClick : undefined}
    >
      {icon && <Icon path={icon} title={title} size={1.5} />}
      <h3>
        {title}
        {titleValue != null && <strong>: {titleValue}</strong>}
      </h3>
    </div>
  );
};

export type StageStatusValues = Array<{ label: string; value?: string }>;

export const StageStatusList: React.FC<{ values: StageStatusValues }> = ({
  values,
}) => {
  return (
    <table className="stage-status--list">
      <tbody>
        {values.map(
          ({ label, value }, index) =>
            value != null && (
              <tr className="stage-status--item" key={index}>
                <td className="stage-status--item-label">{label}:</td>
                <td className="stage-status--item-value">{value}</td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};
