import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormRenderProps } from "react-final-form";
import { mdiAccount, mdiAccountPlus } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import {
  FormErrors,
  ValidateForm,
  TransportRequestDraftValues,
  Patient,
  PatientRelationship,
} from "../../types";
import { useStores } from "../../hooks/useStores";

import {
  TabbedRadioInput,
  TabbedRadioInputContainer,
  TabbedRadioInputSeparator,
} from "../../components/TabbedRadioInput";
import { ErrorLabel } from "../../components/ErrorLabel";
import { LoadingMessage } from "../../components/LoadingMessage";

export const AutofillPatientStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = observer((props) => {
  const { values, form } = props;
  const { session } = useStores();
  const { t } = useTranslation();

  const hasMeAsPatient = values.patient?.relationship === "me";
  const hasOtherAsPatient =
    values.patient?.relationship && values.patient?.relationship !== "me";

  useEffect(() => {
    session.fetchPatients();
  }, []);

  const autofillPatient = (patient: Partial<Patient>) => {
    form.reset({
      ...values,
      patient: {
        ...patient,
        consentGdpr: true,
      },
    });
  };

  const resetPatient = () => {
    form.reset({
      ...values,
      patient: {},
    });
  };

  const chooseMeAsPatient = () => {
    if (session.user) {
      // Consent to use the user as patient, but will require the
      // healthInsuranceCard number on the next stage, if not present

      const userPatientData = session.otherPatients.find(
        (patient) => patient.fiscalCode === session.user?.fiscalCode
      );

      if (userPatientData) {
        autofillPatient({
          ...userPatientData,
          relationship: PatientRelationship.ME,
        });
      } else {
        const userData = { ...session.user };
        // @ts-ignore
        delete userData["id"];

        autofillPatient({
          ...userData,
          relationship: PatientRelationship.ME,
        });
      }
    }
  };

  const chooseOtherAsPatient = () => {
    if (hasMeAsPatient) {
      resetPatient();
    }
  };

  const chooseOtherPatient = (patient: Patient) => {
    autofillPatient(patient);
  };

  const chooseNewOtherPatient = () => {
    resetPatient();
    // @ts-ignore dot path not supported
    form.change("patient.relationship", PatientRelationship.RELATIVE);
    // @ts-ignore dot path not supported
    form.change("patient.consentGdpr", false);
  };

  if (session.isLoadingPatients) {
    return <LoadingMessage description={t("common.loading")} />;
  }

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">{t("transport.stages.patient")}</h4>
      <p>{t("transport.autofillPatientStageDescription")}</p>
      <div className="form-container-sm">
        <TabbedRadioInputContainer>
          <TabbedRadioInput
            name="patient.relationship"
            label={t("transport.forMe")}
            value={PatientRelationship.ME}
            icon={mdiAccount}
            iconSize={2}
            onClick={chooseMeAsPatient}
          />
          <TabbedRadioInputSeparator />
          <TabbedRadioInput
            name="patient.relationship"
            label={t("transport.forAnotherPerson")}
            icon={mdiAccountPlus}
            iconSize={2}
            checked={hasOtherAsPatient}
            value={PatientRelationship.RELATIVE}
            onClick={chooseOtherAsPatient}
          />
        </TabbedRadioInputContainer>
        <ErrorLabel name="relationship" />
        {hasMeAsPatient && (
          <p
            style={{ margin: "0 auto", marginTop: "2em", textAlign: "center" }}
          >
            {t("transport.autofillFromProfile")}
          </p>
        )}
        {hasOtherAsPatient && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "2em",
            }}
          >
            <ButtonGroup toggle className="vertical-btt-group">
              {session.otherPatients.map((patient) => (
                <ToggleButton
                  key={patient.fiscalCode}
                  type="checkbox"
                  checked={patient.fiscalCode === values.patient?.fiscalCode}
                  value={patient.id}
                  onChange={() => chooseOtherPatient(patient)}
                >
                  {patient.name} {patient.surname} - {patient.fiscalCode}
                </ToggleButton>
              ))}
              <ToggleButton
                type="checkbox"
                value="new-patient"
                checked={values.patient?.id == null}
                onChange={chooseNewOtherPatient}
              >
                {t("transport.newPatient")}
              </ToggleButton>
            </ButtonGroup>
          </div>
        )}
      </div>
    </div>
  );
});

export const validateAutofillPatientStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  const patientErrors: FormErrors<TransportRequestDraftValues["patient"]> = {};

  if (!values.patient?.relationship) {
    patientErrors.relationship = "Scegli il paziente";
  }

  return {
    patient: patientErrors,
  };
};
