import React from "react";
import { Field } from "react-final-form";
import Form from "react-bootstrap/Form";

type Props = {
  name: string;
  label?: string;
  description?: string;
  options: Array<{ key: string; label: string }>;
};

export const SelectInput: React.FC<Props> = ({
  name,
  label,
  description,
  options,
}) => (
  <Field
    name={name}
    render={({ input }) => (
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        {description && <span>{description}</span>}
        <Form.Control as="select" {...input}>
          <option value="" />
          {options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    )}
  />
);
