import jsPDF from "jspdf";
import i18n from "../../services/i18n";
import "jspdf-autotable";
import { TransportRequestDraft } from "../../models/TransportRequestDraft";
import { poValueToLabel } from "../../utils/predefinedOptions";
import { formatDate, formatDateTime } from "../../utils/date";
import { InvoiceHolderType, UserProfile } from "../../types";
// Date Fns is used to format the dates we receive
// from our API call

// define a generatePDF function that accepts a tickets argument
const generateQuotation = (
  trasporRequesttDraft: TransportRequestDraft,
  user: UserProfile | undefined,
  holder: InvoiceHolderType,
  convention: string | undefined
) => {
  const trasportDraft = trasporRequesttDraft.values;
  const quotationRequest = trasporRequesttDraft.quotationRequest;

  console.log(trasporRequesttDraft);
  console.log(trasportDraft);
  console.log(holder);
  console.log(quotationRequest);

  // initialize jsPDF
  fetch("/invoice_inline.html").then(async (c) => {
    const t = (key: string) => i18n.t(key);
    let html = await c.text();
    const doc = new jsPDF({
      format: "a4",
      orientation: "portrait",
      compress: true,
    });

    const patientFullname = `${trasportDraft?.patient?.name ?? ""} ${
      trasportDraft?.patient?.surname ?? ""
    }`.trim();

    const bd = new Date(trasportDraft.patient?.birthDate!);
    const bdStr = bd.getDate() + " " + bd.getMonth() + " " + bd.getFullYear();

    const cd = new Date(quotationRequest?.creationDate!);
    const cdStr = cd.getDate() + " " + cd.getMonth() + " " + cd.getFullYear();

    let payee = "";
    let payeeL1 = "";
    let payeeL2 = "";
    let payeeL3 = "";
    let payeeL4 = "";
    if (holder === InvoiceHolderType.APPLICANT) {
      if (user?.vatNumber) {
        // company expected
        payee =
          user?.companyName ??
          `${trasportDraft?.patient?.name ?? ""} ${
            trasportDraft?.patient?.surname ?? ""
          }`.trim() ??
          "";
        payeeL1 = user?.residentialAddress ?? "";
        payeeL2 = user?.vatNumber ?? "";
        payeeL3 = user?.fiscalCode ?? "";
      } else {
        // otherwise private
        payee = user?.name ?? "";
        payeeL1 = user?.residentialAddress ?? "";
        payeeL2 = user?.fiscalCode ?? "";
        payeeL3 = user?.email ?? "";
      }

      let contacts: string[] = [];
      if (user?.email) {
        contacts.push(user?.email);
      }
      if (user?.phoneNumber) {
        contacts.push(user?.phoneNumber);
      }
      if (user?.mobilePhone) {
        contacts.push(user?.mobilePhone);
      }

      payeeL4 = contacts.join(", ");
    } else if (holder === InvoiceHolderType.CONVENTIONS) {
      payee = `${trasportDraft?.patient?.name ?? ""} ${
        trasportDraft?.patient?.surname ?? ""
      }`.trim();
      payeeL1 = trasportDraft?.patient?.residentialAddress ?? "";
      payeeL2 = trasportDraft?.patient?.fiscalCode ?? "";
      payeeL3 = "NUMERO CONVENZIONE";
      payeeL4 = convention ?? "";
    } else if (holder === InvoiceHolderType.PATIENT) {
      payee = patientFullname;
      payeeL1 = trasportDraft?.patient?.residentialAddress ?? "";
      payeeL2 = trasportDraft?.patient?.fiscalCode ?? "";

      let contacts: string[] = [];
      if (trasportDraft?.patient?.phoneNumber) {
        contacts.push(trasportDraft?.patient?.phoneNumber);
      }
      if (trasportDraft?.patient?.mobilePhone) {
        contacts.push(trasportDraft?.patient?.mobilePhone);
      }

      payeeL3 = contacts.join(", ");
    }

    const roundTrip = trasportDraft.trip.roundTrip
      ? t("trip.roundTripYes")
      : t("trip.roundTripNo");

    html = html
      .replace("%NUMBER%", quotationRequest?.number ?? "")
      .replace("%NUMBER%", quotationRequest?.number ?? "")
      .replace("%IMAGE%", "/hagile_logo_reduced.png")
      .replace("%DATE%", formatDate(quotationRequest?.creationDate!))
      .replace("%PAYEE%", payee)
      .replace("%PAYEE_LINE_1%", payeeL1)
      .replace("%PAYEE_LINE_2%", payeeL2)
      .replace("%PAYEE_LINE_3%", payeeL3)
      .replace("%PAYEE_LINE_4%", payeeL4)
      .replace("%CREATED_BY%", user?.id + "")
      .replace(
        "%START_DATE%",
        formatDateTime(trasportDraft?.trip?.departureTime ?? "")
      )
      .replace("%ROUND_TRIP%", roundTrip)
      .replace(
        "%TYPE%",
        t(`${poValueToLabel(trasportDraft?.trip?.reason ?? "")}`)
      )
      .replace("%PATIENT_FULLNAME%", patientFullname)
      .replace("%BIRTH_DATE%", formatDate(trasportDraft.patient?.birthDate!))
      .replace("%BORN_AT%", trasportDraft.patient?.birthPlace ?? "")
      .replace(
        "%HOME_ADDRESS%",
        trasportDraft.patient?.residentialAddress ?? ""
      )
      .replace("%TAX_CODE%", trasportDraft.patient?.fiscalCode ?? "")
      .replace("%FROM_ADDRESS%", trasportDraft.departurePlace?.address ?? "")
      .replace(
        "%FROM_TYPE%",
        t(`place.${trasportDraft.departurePlace?.type}`) ?? ""
      )
      .replace("%FROM_FLOOR%", trasportDraft.departurePlace?.floor ?? "")
      .replace(
        "%FROM_ADDITIONAL_INFO%",
        trasportDraft.departurePlace?.notes ?? ""
      )
      .replace(
        "%FROM_DETAILS%",
        (trasportDraft.departurePlace?.details ?? [])
          .map((d) => poValueToLabel(d))
          .join(",")
      )
      .replace("%TO_ADDRESS%", trasportDraft.arrivalPlace?.address ?? "")
      .replace(
        "%TO_TYPE%",
        t(`place.${trasportDraft.arrivalPlace?.type}`) ?? ""
      )
      .replace("%TO_FLOOR%", trasportDraft.arrivalPlace?.floor ?? "")
      .replace("%TO_ADDITIONAL_INFO%", trasportDraft.arrivalPlace?.notes ?? "")
      .replace(
        "%TO_DETAILS%",
        (trasportDraft.arrivalPlace?.details ?? [])
          .map((d) => poValueToLabel(d))
          .join(",")
      )
      .replace("%PRICE%", (quotationRequest?.quotation?.cost ?? 0).toString())
      .replace("%TOTAL%", (quotationRequest?.quotation?.cost ?? 0).toString());

    await doc
      .html(html, {
        margin: [10, 10, 10, 10],
        autoPaging: "text",
        x: 0,
        y: 0,
        width: 190,
        windowWidth: 675,
      })
      .save(`Preventivo_${quotationRequest?.number ?? ""}.pdf`);
  });
};

export default generateQuotation;
