import { observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { useStores } from "../hooks/useStores";
import CallCenterAgent from "../assets/images/call-center-agent.png";
import api from "../services/api";

export const CallOperatorButton: React.FC = observer(() => {
  const { session } = useStores();

  const [callURL, setCallURL] = React.useState<string | null>(null);

  useEffect(() => {
    api
      .getCallURLClick2call()
      .then((res) => {
        setCallURL(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [session.user]);

  const handleClick = useCallback(() => {
    if (callURL) {
      window.open(callURL);
    } else {
      alert("Servizio non disponibile");
    }
  }, [callURL]);

  return (
    <div className="call-operator-btt" onClick={handleClick}>
      <img src={CallCenterAgent} alt={"Call Center"} />
    </div>
  );
});
