import React from "react";
import { useTranslation } from "react-i18next";

import { TextArea } from "../../components/TextArea";

export const NotesStageForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">{t("transport.stages.notes")}</h4>
      <div className="form-container-sm">
        <TextArea label={t("transport.additionalRequests")} name="trip.notes" />
      </div>
    </div>
  );
};
