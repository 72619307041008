import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { mdiPlus, mdiSync } from "@mdi/js";

import { TransportRequest } from "../models/TransportRequest";

import {
  TabbedRadioInputContainer,
  TabbedRadioInput,
  TabbedRadioInputSeparator,
} from "./TabbedRadioInput";
import { TransportList } from "./TransportList";
import { LoadingMessage } from "./LoadingMessage";

type Props = {
  show?: boolean;
  requests: TransportRequest[];
  isLoadingRequests: boolean;
  onCancel: () => void;
  onReplicate: (request: TransportRequest) => void;
  onNew: () => void;
};

export const NewRequestModal: React.FC<Props> = ({
  show,
  requests,
  isLoadingRequests,
  onCancel,
  onReplicate,
  onNew,
}) => {
  const { t } = useTranslation();

  const [stage, setStage] = useState<"initial" | "replicate">("initial");

  return (
    <Modal size="lg" show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{t("transport.transportRequest")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="center-vertically">
        {stage === "initial" && (
          <>
            <p>{t("common.chooseAnOption")}:</p>
            <Form
              onSubmit={() => {}}
              render={() => (
                <TabbedRadioInputContainer>
                  <TabbedRadioInput
                    name="choice"
                    label={t("transport.newTransportRequest")}
                    icon={mdiPlus}
                    iconSize={2}
                    value={0}
                    onClick={onNew}
                  />
                  <TabbedRadioInputSeparator />
                  <TabbedRadioInput
                    name="choice"
                    label={t("bookings.similarToBookedRequest")}
                    icon={mdiSync}
                    iconSize={2}
                    value={1}
                    onClick={() => setStage("replicate")}
                  />
                </TabbedRadioInputContainer>
              )}
            />
          </>
        )}
        {stage === "replicate" && (
          <>
            <p>{t("bookings.chooseBooking")}:</p>
            {isLoadingRequests ? (
              <LoadingMessage />
            ) : (
              <TransportList requests={requests} onChoose={onReplicate} />
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
