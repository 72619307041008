import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../assets/images/hagile_exe_01b.png";
import Icon from "@mdi/react";
import {
  mdiTranslate,
  mdiInstagram,
  mdiFacebook,
  mdiYoutube,
  mdiTwitter,
} from "@mdi/js";

export const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className={"footer--container"}>
      <Container style={{ paddingTop: "2.5rem", paddingBottom: "1rem" }}>
        <Row>
          <Col xs={12} md={8} style={{ paddingBottom: "1rem" }}>
            <img
              src={Logo}
              alt={"Hagile logo"}
              style={{ width: "68px", height: "auto" }}
            />
            <br />
            <br />
            <Button
              variant="outline-dark"
              style={{ padding: 0 }}
              onClick={() => console.log("TODO change language")}
            >
              <Icon path={mdiTranslate} size={1} /> Lingua
            </Button>
          </Col>
          <Col xs={6} md={2}>
            <ul className="list-unstyled">
              <li>
                <Link to={"/"}>{t("footer.whoWeAre")}</Link>
              </li>
              <li>
                <Link to={"/"}>{t("footer.howItWors")}</Link>
              </li>
              <li>
                <Link to={"/"}>{t("footer.faq")}</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={2}>
            <ul>
              <li>
                <Link to={"/"}>{t("footer.insurance")}</Link>
              </li>
              <li>
                <Link to={"/"}>{t("footer.jobOffers")}</Link>
              </li>
              <li>
                <Link to={"/"}>{t("footer.contactUs")}</Link>
              </li>
              <li>
                <Link to={"/"}>{t("footer.press")}</Link>
              </li>
            </ul>
          </Col>
        </Row>
        <Row style={{ paddingTop: "2rem", paddingBottom: "2.5rem" }}>
          <Col>
            <div className="space-between">
              <Link to={"/"}>Hagile</Link>
              <Link to={"/"}>{t("footer.termsOfUse")}</Link>
              <div>
                <Link to={"/"}>
                  <Icon path={mdiInstagram} size={1} />
                </Link>
                &nbsp;
                <Link to={"/"}>
                  <Icon path={mdiFacebook} size={1} />
                </Link>
                &nbsp;
                <Link to={"/"}>
                  <Icon path={mdiTwitter} size={1} />
                </Link>
                &nbsp;
                <Link to={"/"}>
                  <Icon path={mdiYoutube} size={1} />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
