import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import env from "../../config/env";
import { useStores } from "../../hooks/useStores";

import { TransportRequestNew } from "./TransportRequestNew";
import { TransportRequestStatus } from "./TransportRequestStatus";
import { TransportRequestWizard, lastStage } from "./TransportRequestWizard";
import { ConfirmationStage } from "./ConfirmationStage";
import { CompletedStage } from "./CompletedStage";
import { toJS } from "mobx";
const initialStage = env.DEV_INITIAL_STAGE >= 0 ? env.DEV_INITIAL_STAGE : 0;

export const TransportRequestIntro: React.FC<any> = observer((...props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { session, transports } = useStores();
  const [stage, setStage] = useState(
    props[0].location &&
      props[0].location.state &&
      props[0].location.state.stage
      ? props[0].location.state.stage
      : initialStage
  );

  useEffect(() => {
    // Clear the transport request draft when leaving the wizard
    // Don't clear when not logged, so we can go to login and then recover
    // the current transport draft
    return () => {
      if (session.isLogged) {
        transports.clearRequestDraft();
      }
    };
  }, []);

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [stage]);

  // useEffect(() => {
  //   // Reset wizard to first stage when a new request draft has been set
  //   setStage(initialStage);
  // }, [transports.requestDraft]);

  const onCompletedStage = () => {
    const nextStage = stage + 1;
    if (nextStage > lastStage) {
      history.push("/transport/confirm");
    } else {
      setStage(nextStage);
    }
  };

  const setCurrentStage = (newStage: number) => {
    setStage(Math.max(Math.min(newStage, stage), 0));
  };

  const goToPrevStage = () => {
    setCurrentStage(stage - 1);
  };

  return (
    <Container className="main--container">
      <Row style={{ flex: 1 }}>
        <Col md="4" className="main--sidebar">
          <h2 style={{ marginBottom: "1.5rem" }}>
            {t("transport.transportRequest")}
          </h2>
          <TransportRequestStatus
            currentStage={stage}
            onChangeStage={setCurrentStage}
          />
        </Col>
        <Col md="8">
          <Switch>
            <Route path={`/transport/completed`}>
              <CompletedStage />
            </Route>
            <Route path={`/transport/confirm`}>
              <ConfirmationStage />
            </Route>
            <Route path={`/transport/wizard`}>
              <TransportRequestWizard
                stage={stage}
                onCompletedStage={onCompletedStage}
                onPrevStage={goToPrevStage}
              />
            </Route>
            <Route path="/transport/new">
              <TransportRequestNew />
            </Route>
            <Route path="/transport">
              <Redirect to="/transport/new" />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
});
