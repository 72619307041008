import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { ValidateForm } from "../types";
import { TransportRequest } from "../models/TransportRequest";

import { DateTimeInput } from "./DateTimeInput";
import { WizardPaginationControls } from "./WizardPaginationControls";

type ReplicateFormValues = {
  requestId: string;
  departureTime: string;
};

type Props = {
  requestToReplicate?: TransportRequest;
  onCancel: () => void;
  onReplicate: (values: ReplicateFormValues) => void;
};

export const ReplicateRequestModal: React.FC<Props> = ({
  requestToReplicate,
  onCancel,
  onReplicate,
}) => {
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      requestId: requestToReplicate?.id,
      departureTime: new Date().toISOString(),
    }),
    [requestToReplicate]
  );

  return (
    <Modal show={!!requestToReplicate} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("bookings.replicateTransportRequest")}
          <br />
          {requestToReplicate?.id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="center-vertically">
        <p>{t("bookings.replicateTransportRequestDescr")}</p>
        <Form<ReplicateFormValues>
          initialValues={initialValues}
          validate={validateReplicateForm}
          onSubmit={onReplicate}
          render={(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <DateTimeInput
                label={t("trip.departureDate")}
                name="departureTime"
              />
              <WizardPaginationControls
                goNext={formProps.handleSubmit}
                goNextLabel={t("common.replicate")}
              />
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

const validateReplicateForm: ValidateForm<ReplicateFormValues> = (values) => {
  if (
    !values.departureTime ||
    new Date(values.departureTime).getTime() < Date.now()
  ) {
    return {
      departureTime: "Data ed orario devono essere futuri",
    };
  }

  return {};
};
