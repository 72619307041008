import React, { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Redirect, Link, Switch, Route, useLocation } from "react-router-dom";
import { Container, Row, Button, Col, Modal } from "react-bootstrap";
import Icon from "@mdi/react";
import {
  mdiAmbulance,
  mdiAccount,
  mdiAccountGroup,
  mdiOfficeBuilding,
  mdiWallet,
  mdiCreditCardMultiple,
} from "@mdi/js";

import { useStores } from "../hooks/useStores";

import { UserProfilePage } from "./UserProfile";
import { UserPatients } from "./UserPatients";
import { UserPlaces } from "./UserPlaces";
import { UserInvoices } from "./UserInvoices";
import { UserPaymentMethods } from "./UserPaymentMethods";
import { UserBookings } from "./UserBookings";
import { UserBookingDetails } from "./UserBookingDetails";
import { StageStatusHeader } from "../components/StageStatus";
import { NewRequestModal } from "../components/NewRequestModal";
import history from "../history";
import { ReplicateRequestModal } from "../components/ReplicateRequestModal";
import { TransportRequest } from "../models/TransportRequest";

const useMenu = (): Array<{
  name: string;
  title: string;
  icon: string;
  component: React.FC;
}> => {
  const { t } = useTranslation();

  return [
    {
      name: "profile",
      title: t("reservedArea.profile"),
      icon: mdiAccount,
      component: UserProfilePage,
    },
    {
      name: "bookings",
      title: t("reservedArea.bookings"),
      icon: mdiAmbulance,
      component: UserBookings,
    },
    {
      name: "invoices",
      title: t("reservedArea.invoices"),
      icon: mdiWallet,
      component: UserInvoices,
    },
    {
      name: "payment-methods",
      title: t("reservedArea.paymentMethods"),
      icon: mdiCreditCardMultiple,
      component: UserPaymentMethods,
    },
    {
      name: "patients",
      title: t("reservedArea.patients"),
      icon: mdiAccountGroup,
      component: UserPatients,
    },
    {
      name: "places",
      title: t("reservedArea.addresses"),
      icon: mdiOfficeBuilding,
      component: UserPlaces,
    },
  ];
};

export const UserReservedArea: React.FC = observer(() => {
  const { session, transports } = useStores();
  const location = useLocation();
  const { t } = useTranslation();

  const [newRequestShow, setNewRequestShow] = useState(false);
  const [requestToReplicate, setRequestToReplicate] =
    useState<TransportRequest>();

  const showNewRequestModal = () => {
    transports.fetchTransportRequests();
    setNewRequestShow(true);
  };

  const menu = useMenu();
  const isHome = location.pathname === "/reserved-area";
  const isMenuEntryActive = (name: string) =>
    location.pathname.search(name) !== -1;

  if (!session.isLogged) {
    return <Redirect to="/" />;
  }

  return (
    <Container className="main--container">
      <Row>
        <Col hidden={isHome} md={4}>
          <h2>{t("reservedArea.personalArea")}</h2>
          <div style={{ margin: "1rem 0 2rem" }}>
            <Button
              type="button"
              variant="primary"
              onClick={showNewRequestModal}
            >
              {t("reservedArea.newTransportRequest")}{" "}
              <Icon
                size={1}
                path={mdiAmbulance}
                title={t("reservedArea.newTransportRequest")}
              />
            </Button>
          </div>
          <div className="stage-status--container">
            {menu.map((entry) => (
              <Link
                to={`/reserved-area/${entry.name}`}
                key={entry.name}
                className="secondary-link"
                style={{ display: "block", marginBottom: "1.2rem" }}
              >
                <StageStatusHeader
                  title={entry.title}
                  icon={entry.icon}
                  active={isMenuEntryActive(entry.name)}
                />
              </Link>
            ))}
          </div>
        </Col>
        <Col md={isHome ? 12 : 8}>
          <Switch>
            <Route path="/reserved-area/bookings/:bookingId">
              <UserBookingDetails />
            </Route>
            {menu.map((entry) => (
              <Route path={`/reserved-area/${entry.name}`} key={entry.name}>
                <entry.component />
              </Route>
            ))}
            <Route path="/reserved-area/">
              <ClientReservedAreaHome onNewRequest={showNewRequestModal} />
            </Route>
          </Switch>
        </Col>
      </Row>

      <NewRequestModal
        // use key to reset the modal once we hide it, to be re-usable
        key={`NewRequestModal-${newRequestShow}`}
        show={newRequestShow}
        requests={transports.requests}
        isLoadingRequests={transports.isLoadingRequests}
        onCancel={() => setNewRequestShow(false)}
        onReplicate={(request) => {
          setRequestToReplicate(request);
          setNewRequestShow(false);
        }}
        onNew={() => history.push("/transport")}
      />

      <ReplicateRequestModal
        requestToReplicate={requestToReplicate}
        onCancel={() => setRequestToReplicate(undefined)}
        onReplicate={(values) => {
          history.push(
            `/transport/new?replicateFromId=${values.requestId}&replicateDepartureTime=${values.departureTime}`
          );
        }}
      />
    </Container>
  );
});

type HomeProps = { onNewRequest: () => void };

const ClientReservedAreaHome: React.FC<HomeProps> = observer((props) => {
  const { onNewRequest } = props;
  const { session } = useStores();
  const { t } = useTranslation();

  const menu = useMenu();

  return (
    <Container>
      <Row className="space-between">
        <h3>
          {t("reservedArea.personalAreaOf")}{" "}
          <strong style={{ fontWeight: 600 }}>
            {`${session.user?.name} ${session.user?.surname}`}
          </strong>
        </h3>
        <Button
          type="button"
          variant="primary"
          style={{ fontSize: "1.3rem", padding: "0.8rem 1.6rem" }}
          onClick={onNewRequest}
        >
          {t("reservedArea.newTransportRequest")}&nbsp;&nbsp;
          <Icon
            path={mdiAmbulance}
            title={t("reservedArea.newTransportRequest")}
            size={1.3}
          />
        </Button>
      </Row>
      {/* <Row style={{paddingTop: 60}}>
        <h5>{t('reservedArea.reminderUpcomingBookings')}</h5>
      </Row> */}
      <Row style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        {menu.map((entry) => (
          <Col
            xs={12}
            md={4}
            key={entry.name}
            style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
          >
            <DashButton
              to={`/reserved-area/${entry.name}`}
              title={entry.title}
              icon={entry.icon}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
});

type DashProps = { to: string; title: string; icon: string };

const DashButton: React.FC<DashProps> = ({ to, title, icon }) => {
  return (
    <Link to={to}>
      <Button type={"button"} variant="outline-secondary" size="lg" block>
        <Icon size={3} path={icon} title={title} />
        <br />
        {title}
      </Button>
    </Link>
  );
};
