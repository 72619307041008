import { useMemo } from "react";
import { useLocation } from "react-router-dom";

/**
 * A custom hook that builds on useLocation to parse the query string for you.
 * @see https://reacttraining.com/react-router/web/example/query-parameters
 */
export const useQueryParams = () => {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location]);
};
