import moment from "moment";

/** Format date and time in YYYY/MM/DD HH:mm:ss */
export const formatDateTime = (date: number | string | Date): string => {
  return moment(date).format("YYYY/MM/DD HH:mm:ss");
};

/** Format date in YY/MM/DD */
export const formatDate = (date: number | string | Date): string => {
  return moment(date).format("YYYY/MM/DD");
};
