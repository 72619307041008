import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import {
  Container,
  Button,
  Table,
  Form as BSForm,
  Modal,
  Col,
  Alert,
} from "react-bootstrap";
import Icon from "@mdi/react";
import {
  mdiFloppy,
  mdiPlus,
  mdiClose,
  mdiFolderOpen,
  mdiTrashCan,
} from "@mdi/js";

import { useStores } from "../hooks/useStores";
import {
  ValidateForm,
  FormErrors,
  Patient,
  PatientWeightRange,
  PatientRelationship,
} from "../types";

import { TextInput } from "../components/TextInput";
import { RadioInput } from "../components/RadioInput";
import { DateInput } from "../components/DateInput";
import { LoadingMessage } from "../components/LoadingMessage";
import { ToggleInputList } from "../components/ToggleInputList";

export const UserPatients: React.FC = observer(() => {
  const [patientToEdit, setPatientToEdit] = useState<Patient>();
  const [patientToDelete, setPatientToDelete] = useState<Patient>();
  const [isAddingPatient, setIsAddingPatient] = useState(false);
  const [error, setError] = useState<string>();

  const { t } = useTranslation();
  const { session } = useStores();

  useEffect(() => {
    session.fetchPatients();
    session.fetchAllPredefinedOptions();
  }, []);

  const onEditPatient = async (newValues: Patient) => {
    try {
      await session.updatePatient(newValues);
      setPatientToEdit(undefined);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const onCancelEditPatient = () => {
    setPatientToEdit(undefined);
  };

  const onAddPatient = async (patient: Patient) => {
    try {
      await session.addPatient(patient);
      setIsAddingPatient(false);
    } catch (err: any) {
      setError(err.message);
    }
  };

  const onCancelAddPatient = () => {
    setIsAddingPatient(false);
  };

  const cancelDeletePatient = () => {
    setPatientToDelete(undefined);
  };

  const confirmDeletePatient = () => {
    if (patientToDelete) {
      session.deletePatient(patientToDelete.id);
      setPatientToDelete(undefined);
    }
  };

  const canEditPatients = !session.isLoading;

  return (
    <Container>
      <div className="main-title">
        <h4>{t("reservedArea.patients")}</h4>
        <Button
          variant="outline-secondary"
          onClick={() => setIsAddingPatient(true)}
        >
          <Icon size={1} path={mdiPlus} /> {t("common.add").toUpperCase()}
        </Button>
      </div>

      <Table responsive>
        <thead>
          <tr>
            <th>
              <h4 className="light">{t("person.fiscalCode")}</h4>
            </th>
            <th>
              <h4 className="light">{t("common.nominative")}</h4>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {session.otherPatients.map((patient) => (
            <tr key={patient.id}>
              <td>
                <strong>{patient.fiscalCode}</strong>
              </td>
              <td>{`${patient.name} ${patient.surname}`}</td>
              <td className="text-right">
                <Button
                  variant="outline-secondary"
                  title={t("common.view")}
                  disabled={!canEditPatients}
                  onClick={() => setPatientToEdit(patient)}
                >
                  <Icon size={1} path={mdiFolderOpen} />
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  title={t("common.delete")}
                  disabled={!canEditPatients}
                  onClick={() => setPatientToDelete(patient)}
                >
                  <Icon size={1} path={mdiTrashCan} />
                </Button>
              </td>
            </tr>
          ))}
          {!session.otherPatients.length && !session.isLoadingPatients && (
            <tr>
              <td colSpan={10}>{t("common.noElementsToShow")}</td>
            </tr>
          )}
        </tbody>
      </Table>

      {!session.otherPatients.length && session.isLoadingPatients && (
        <LoadingMessage description={t("common.loading")} />
      )}

      <Modal show={isAddingPatient} onHide={onCancelAddPatient}>
        <Modal.Header>
          <Modal.Title>
            {t("common.add")} {t("common.patient")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isAddingPatient && (
            <AddOrEditPatientForm
              submitError={error}
              onSubmit={onAddPatient}
              onCancel={onCancelAddPatient}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={!!patientToEdit} onHide={onCancelEditPatient}>
        <Modal.Header>
          <Modal.Title>
            {t("common.edit")} {t("common.patient")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {patientToEdit && (
            <AddOrEditPatientForm
              submitError={error}
              patient={patientToEdit}
              onSubmit={onEditPatient}
              onCancel={onCancelEditPatient}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal show={!!patientToDelete} onHide={cancelDeletePatient}>
        <Modal.Header>
          <Modal.Title>
            {t("common.delete")} {t("common.patient")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("reservedArea.deletePatientConfirmation", {
              name: `${patientToDelete?.name} ${patientToDelete?.surname}`,
            })}
          </p>
          <div className="text-center">
            <Button variant="outline-secondary" onClick={cancelDeletePatient}>
              {t("common.cancel")} <Icon size={1} path={mdiClose} />
            </Button>
            <Button variant="danger" onClick={confirmDeletePatient}>
              {t("common.delete")} <Icon size={1} path={mdiTrashCan} />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
});

type FormProps = {
  patient?: Patient;
  submitError?: string;
  onSubmit: (patient: Patient) => void;
  onCancel: () => void;
};

export const AddOrEditPatientForm: React.FC<FormProps> = observer((props) => {
  const { session } = useStores();
  const { patient, onCancel, onSubmit, submitError } = props;
  const { t } = useTranslation();

  return (
    <Form
      // When editing, this will retain the patient ID on submit
      initialValues={{ ...patient }}
      validate={validateForm}
      onSubmit={onSubmit}
      render={(formProps) => (
        <BSForm onSubmit={formProps.handleSubmit} className="form-container-sm">
          <RadioInput
            name="relationship"
            label={t("person.relationship")}
            options={[
              {
                label: t(
                  `person.relationships.${PatientRelationship.RELATIVE}`
                ),
                value: PatientRelationship.RELATIVE,
              },
              {
                label: t(`person.relationships.${PatientRelationship.PATIENT}`),
                value: PatientRelationship.PATIENT,
              },
            ]}
          />
          <BSForm.Row>
            <Col>
              <TextInput
                name="name"
                label={t("person.givenName")}
                placeholder="Mario"
              />
            </Col>
            <Col>
              <TextInput
                name="surname"
                label={t("person.familyName")}
                placeholder="Rossi"
              />
            </Col>
          </BSForm.Row>
          <BSForm.Row>
            <Col>
              <DateInput name="birthDate" label={t("person.bornOn")} />
            </Col>
            <Col>
              <TextInput
                name="birthPlace"
                label={t("person.bornAt")}
                placeholder={t("person.bornAtPlaceholder")}
              />
            </Col>
          </BSForm.Row>
          <TextInput
            name="residentialAddress"
            label={t("person.residentialAddress")}
            placeholder="Via tal dei tali, 20"
          />
          <TextInput
            name="fiscalCode"
            label={t("person.fiscalCode")}
            placeholder="RSSBBR69C48F839A"
          />
          <TextInput
            name="healthInsuranceCard"
            label={t("person.healthInsuranceCard")}
            placeholder="1234567…"
          />
          <TextInput
            name="phoneNumber"
            label={t("person.phoneNumber")}
            placeholder="+393334445555"
          />

          {/* Extra */}

          <RadioInput
            label={t("person.movementLabel")}
            name="mobility"
            options={session.predefinedOptions.patientMobility.map(
              (option) => ({
                label: t(`person.mobility.${option.value}`),
                value: option.value,
              })
            )}
          />

          <RadioInput
            label={t("person.weightLabel")}
            name="weight"
            options={[
              {
                label: t(`person.weights.${PatientWeightRange.LESS_50}`),
                value: PatientWeightRange.LESS_50,
              },
              {
                label: t(`person.weights.${PatientWeightRange.RANGE_50_80}`),
                value: PatientWeightRange.RANGE_50_80,
              },
              {
                label: t(`person.weights.${PatientWeightRange.RANGE_80_110}`),
                value: PatientWeightRange.RANGE_80_110,
              },
              {
                label: t(`person.weights.${PatientWeightRange.MORE_110}`),
                value: PatientWeightRange.MORE_110,
              },
            ]}
          />

          <ToggleInputList
            name="specialNeeds"
            label={t("person.hasSpecialNeeds")}
            options={session.predefinedOptions.patientSpecialNeed.map(
              (option) => ({
                value: option.value,
                label: t(`person.specialNeeds.${option.value}`),
              })
            )}
            block={true}
          />

          <ToggleInputList
            name="immobilizationAids"
            label={t("person.immobilizationAidsType")}
            options={session.predefinedOptions.patientImmobilizationAid.map(
              (option) => ({
                value: option.value,
                label: t(`person.immobilizationAids.${option.value}`),
              })
            )}
          />

          <ToggleInputList
            name="diseases"
            label={t("person.diseasesType")}
            options={session.predefinedOptions.patientDisease.map((option) => ({
              value: option.value,
              label: t(`person.diseases.${option.value}`),
            }))}
          />

          {!formProps.submitting && submitError && (
            <Alert variant="danger">{submitError}</Alert>
          )}

          <div className="text-center" style={{ marginTop: "2rem" }}>
            <Button variant="outline-secondary" onClick={onCancel}>
              {t("common.cancel")}
              <Icon size={1} path={mdiClose} />
            </Button>
            <Button
              type={"submit"}
              variant="primary"
              disabled={formProps.submitting}
            >
              {t("common.save")} <Icon size={1} path={mdiFloppy} />
            </Button>
          </div>
        </BSForm>
      )}
    />
  );
});

export const validateForm: ValidateForm<Patient> = (values) => {
  const errors: FormErrors<Patient> = {};

  if (!values.name) {
    errors.name = "Il nome è richiesto";
  }
  if (!values.surname) {
    errors.surname = "Il cognome è richiesto";
  }
  if (!values.birthPlace) {
    errors.birthPlace = "La città di nascita è richiesta";
  }
  if (!values.birthDate) {
    errors.birthDate = "La data di nascita è richiesta";
  }
  if (!values.residentialAddress) {
    errors.residentialAddress = "L'indirizzo di residenza è richiesto";
  }
  if (!values.fiscalCode) {
    errors.fiscalCode = "Il codice fiscale è richiesto";
  }

  // // TODO check if it's a number
  // if (!values.healthInsuranceCard) {
  //   errors.healthInsuranceCard =
  //     'Il numero della tessera sanitaria è richiesto';
  // }

  return errors;
};
