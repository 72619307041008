import React from "react";
import { Field } from "react-final-form";
import Form from "react-bootstrap/Form";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import { ErrorLabel } from "./ErrorLabel";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  disablePast?: boolean;
};

export const DateInput: React.FC<Props> = ({
  name,
  label,
  disablePast,
  placeholder,
}) => (
  <Field
    name={name}
    render={({ input }) => {
      const dateValue = input.value ? moment(input.value).toDate() : null;
      return (
        <Form.Group>
          {label && <Form.Label>{label}</Form.Label>}
          <DatePicker
            variant="inline"
            placeholder={placeholder}
            value={dateValue}
            disablePast={disablePast}
            onChange={(date) => input.onChange(date)}
            animateYearScrolling
            fullWidth
            format={"DD/MM/YYYY"}
          />
          <ErrorLabel name={name} />
        </Form.Group>
      );
    }}
  />
);
