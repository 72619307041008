import React from "react";
import { observer } from "mobx-react";
import { Form } from "react-final-form";
import { Form as BSForm, Container, Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { mdiAccount, mdiAccountTie } from "@mdi/js";

import { useStores } from "../hooks/useStores";
import { ValidateForm, FormErrors, UserRole, NewUserProfile } from "../types";

import { TextInput } from "../components/TextInput";
import {
  TabbedRadioInputSeparator,
  TabbedRadioInputContainer,
  TabbedRadioInput,
} from "../components/TabbedRadioInput";
import { DateInput } from "../components/DateInput";
import { CheckboxInput } from "../components/CheckboxInput";
import { TermsAndConditions } from "../components/TermsAndConditions";

import { validateProfileForm } from "./UserProfile";

type RegistrationValues = {
  role: UserRole;
  confirmPassword: string;
  consentGdpr: boolean;
} & NewUserProfile;

export const RegistrationScreen: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();

  const onSubmit = (values: RegistrationValues) => {
    values.username = values.email;
    session.register(values).catch((err) => {
      window.alert(t("common.registrationError") + " " + err.message);
    });
  };

  if (session.isLogged) {
    return <Redirect to={"/"} />;
  }

  return (
    <Container className="main--container">
      <Form<RegistrationValues>
        initialValues={{ role: UserRole.USER }}
        validate={validateForm}
        onSubmit={onSubmit}
        render={(formProps) => (
          <BSForm
            onSubmit={formProps.handleSubmit}
            className="form-container-sm"
          >
            <TabbedRadioInputContainer>
              <TabbedRadioInput
                name="role"
                value={UserRole.USER}
                label={t("session.forUser")}
                icon={mdiAccount}
                iconSize={1.5}
                filled
              />
              <TabbedRadioInputSeparator />
              <TabbedRadioInput
                name="role"
                disabled={true}
                value={UserRole.PARTNER}
                label={t("session.forPartner")}
                icon={mdiAccountTie}
                iconSize={1.5}
                filled
              />
            </TabbedRadioInputContainer>
            <h2 className="text-center" style={{ margin: "3rem 0 1.5rem" }}>
              {t("session.signUp")}
            </h2>
            <TextInput
              name="email"
              type="email"
              label={t("session.email")}
              placeholder="mario.rossi@example.com"
              autocomplete="username"
            />
            <TextInput
              name="password"
              type="password"
              label={t("session.password")}
              autocomplete="new-password"
            />
            <TextInput
              name="confirmPassword"
              type="password"
              label={t("session.confirmPassword")}
              autocomplete="new-password"
            />
            <BSForm.Row>
              <Col>
                <TextInput
                  name="companyName"
                  label={t("person.companyName")}
                  placeholder="Tech S.R.L."
                />
              </Col>
            </BSForm.Row>
            <BSForm.Row>
              <Col>
                <TextInput
                  name="name"
                  label={t("person.givenName")}
                  placeholder="Mario"
                />
              </Col>
              <Col>
                <TextInput
                  name="surname"
                  label={t("person.familyName")}
                  placeholder="Rossi"
                />
              </Col>
            </BSForm.Row>

            <BSForm.Row>
              <Col>
                <DateInput name="birthDate" label={t("person.bornOn")} />
              </Col>
              <Col>
                <TextInput
                  name="birthPlace"
                  label={t("person.bornAt")}
                  placeholder={t("person.bornAtPlaceholder")}
                />
              </Col>
            </BSForm.Row>

            <TextInput
              name="fiscalCode"
              label={t("person.fiscalCode")}
              placeholder="RSSBBR69C48F839A"
            />
            <BSForm.Row>
              <Col>
                <TextInput
                  name="vatNumber"
                  label={t("person.vatNumber")}
                  placeholder="00000000000"
                />
              </Col>
            </BSForm.Row>
            <BSForm.Row>
              <Col>
                <TextInput
                  name="sdi"
                  label={t("person.sdi")}
                  placeholder="5ED7F9"
                />
              </Col>
            </BSForm.Row>
            <TextInput
              name="healthInsuranceCard"
              label={t("person.healthInsuranceCard")}
              placeholder="1234567…"
            />

            <BSForm.Row>
              <Col>
                <TextInput
                  name="mobilePhone"
                  label={t("person.mobilePhone")}
                  placeholder="+393334445555"
                />
              </Col>
              <Col>
                <TextInput
                  name="phoneNumber"
                  label={t("person.phoneNumber")}
                  placeholder="+390494445555"
                />
              </Col>
            </BSForm.Row>

            <TextInput
              name="residentialAddress"
              label={t("person.residentialAddress")}
              placeholder="Via Tal dei Tali"
            />
            <BSForm.Row>
              <Col>
                <TextInput name="country" label={t("person.nation")} />
              </Col>
              <Col>
                <TextInput name="province" label={t("person.province")} />
              </Col>
            </BSForm.Row>
            <BSForm.Row>
              <Col>
                <TextInput name="city" label={t("person.city")} />
              </Col>
              <Col>
                <TextInput name="zipCode" label={t("person.postalCode")} />
              </Col>
            </BSForm.Row>

            <CheckboxInput
              name="consentGdpr"
              description={<TermsAndConditions />}
            />

            <div className="text-center">
              <Button
                type="submit"
                variant="primary"
                disabled={formProps.pristine || formProps.submitting}
              >
                {t("session.signMeUp")}
              </Button>
            </div>
          </BSForm>
        )}
      />
    </Container>
  );
});

const validateForm: ValidateForm<RegistrationValues> = (values) => {
  const errors: FormErrors<RegistrationValues> = {};
  if (!values.role) {
    errors.role = "Selezionare il tipo di account";
  }
  if (!values.email) {
    errors.email = "L'email non può essere vuota";
  }
  if (!values.password) {
    errors.password = "La password non può essere vuota";
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Le password inserite devono coincidere";
  }
  if (!values.consentGdpr) {
    errors.consentGdpr =
      "È necessario accettare i termini e condizioni di utilizzo";
  }
  return {
    ...errors,
    ...validateProfileForm(values),
  };
};
