import { makeAutoObservable } from "mobx";

import { Invoice, Stores } from "../types";
import { TransportRequest } from "../models/TransportRequest";
import api from "../services/api";
import { TransportRequestDraft } from "../models/TransportRequestDraft";
import moment from "moment";

export class TransportsStore {
  stores: Stores;

  /** Current transport request draft */
  requestDraft?: TransportRequestDraft = undefined;

  requestInvoices: Invoice[] = [];
  isLoadingInvoices = false;

  requests: TransportRequest[] = [];
  isLoadingRequests = false;

  vehiclesAvailable = false;
  vehiclesAvailablePublic: any = null;

  constructor(stores: Stores) {
    this.stores = stores;

    makeAutoObservable(this);
  }

  async fetchTransportRequest(id: string): Promise<TransportRequest> {
    const cachedRequest = this.requests.find((request) => request.id === id);
    if (cachedRequest) {
      console.log("fetchTransportRequest: found cached request", id);
      return cachedRequest;
    }

    const request = await api.getTransportRequest(id);
    return new TransportRequest(request);
  }

  async fetchTransportRequests(): Promise<TransportRequest[]> {
    this.requests = [];
    try {
      this.isLoadingRequests = true;
      const requests = await api.getTransportRequests();
      this.requests = requests.map((r) => new TransportRequest(r));
      return this.requests;
    } finally {
      this.isLoadingRequests = false;
    }
  }

  setRequestDraft(requestDraft: TransportRequestDraft) {
    this.requestDraft = requestDraft;
  }

  clearRequestDraft() {
    this.requestDraft?.invalidateQuotation();
    this.requestDraft = undefined;
  }

  getOrCreateRequestDraft(): TransportRequestDraft {
    // Don't re-use a request draft already completed and confirmed
    if (!this.requestDraft || this.requestDraft.quotationConfirmed) {
      this.requestDraft = new TransportRequestDraft();
    }
    return this.requestDraft;
  }

  replicateAsDraft = async (
    id: string,
    alterations: { departureTime: string }
  ) => {
    try {
      const prevTransport = await this.fetchTransportRequest(id);
      this.requestDraft = prevTransport.cloneAsDraft(alterations);
    } catch (err: any) {
      console.log("replicateAsDraft failed", err.message);
      throw new Error("Fallita replica");
    }
  };

  isVehiclesAvailable = async (
    startTime: string,
    endTime: string,
    province: string
  ) => {
    const response = await api.isVehiclesAvailable(
      startTime,
      endTime,
      province
    );
    this.vehiclesAvailable = response;
  };

  isVehiclesAvailablePublic = async (
    startTime: string,
    endTime: string,
    province: string
  ) => {
    const response = await api.isVehiclesAvailablePublic(
      startTime,
      endTime,
      province
    );

    this.vehiclesAvailablePublic = response;
  };

  async fetchInvoices() {
    this.requestInvoices = [];
    try {
      this.isLoadingInvoices = true;

      const startDate = moment().format("YYYY/MM/DD HH:mm:ss"),
        endDate = moment().format("YYYY/MM/DD HH:mm:ss");

      this.requestInvoices = await api.getInvoices(
        startDate,
        endDate,
        String(this.stores.session.user?.id)
      );
    } finally {
      this.isLoadingInvoices = false;
    }
  }

  async downloadInvoice(orderId: string) {
    return await api.getInvoiceDocument(orderId);
  }
}
