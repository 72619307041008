import _ from "lodash";
import { TransportRequestDraftValues, ValidateForm } from "../types";
import { validateAutofillPatientStageForm } from "../pages/TransportRequestWizard/AutofillPatientStageForm";
import { validatePatientExtraStageForm } from "../pages/TransportRequestWizard/PatientExtraStageForm";
import { validatePatientStageForm } from "../pages/TransportRequestWizard/PatientStageForm";
import {
  validateArrivalPlaceStageForm,
  validateDepartureplaceStageForm,
} from "../pages/TransportRequestWizard/PlaceStageForm";
import { validateRouteStageForm } from "../pages/TransportRequestWizard/RouteStageForm";
import { validateTransportStageForm } from "../pages/TransportRequestWizard/TripStageForm";

export const validateTransportRequestValues: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  return _.omitBy(
    _.merge(
      {},
      validateRouteStageForm(values),
      validateAutofillPatientStageForm(values),
      validatePatientStageForm(values),
      validatePatientExtraStageForm(values),
      validateTransportStageForm(values),
      validateArrivalPlaceStageForm(values),
      validateDepartureplaceStageForm(values)
    ),
    _.isEmpty
  );
};
