export default {
  IS_DEV: process.env.NODE_ENV === "development",

  MOCK_API: false,
  MOCK_MAPS: false,
  MOCK_FORM_VALIDATION: false,

  // Set initial wizard stage; -1 disable
  DEV_INITIAL_STAGE: -1,
  // Set route to redirect to; '' disable
  DEV_INITIAL_ROUTE: "",

  DEBUG_ROUTER: false,
  STRIPE_PK_TEST:
    "pk_test_51IKiBqJssBWurPWw3dae9RMFIdnhDIMhh5arBHOnCDIKrhbXOO19Uf3aidgXhRPXpnjCAcE8SjpQZRrfWkAISh6f00hyD5LkWm",
  STRIPE_PK_PROD:
    "pk_test_51IKiBqJssBWurPWw3dae9RMFIdnhDIMhh5arBHOnCDIKrhbXOO19Uf3aidgXhRPXpnjCAcE8SjpQZRrfWkAISh6f00hyD5LkWm",

  DEV_PAYPAL_CLIENT_ID:
    "AebcTTwjd7XXLn6hCqmVVIEFngxqiZ1LfiU-k-gUyKa5WClMLK6KXuYA7vopGj3zouBR6Z_H9cy_6P7s",
  PROD_PAYPAL_CLIENT_ID:
    "AQzp30UGrprDMg7HQBssxa0-pfzvvLWuWgaSAusMw06l8pz6AcCdEIel53Q932H1OzoOBU8XjAqMckZN",

  DEV_PAYPAL_URL: "https://api-m.sandbox.paypal.com",
  PROD_PAYPAL_URL: "https://api-m.paypal.com",

  DEV_SATISPAY_URL: "https://staging.authservices.satispay.com/",
  PROD_SATISPAY_URL: "https://authservices.satispay.com/",

  DEV_PUBLIC_KEY: "",
  PROD_PUBLIC_KEY: "",

  DEV_TOKEN: "",
  PROD_TOKEN: "",
};
