import React, { useEffect } from "react";
import { Form, FormRenderProps } from "react-final-form";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import env from "../../config/env";
import { useStores } from "../../hooks/useStores";

import { TransportRequestDraftValues, ValidateForm } from "../../types";
import { PatientStageForm, validatePatientStageForm } from "./PatientStageForm";
import { validateRouteStageForm, RouteStageForm } from "./RouteStageForm";
import {
  ArrivalPlaceStageForm,
  DeparturePlaceStageForm,
  validateDepartureplaceStageForm,
  validateArrivalPlaceStageForm,
} from "./PlaceStageForm";
import {
  PatientExtraStageForm,
  validatePatientExtraStageForm,
} from "./PatientExtraStageForm";

import {
  AutofillPatientStageForm,
  validateAutofillPatientStageForm,
} from "./AutofillPatientStageForm";
import { WizardPaginationControls } from "../../components/WizardPaginationControls";
import { TripStageForm, validateTransportStageForm } from "./TripStageForm";
import { NotesStageForm } from "./NotesStageForm";
import { withLoginGuard } from "./LoginGuardStageForm";
import { validateTransportRequestValues } from "../../utils/wizard";

const formStages: Array<{
  Component: React.ElementType<FormRenderProps<TransportRequestDraftValues>>;
  validate: ValidateForm<TransportRequestDraftValues>;
}> = [
  {
    Component: RouteStageForm,
    validate: validateRouteStageForm,
  },
  {
    Component: withLoginGuard(AutofillPatientStageForm),
    validate: validateAutofillPatientStageForm,
  },
  {
    Component: withLoginGuard(PatientStageForm),
    validate: validatePatientStageForm,
  },
  {
    Component: withLoginGuard(PatientExtraStageForm),
    validate: validatePatientExtraStageForm,
  },
  {
    Component: withLoginGuard(TripStageForm),
    validate: validateTransportStageForm,
  },
  {
    Component: withLoginGuard(DeparturePlaceStageForm),
    validate: validateDepartureplaceStageForm,
  },
  {
    Component: withLoginGuard(ArrivalPlaceStageForm),
    validate: validateArrivalPlaceStageForm,
  },
  {
    Component: withLoginGuard(NotesStageForm),
    validate: validateTransportRequestValues,
  },
];

export const lastStage = formStages.length - 1;

type Props = {
  stage: number;
  onCompletedStage: () => void;
  onPrevStage: () => void;
};

export const TransportRequestWizard: React.FC<Props> = observer((props) => {
  const { stage, onCompletedStage, onPrevStage } = props;

  const { transports } = useStores();

  const transportRequest = transports.getOrCreateRequestDraft();
  const FormStage = formStages[stage];
  console.log("stage: ", stage);

  const handleSubmit = async (values: TransportRequestDraftValues) => {
    try {
      console.log("Completed wizard stage", stage, toJS(values));
      transportRequest.editValues(values);

      onCompletedStage();
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  if (!FormStage) {
    throw new Error(`Form for stage "${stage}" does not exists`);
  }

  let { validate } = FormStage;
  if (env.MOCK_FORM_VALIDATION) {
    validate = () => ({});
  }

  return (
    <Form
      initialValues={transportRequest.values}
      validate={validate}
      onSubmit={handleSubmit}
      render={(formProps) => (
        <form
          style={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={formProps.handleSubmit}
        >
          <FormStage.Component {...formProps} />
          <hr />
          <WizardPaginationControls
            goNext={() => formProps.form.submit()}
            goPrev={onPrevStage}
            disabled={formProps.submitting}
          />
        </form>
      )}
    />
  );
});

// Prototipo StyleSheet stile react-native
// const styles: { [className: string]: React.CSSProperties } = {};
