import React from "react";
import { Field } from "react-final-form";
import Form from "react-bootstrap/Form";

import { ErrorLabel } from "./ErrorLabel";

type Props = {
  name: string;
  description: React.ReactNode;
};

export const CheckboxInput: React.FC<Props> = ({ name, description }) => (
  <Field
    name={name}
    type="checkbox"
    render={({ input }) => {
      return (
        <Form.Group onClick={() => input.onChange(!input.checked)}>
          <Form.Check {...input} label={description} type="checkbox" />
          <ErrorLabel name={name} />
        </Form.Group>
      );
    }}
  />
);
