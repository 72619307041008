import ky from "ky";
import env from "../config/env";
import keys from "../config/keys";
import {
  UserProfile,
  PatientRelationship,
  NewUserProfile,
  Patient,
  Place,
  PlaceType,
  PredefinedOption,
  AllPredefinedOptions,
  PaymentMethod,
  BookedTransportRequest,
  Invoice,
  TransportRequestDraftValues,
  TransportRequestValues,
} from "../types";
import mocks from "../mocks";

interface APIUser {
  ID: string;
  CreationDate: string;
  FirstName: string;
  LastName: string;
  CompanyName?: string;
  FiscalCode: string;
  VatNumber?: string;
  NumeroTessera?: string;
  Address: string;
  ZipCode: string;
  City: string;
  Province: string;
  Country: string;
  Email: string;
  Phone?: string;
  Mobile?: string;
  BirthDate: string;
  BirthPlace: string;
  UserName: string;
  Notes?: string;
  SDI?: string;
  // Fields not actually used when fetching from backend
  // Deleted: boolean;
  // Password?: string;
  // NewPassword?: string;
}

interface APIPredefinedOption {
  BitID: number;
  Value: string;
}

interface APIPatient {
  ID: string;
  /** ISO Date */
  CreationDate: string;
  FirstName: string;
  LastName: string;
  CompanyName?: string;
  FiscalCode: string;
  VatNumber?: string;
  InsuranceNumber: string;
  BirthDate: string;
  BirthPlace: string;

  Address: string;
  ZipCode: string;
  City: string;
  Province: string;
  Country: string;

  Email: string;
  /** Landline phone number */
  Phone?: string;
  /** Mobile phone number */
  Mobile?: string;

  Weight: number;
  Mobility?: APIPredefinedOption | null;
  SpecialNeeds: APIPredefinedOption[];
  ImmobilizationAids: APIPredefinedOption[];
  Diseases: APIPredefinedOption[];

  Notes?: string;
  Deleted: boolean;
}

interface APIPlace {
  ID: string;
  /** ISO Date */
  CreationDate: string;
  Name: string;
  Address: string;
  ZipCode: string;
  City: string;
  Province: string;
  Country: string;
  Floor: string;
  BuildingType: APIPredefinedOption;
  Details: APIPredefinedOption[];
  // For Hospital
  Building?: string;
  // For Hospital
  Ward?: string;
  Notes?: string;
  Deleted: boolean;
}

interface APINewTransportRequest {
  Departure: APIPlace;
  Destination: APIPlace;
  Patient: APIPatient;
  UserID: string;
  TripReason: APIPredefinedOption | undefined;
  Examinations: APIPredefinedOption[];
  /** Date for the tranport request. Format "2021-03-12T21:00:00" */
  RequestTime: string;
  Notes?: string;
}

interface APIBookedTransportRequest extends APINewTransportRequest {
  ID: string;
  /** Unique and human-readable code for this transport */
  Number?: string;
  /** Date for the creation in the database. Format "2021-03-12T15:12:10.123" */
  CreationDate: string;
  /** Date for the quotation creation. Format "2021-03-12T15:12:10.123" */
  Date: string;
  /** Date for the tranport request. Format "2021-03-12T21:00:00" */
  RequestTime: string;
  /** es. "00:00:00" */
  WaitTime: string;
  /** es. "00:00:00" */
  TravelTime: string;
  /** es. "00:00:00" */
  ExamTime: string;
  /** es. 7.8 */
  TravelKm: number;
  /** es. 7.8 */
  Cost: number;
  Status: APIPredefinedOption;
  CancelReasons: APIPredefinedOption[];
  URL: string;
  Notes?: string;
  Deleted: boolean;
}

const mapToApiUser = (user: UserProfile): APIUser => ({
  FirstName: user.name,
  LastName: user.surname,
  Address: user.residentialAddress,
  BirthDate: user.birthDate,
  BirthPlace: user.birthPlace,
  City: user.city,
  CompanyName: user.companyName,
  Country: user.country,
  Email: user.email,
  FiscalCode: user.fiscalCode,
  ID: user.id,
  ZipCode: user.zipCode,
  Mobile: user.mobilePhone,
  Phone: user.phoneNumber,
  Province: user.province,
  VatNumber: user.vatNumber,
  Notes: user.notes,
  CreationDate: user.creationDate,
  UserName: user.username,
  SDI: user.sdi,
});

const mapFromApiUser = (user: APIUser): UserProfile => ({
  username: user.UserName,
  id: user.ID,
  email: user.Email,
  name: user.FirstName,
  surname: user.LastName,
  fiscalCode: user.FiscalCode,
  phoneNumber: user.Phone,
  birthDate: user.BirthDate,
  birthPlace: user.BirthPlace,
  residentialAddress: user.Address,
  healthInsuranceCard: user.NumeroTessera,
  creationDate: user.CreationDate,
  country: user.Country,
  province: user.Province,
  city: user.City,
  zipCode: user.ZipCode,
  mobilePhone: user.Mobile,
  notes: user.Notes,
  companyName: user.CompanyName,
  sdi: user.SDI,
  vatNumber: user.VatNumber,
});

const mapFromApiPredefinedOption = (
  option: APIPredefinedOption
): PredefinedOption => ({
  id: option.BitID,
  value: option.Value,
});

const mapSimpleFromApiPredefinedOption = (
  option: APIPredefinedOption
): PredefinedOption["value"] => option.Value;

const mapToApiPredefinedOption = (
  optionValue: PredefinedOption["value"],
  options: PredefinedOption[]
): APIPredefinedOption | undefined => {
  const pOption = options.find((option) => option.value === optionValue);
  if (pOption) {
    return {
      BitID: pOption.id,
      Value: pOption.value,
    };
  }
};

const mapAllToApiPredefinedOption = (
  optionValues: PredefinedOption["value"][],
  options: PredefinedOption[]
): APIPredefinedOption[] => {
  return optionValues
    .map((ov) => mapToApiPredefinedOption(ov, options))
    .filter((x): x is APIPredefinedOption => x != null);
};

const mapFromApiPatient = (patient: APIPatient): Patient => {
  return {
    id: patient.ID,
    name: patient.FirstName,
    surname: patient.LastName,
    companyName: patient.CompanyName,
    vatNumber: patient.VatNumber,
    fiscalCode: patient.FiscalCode,
    phoneNumber: patient.Phone,
    birthDate: patient.BirthDate,
    birthPlace: patient.BirthPlace,
    residentialAddress: patient.Address,
    healthInsuranceCard: patient.InsuranceNumber,
    country: patient.Country,
    province: patient.Province,
    city: patient.City,
    zipCode: patient.ZipCode,
    mobilePhone: patient.Mobile,
    notes: patient.Notes,
    creationDate: patient.CreationDate,

    weight: patient.Weight,
    mobility:
      patient.Mobility != null
        ? mapSimpleFromApiPredefinedOption(patient.Mobility)
        : undefined,
    diseases: patient.Diseases.map(mapSimpleFromApiPredefinedOption),
    specialNeeds: patient.SpecialNeeds.map(mapSimpleFromApiPredefinedOption),
    immobilizationAids: patient.ImmobilizationAids.map(
      mapSimpleFromApiPredefinedOption
    ),

    // TODO: where to get this extra info? Always consider the patient as a Patient for the user
    relationship: PatientRelationship.PATIENT,
  };
};

const mapToApiPatient = (
  patient: Patient,
  allPredefinedOptions: AllPredefinedOptions
): APIPatient => {
  return {
    ID: patient.id,
    CreationDate: patient.creationDate,
    Email: "",
    FirstName: patient.name,
    LastName: patient.surname,
    Address: patient.residentialAddress,
    BirthDate: patient.birthDate,
    BirthPlace: patient.birthPlace,
    City: patient.city,
    CompanyName: patient.companyName,
    InsuranceNumber: patient.healthInsuranceCard || "",
    Country: patient.country,
    FiscalCode: patient.fiscalCode,
    ZipCode: patient.zipCode,
    Mobile: patient.mobilePhone,
    Phone: patient.phoneNumber,
    Province: patient.province,
    VatNumber: patient.vatNumber,

    Weight: patient.weight,
    Mobility:
      patient.mobility != null
        ? mapToApiPredefinedOption(
            patient.mobility,
            allPredefinedOptions.patientMobility
          ) || null
        : null,
    Diseases: patient.diseases
      ? mapAllToApiPredefinedOption(
          patient.diseases,
          allPredefinedOptions.patientDisease
        )
      : [],
    ImmobilizationAids: patient.immobilizationAids
      ? mapAllToApiPredefinedOption(
          patient.immobilizationAids,
          allPredefinedOptions.patientImmobilizationAid
        )
      : [],
    SpecialNeeds: patient.specialNeeds
      ? mapAllToApiPredefinedOption(
          patient.specialNeeds,
          allPredefinedOptions.patientSpecialNeed
        )
      : [],
    Notes: patient.notes,
    Deleted: false,
  };
};

const mapFromApiPlace = (place: APIPlace): Place => ({
  id: place.ID,
  name: place.Name,
  creationDate: place.CreationDate,
  address: place.Address,
  floor: place.Floor,
  city: place.City,
  country: place.Country,
  zipCode: place.ZipCode,
  province: place.Province,
  hospitalBuilding: place.Building,
  hospitalWard: place.Ward,
  type:
    place.BuildingType.Value === PlaceType.HOUSE
      ? PlaceType.HOUSE
      : PlaceType.HEALTH_FACILITY,
  details: place.Details && place.Details.map(mapSimpleFromApiPredefinedOption),
});

const mapToApiPlace = (
  place: Place,
  allPredefinedOptions: AllPredefinedOptions
): APIPlace => ({
  ID: place.id,
  Name: place.name || "",
  // CreationDate: place.creationDate,
  CreationDate: new Date().toISOString(),
  Address: place.address || "",
  Floor: place.floor || "",
  City: place.city || "",
  Country: place.country || "",
  ZipCode: place.zipCode || "",
  Province: place.province || "",
  BuildingType: mapToApiPredefinedOption(
    place.type,
    allPredefinedOptions.buildingType
  )!,
  Details: place.details
    ? mapAllToApiPredefinedOption(
        place.details,
        allPredefinedOptions.buildingDetail
      )
    : [],
  Ward: place.type === PlaceType.HEALTH_FACILITY ? place.hospitalWard : "",
  Building:
    place.type === PlaceType.HEALTH_FACILITY ? place.hospitalBuilding : "",
  Notes: place.notes || "",
  Deleted: false,
});

/**
 *
 * @param time "11:22:33"
 */
const parseTimeToSeconds = (time: string) => {
  const [hours, minutes, seconds] = time.split(":");
  return (
    Number.parseInt(hours, 10) * 60 * 60 +
    Number.parseInt(minutes, 10) * 60 +
    Number.parseInt(seconds, 10)
  );
};

const mapFromApiBookedTransportRequest = (
  request: APIBookedTransportRequest
): BookedTransportRequest => {
  console.log(request.ID);
  console.log(request.Status);
  return {
    id: request.ID,
    number: request.Number,
    patient: mapFromApiPatient(request.Patient),
    departurePlace: mapFromApiPlace(request.Departure),
    arrivalPlace: mapFromApiPlace(request.Destination),
    trip: {
      departureTime: request.RequestTime,
      examinations: request.Examinations.map((e) => e.Value),
      reason: request.TripReason ? request.TripReason.Value : null,
      notes: request.Notes,
    },
    quotation: {
      status: request.Status
        ? mapFromApiPredefinedOption(request.Status)
        : null,
      estimateUrl: request.URL,
      cost: request.Cost,
      waitingTime: parseTimeToSeconds(request.WaitTime),
      travelTime: parseTimeToSeconds(request.TravelTime),
      travelDistance: request.TravelKm,
      examinationsTime: parseTimeToSeconds(request.ExamTime),
    },
    creationDate: request.CreationDate,
    cancelReasons: request.CancelReasons.map((option) =>
      mapFromApiPredefinedOption(option)
    ),
  };
};

const mapToApiBookedTransportRequest = (
  request: BookedTransportRequest,
  allPredefinedOptions: AllPredefinedOptions
): APIBookedTransportRequest => {
  // TODO
  throw new Error("TODO mapToApiBookedTransportRequest");
};

const mapToApiNewTransportRequest = (
  request: TransportRequestValues,
  allPredefinedOptions: AllPredefinedOptions,
  userId: string
): APINewTransportRequest => {
  return {
    Destination: mapToApiPlace(request.arrivalPlace, allPredefinedOptions),
    Patient: mapToApiPatient(request.patient, allPredefinedOptions),
    Departure: mapToApiPlace(request.departurePlace, allPredefinedOptions),
    Examinations: request.trip.examinations
      ? mapAllToApiPredefinedOption(
          request.trip.examinations,
          allPredefinedOptions.tripExamination
        )
      : [],
    TripReason: request.trip.reason
      ? mapToApiPredefinedOption(
          request.trip.reason,
          allPredefinedOptions.tripReason
        )
      : undefined,
    RequestTime: request.trip.departureTime,
    Notes: request.trip.notes,
    UserID: userId,
  };
};

let sessionToken: string | undefined;
let startTime: string | undefined;
let endTime: string | undefined;
let province: string | undefined;
let clientId: string | undefined;
let orderId: string | undefined;

const setSession = (token: string) => {
  sessionToken = token;
};

const setStartTime = (time: string) => {
  startTime = time;
};

const setEndTime = (time: string) => {
  endTime = time;
};

const setProvince = (provinceCode: string) => {
  province = provinceCode;
};

const clearSession = () => {
  sessionToken = undefined;
};

const setClientId = (id: string) => {
  clientId = id;
};

const setOrderId = (id: string) => {
  orderId = id;
};

type LoginResponse = {
  User: APIUser;
  Token: string;
};

const login = async (
  username: string,
  password: string
): Promise<LoginResponse> => {
  if (env.MOCK_API) {
    return {
      User: {}, // user data from here not used
      Token: "xxxmocktokenxxx",
    } as any;
  }

  const response: LoginResponse = await makeApiRequest("POST", "Login", {
    userName: username,
    password: password,
    platformId: 0,
    serviceId: 0,
  });

  console.log("login response", response);

  setSession(response.Token);

  return response;
};

const logout = async (): Promise<void> => {
  if (env.MOCK_API) {
    clearSession();
    return;
  }

  try {
    await makeApiRequest("POST", "Logout");
  } finally {
    clearSession();
  }
};

const registerNewUser = async (newUser: NewUserProfile): Promise<void> => {
  await makeApiRequest("POST", "Profile", {
    ...mapToApiUser(newUser),
    Password: newUser.password,
  });
};

const changeUserPassword = async (
  currentPassword: string,
  newPassword: string
): Promise<void> => {
  const user: APIUser = await makeApiRequest("GET", "Profile");
  return makeApiRequest("PATCH", "Profile", {
    // Other user data are needed to call this endpoint
    ...user,
    UserName: user.UserName,
    Password: currentPassword,
    NewPassword: newPassword,
  });
};

const getUser = async (): Promise<UserProfile> => {
  if (env.MOCK_API) {
    return {
      ...mocks.userPatient,
    };
  }

  const user: APIUser = await makeApiRequest("GET", "Profile");
  return mapFromApiUser(user);
};

const getAllPredefinedOptions = async (): Promise<AllPredefinedOptions> => {
  if (env.MOCK_API) {
    return { ...mocks.allPredefinedOptions };
  }

  const optionsPathMap: Array<[keyof AllPredefinedOptions, string]> = [
    ["patientMobility", "Patients/MobilitiesList"],
    ["patientSpecialNeed", "Patients/SpecialNeedsList"],
    ["patientImmobilizationAid", "Patients/ImmobilizationAidsList"],
    ["patientDisease", "Patients/DiseasesList"],
    ["buildingType", "Places/BuildingTypesList"],
    ["buildingDetail", "Places/DetailsList"],
    ["quotationStatus", "Quotations/StatusList"],
    ["tripExamination", "Quotations/ExaminationsList"],
    ["quotationCancelReason", "Quotations/CancelReasonsList"],
    ["tripReason", "Quotations/TripReasonsList"],
  ];

  // TODO: use mocked data as base and fallback, if any of the endpoint fails?
  const optionsMap: AllPredefinedOptions = {
    buildingDetail: [],
    buildingType: [],
    patientDisease: [],
    patientImmobilizationAid: [],
    patientMobility: [],
    patientSpecialNeed: [],
    quotationStatus: [],
    tripExamination: [],
    quotationCancelReason: [],
    tripReason: [],
  };

  await Promise.all(
    optionsPathMap.map(async ([key, path]) => {
      const values: APIPredefinedOption[] = await makeApiRequest("GET", path);
      console.log(values);
      optionsMap[key] = values.map(mapFromApiPredefinedOption);
    })
  );

  return optionsMap;
};

const getPatients = async (): Promise<Patient[]> => {
  if (env.MOCK_API) {
    return [...mocks.patients];
  }

  const patients: APIPatient[] = await makeApiRequest("GET", "Patients");
  return patients.map(mapFromApiPatient);
};

const addPatient = async (
  newPatient: Patient,
  allPredefinedOptions: AllPredefinedOptions
): Promise<Patient> => {
  const added: APIPatient = await makeApiRequest(
    "POST",
    "Patients",
    mapToApiPatient(newPatient, allPredefinedOptions)
  );
  return mapFromApiPatient(added);
};

const updatePatient = async (
  patient: Patient,
  allPredefinedOptions: AllPredefinedOptions
): Promise<Patient> => {
  const updated: APIPatient = await makeApiRequest(
    "PATCH",
    `Patients/${patient.id}`,
    mapToApiPatient(patient, allPredefinedOptions)
  );
  return mapFromApiPatient(updated);
};

const deletePatient = async (patientId: string): Promise<void> => {
  await makeApiRequest("DELETE", `Patients/${patientId}`);
};

const getPlaces = async (): Promise<Place[]> => {
  if (env.MOCK_API) {
    return [...mocks.places];
  }

  const places: APIPlace[] = await makeApiRequest("GET", "Places");
  return places.map(mapFromApiPlace);
};

const addPlace = async (
  newPlace: Place,
  allPredefinedOptions: AllPredefinedOptions
): Promise<Place> => {
  const added: APIPlace = await makeApiRequest(
    "POST",
    "Places",
    mapToApiPlace(newPlace, allPredefinedOptions)
  );
  return mapFromApiPlace(added);
};

const updatePlace = async (
  place: Place,
  allPredefinedOptions: AllPredefinedOptions
): Promise<Place> => {
  const updated: APIPlace = await makeApiRequest(
    "PATCH",
    `Places/${place.id}`,
    mapToApiPlace(place, allPredefinedOptions)
  );
  return mapFromApiPlace(updated);
};

const deletePlace = async (placeId: string): Promise<void> => {
  await makeApiRequest("DELETE", `Places/${placeId}`);
};

const getCallURLClick2call = async (): Promise<string> => {
  const response = await makeApiRequest("GET", `Phone/CallOperator`);

  return response.URL;
};

const editUser = async (user: UserProfile): Promise<UserProfile> => {
  const updatedUser: APIUser = await makeApiRequest("PATCH", "Profile", {
    ...mapToApiUser(user),
    // These fields cannot be changed
    CreationDate: undefined,
    Deleted: undefined,
    // Not needed when updating the profile info; see `changePassword`
    UserName: undefined,
    Password: undefined,
    NewPassword: undefined,
  });
  return mapFromApiUser(updatedUser);
};

const getPaymentMethods = async (): Promise<PaymentMethod[]> => {
  if (env.MOCK_API) {
    return [...mocks.paymentMethods];
  }

  // TODO
  return [];
};

const getTransportRequests = async (): Promise<BookedTransportRequest[]> => {
  if (env.MOCK_API) {
    return [...mocks.transportRequests];
  }

  const requests: APIBookedTransportRequest[] = await makeApiRequest(
    "GET",
    "Quotations"
  );
  return requests.map(mapFromApiBookedTransportRequest);
};

const getTransportRequest = async (
  id: string
): Promise<BookedTransportRequest> => {
  if (env.MOCK_API) {
    const request = mocks.transportRequests.find((r) => r.id === id);
    if (!request) {
      throw new Error(`Cannot find booked transport request for id ${id}`);
    }
    return request;
  }

  // TODO: real API call
  throw new Error(`Cannot find booked transport request for id ${id}`);
};

const createTransportRequest = async (
  request: TransportRequestValues
): Promise<BookedTransportRequest> => {
  if (env.MOCK_API) {
    const mockedBookedTransportRequest = mocks.transportRequests[0];
    return {
      ...mockedBookedTransportRequest,
      ...request,
    };
  }
  console.log("createTransportRequest");
  const allPredefinedOptions = await getAllPredefinedOptions();
  console.log({ allPredefinedOptions });
  const profile = await getUser();
  console.log({ profile });

  const data = mapToApiNewTransportRequest(
    request,
    allPredefinedOptions,
    profile.id
  );
  console.log({ data });
  const added: APIBookedTransportRequest = await makeApiRequest(
    "POST",
    "Quotations",
    data
  );
  console.log({ added });
  return mapFromApiBookedTransportRequest(added);
};

const rejectTransportRequest = async (
  quotationId: BookedTransportRequest["id"],
  rejectReasons: PredefinedOption[]
): Promise<void> => {
  const response = await makeApiRequest(
    "DELETE",
    `Quotations/${quotationId}`,
    rejectReasons
  );
};

const getInvoices = async (
  startTime: string,
  endTime: string,
  clientId: string
): Promise<Invoice[]> => {
  if (env.MOCK_API) {
    return [...mocks.invoices];
  }
  setStartTime(startTime);
  setEndTime(endTime);
  setClientId(clientId);
  const invoices: Invoice[] = await makeApiRequest("GET", "Stripe/Payments");

  return invoices;
};

const getInvoiceDocument = async (orderId: string): Promise<string> => {
  setOrderId(orderId);
  const response = await makeApiRequest("GET", `Invoices`);

  return response.URL;
};

const api = ky.create({
  prefixUrl: keys.API_BASE_URL,
  hooks: {
    beforeRequest: [
      (request) => {
        if (sessionToken) {
          request.headers.set("X-App-Session-Token", sessionToken);
        }
        if (startTime) {
          request.headers.set("StartTime", startTime);
        }

        if (endTime) {
          request.headers.set("EndTime", endTime);
        }

        if (province) {
          request.headers.set("Province", province);
        }

        if (clientId) {
          request.headers.set("ClientId", clientId);
        }

        if (orderId) {
          request.headers.set("OrderId", orderId);
        }
      },
    ],
  },
});

const makeApiRequest = async (
  method: "POST" | "GET" | "PATCH" | "DELETE",
  path: string,
  body?: any
): Promise<any> => {
  try {
    const response = await api(path, {
      method: method,
      json: body,
    }).json();
    return response;
  } catch (err: any) {
    console.warn(`Failed request ${method} ${path}`, err);
    // TODO: manage session auth expiration/invalidation
    if (err instanceof ky.HTTPError) {
      const message = await parseErrorMessage(err.response);
      throw new Error(
        `${err.response.status}/${err.response.statusText}: ${message}`
      );
    }
    throw err;
  }
};

const isVehiclesAvailable = async (
  startTime: string,
  endTime: string,
  province: string
): Promise<boolean> => {
  setStartTime(startTime);
  setEndTime(endTime);
  setProvince(province);
  const response: any[] = await makeApiRequest("GET", "Vehicles/ListAvailable");

  return response.length > 0 ? true : false;
};

const isVehiclesAvailablePublic = async (
  startTime: string,
  endTime: string,
  province: string
): Promise<boolean> => {
  setStartTime(startTime);
  setEndTime(endTime);
  setProvince(province);
  const response: any = await makeApiRequest("GET", "Public/VehiclesAvailable");
  return response;
};

const parseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (!data.ErrorMessage) {
      throw new Error();
    }
    return data.ErrorMessage;
  } catch (err: any) {
    return "";
  }
};

const deleteMission = async (
  missionID: string,
  option: PredefinedOption,
  note?: string
): Promise<boolean> => {
  const response = await makeApiRequest("DELETE", "Missions/" + missionID, [
    {
      BitID: option.id,
      Value: option.value,
    },
  ]);

  return true;
};

export default {
  setSession,
  clearSession,
  login,
  logout,
  registerNewUser,
  getUser,
  editUser,
  changeUserPassword,
  getAllPredefinedOptions,
  getPatients,
  addPatient,
  updatePatient,
  deletePatient,
  getPlaces,
  addPlace,
  updatePlace,
  deletePlace,
  getPaymentMethods,
  getTransportRequests,
  getTransportRequest,
  createTransportRequest,
  getInvoices,
  isVehiclesAvailable,
  isVehiclesAvailablePublic,
  getCallURLClick2call,
  getInvoiceDocument,
  rejectTransportRequest,
  deleteMission,
};
