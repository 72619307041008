import React from "react";
import { Field } from "react-final-form";
import Icon from "@mdi/react";
import { classnames } from "../utils/react";

type Props = {
  name: string;
  label: React.ReactNode;
  value: string | number;
  /* Force to be shown as checked even if the value does not corresponds. */
  checked?: boolean;
  disabled?: boolean;
  filled?: boolean;
  icon?: string | JSX.Element;
  iconSize?: number;
  onClick?: () => void;
};

export const TabbedRadioInput: React.FC<Props> = ({
  name,
  label,
  icon,
  value,
  checked,
  filled,
  disabled,
  onClick,
  iconSize = 1,
}) => (
  <Field
    name={name}
    type="radio"
    value={value}
    render={({ input }) => (
      <label
        className={classnames(
          "tabbed-radio-input--item",
          (input.checked || checked) && "checked",
          disabled && "disabled",
          filled && "filled"
        )}
        onClick={!disabled ? onClick : undefined}
      >
        <input
          {...input}
          disabled={disabled}
          type="radio"
          value={value}
          style={{ display: "none" }}
        />
        {typeof icon === "string" && <Icon path={icon} size={iconSize} />}
        {typeof icon !== "string" && icon}
        <div className="tabbed-radio-input--item-label">{label}</div>
      </label>
    )}
  />
);

export const TabbedRadioInputContainer: React.FC = (props) => {
  return <div className="tabbed-radio-input--container">{props.children}</div>;
};

export const TabbedRadioInputSeparator: React.FC = () => {
  return <div className="tabbed-radio-input--separator" />;
};
