import React from "react";
import { FormRenderProps } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";

import {
  FormErrors,
  ValidateForm,
  TransportRequestDraftValues,
  PatientRelationship,
} from "../../types";

import { DateInput } from "../../components/DateInput";
import { TextInput } from "../../components/TextInput";
import { RadioInput } from "../../components/RadioInput";
import { CheckboxInput } from "../../components/CheckboxInput";
import { TermsAndConditions } from "../../components/TermsAndConditions";
import env from "../../config/env";

export const PatientStageForm: React.FC<
  FormRenderProps<TransportRequestDraftValues>
> = (props) => {
  const { values } = props;
  const { t } = useTranslation();

  return (
    <div className="stage-form">
      <h4 className="stage-form--title">{t("transport.stages.patient")}</h4>
      <p>{t("transport.patientStageFormDescription")}</p>
      <div className={"form-container-sm"}>
        <TextInput name="patient.name" label={t("person.givenName")} />
        <TextInput name="patient.surname" label={t("person.familyName")} />
        <Row>
          <Col>
            <DateInput name="patient.birthDate" label={t("person.bornOn")} />
          </Col>
          <Col>
            <TextInput name="patient.birthPlace" label={t("person.bornAt")} />
          </Col>
        </Row>
        <TextInput
          name="patient.residentialAddress"
          label={t("person.residentialAddress")}
        />
        <TextInput name="patient.fiscalCode" label={t("person.fiscalCode")} />
        <TextInput
          name="patient.healthInsuranceCard"
          label={t("person.healthInsuranceCard")}
        />
        {values.patient?.relationship !== PatientRelationship.ME && (
          <RadioInput
            name="patient.relationship"
            label={t("person.relationship")}
            options={[
              {
                label: t(
                  `person.relationships.${PatientRelationship.RELATIVE}`
                ),
                value: PatientRelationship.RELATIVE,
              },
              {
                label: t(`person.relationships.${PatientRelationship.PATIENT}`),
                value: PatientRelationship.PATIENT,
              },
            ]}
          />
        )}
        {values.patient?.id == null && (
          <CheckboxInput
            name="patient.consentGdpr"
            description={<TermsAndConditions />}
          />
        )}
      </div>
    </div>
  );
};

export const validatePatientStageForm: ValidateForm<
  TransportRequestDraftValues
> = (values) => {
  const patientErrors: FormErrors<TransportRequestDraftValues["patient"]> = {};
  const errors: FormErrors<TransportRequestDraftValues> = {
    patient: patientErrors,
  };

  if (!values.patient || !values.patient.relationship) {
    patientErrors.relationship = "Scegli il paziente";
    return errors;
  }

  if (!values.patient.name) {
    patientErrors.name = "Il nome è richiesto";
  }
  if (!values.patient.surname) {
    patientErrors.surname = "Il cognome è richiesto";
  }
  if (!values.patient.birthPlace) {
    patientErrors.birthPlace = "La città di nascita è richiesta";
  }
  if (!values.patient.birthDate) {
    patientErrors.birthDate = "La data di nascita è richiesta";
  }
  if (!values.patient.residentialAddress) {
    patientErrors.residentialAddress = "L'indirizzo di residenza è richiesto";
  }
  if (!values.patient.fiscalCode) {
    patientErrors.fiscalCode = "Il codice fiscale è richiesto";
  }
  if (!values.patient.consentGdpr) {
    patientErrors.consentGdpr = "Il consenso alla GDPR è richiesto";
  }
  if (!values.patient.relationship) {
    patientErrors.relationship =
      "È necessario specificare il tipo di relazione con il paziente";
  }

  // if (
  //   !values.patient.healthInsuranceCard ||
  //   Number.isNaN(Number.parseInt(values.patient.healthInsuranceCard, 10))
  // ) {
  //   patientErrors.healthInsuranceCard =
  //     'Il numero della tessera sanitaria è richiesto';
  // }

  return errors;
};
