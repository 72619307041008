import React from "react";
import { useTranslation } from "react-i18next";

export const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span>
      {t("person.gdprConsentPrefix")} <a href="#TODO-GDRP">GDPR TODO link</a>
    </span>
  );
};
