import React from "react";
import { Button, Table, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiFolderOpen, mdiCancel, mdiSync } from "@mdi/js";
import moment from "moment";

import { TransportRequest } from "../models/TransportRequest";

type Props = {
  requests: TransportRequest[];
  onReplicate?: (request: TransportRequest) => void;
  onViewDetails?: (request: TransportRequest) => void;
  onCancel?: (request: TransportRequest) => void;
  onChoose?: (request: TransportRequest) => void;
};

export const TransportList: React.FC<Props> = ({
  requests,
  onReplicate,
  onViewDetails,
  onCancel,
  onChoose,
}) => {
  const { t } = useTranslation();

  const isClickable = !!onChoose;

  return (
    <Table hover={isClickable} responsive>
      <thead>
        <tr>
          <th>
            <h4 className="light">{t("common.code")}</h4>
          </th>
          <th>
            <h4 className="light">{t("trip.from")}</h4>
          </th>
          <th>
            <h4 className="light">{t("trip.to")}</h4>
          </th>
          <th>
            <h4 className="light">{t("common.patient")}</h4>
          </th>
          <th>
            <h4 className="light">{t("trip.departureDate")}</h4>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {requests.map((request) => (
          <tr
            key={request.id}
            onClick={() => onChoose && onChoose(request)}
            className={isClickable ? "is-clickable" : ""}
          >
            <td>
              {/* TODO: use and order by departure date instead of request code/number? */}
              <strong>{request.data.number || request.id}</strong>
              <br />
              <Badge>{request.status}</Badge>
            </td>
            <td>{request.data.departurePlace.address}</td>
            <td>{request.data.arrivalPlace.address}</td>
            <td>{`${request.data.patient.name} ${request.data.patient.surname}`}</td>
            <td>
              {moment(request.data.trip.departureTime).format(
                "DD/MM/YYYY HH:mm:ss"
              )}
            </td>

            <td className="text-right text-nowrap">
              {onViewDetails && (
                <Button
                  size="sm"
                  variant="outline-secondary"
                  title={t("common.view")}
                  onClick={() => onViewDetails(request)}
                >
                  <Icon size={1} path={mdiFolderOpen} />
                </Button>
              )}
              {onReplicate && (
                <Button
                  size="sm"
                  variant="outline-secondary"
                  title={t("common.replicate")}
                  onClick={() => onReplicate(request)}
                >
                  <Icon size={1} path={mdiSync} />
                </Button>
              )}
              {onCancel && request.status !== "RIFIUTATO" && (
                <Button
                  size="sm"
                  variant="outline-secondary"
                  title={t("common.cancel")}
                  disabled={!request.isCancelable}
                  onClick={() => onCancel(request)}
                >
                  <Icon size={1} path={mdiCancel} />
                </Button>
              )}
            </td>
          </tr>
        ))}
        {!requests.length && (
          <tr>
            <td colSpan={3}>{t("common.noElementsToShow")}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
