import React from "react";
import { Form as BSForm } from "react-bootstrap";
import { useField } from "react-final-form";
import { ErrorLabel } from "./ErrorLabel";

type Option = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  options: Option[];
  label?: string;
  block?: boolean;
};

export const ToggleInputList: React.FC<Props> = ({
  name,
  options,
  label,
  block,
}) => {
  const field = useField(name);

  const fieldValue: string[] = field.input?.value || [];
  const activeOptions = new Set(fieldValue);

  const toggleValue = (value: Option["value"]) => {
    if (activeOptions.has(value)) {
      activeOptions.delete(value);
    } else {
      activeOptions.add(value);
    }

    const newFieldValue = [...activeOptions.values()];
    field.input.onChange(newFieldValue);
  };

  return (
    <BSForm.Group>
      {label && <BSForm.Label>{label}</BSForm.Label>}
      <br />
      {options.map(({ value, label }) => (
        <label
          key={value}
          className={`toggle-btn ${activeOptions.has(value) ? "active" : ""} ${
            block ? "block" : ""
          }`}
          onClick={() => toggleValue(value)}
        >
          <span>{label}</span>
        </label>
      ))}
      <br />
      <ErrorLabel name={name} />
    </BSForm.Group>
  );
};
