import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import { PredefinedOption, RefuseReason, ValidateForm } from "../types";
import { TransportRequest } from "../models/TransportRequest";

import { DateTimeInput } from "./DateTimeInput";
import { TextInput } from "./TextInput";
import { RadioInput } from "./RadioInput";
import { capitalize } from "lodash";

type RefuseFormValues = {
  motivation: number;
  motivationNote?: string;
  redate?: string;
  wantsToRedate: boolean;
};

type Props = {
  requestToCancel?: TransportRequest;
  onCancel: () => void;
  onRefuse: (reason: PredefinedOption, note?: string) => void;
  onRedate: (date: string) => void;
  refuseOptions: PredefinedOption[];
};

export const RefuseRequestModal: React.FC<Props> = ({
  requestToCancel,
  refuseOptions = [],
  onCancel,
  onRefuse,
  onRedate,
}) => {
  const { t } = useTranslation();

  // TODO: translate labels
  // const motivationOptions = [
  //   {label: 'Aggravamento paziente', value: RefuseReason.AGGRAVATION},
  //   {label: 'Visita annullata', value: RefuseReason.VISIT_CANCELED},
  //   {label: 'Visita spostata', value: RefuseReason.VISIT_REDATED},
  //   {label: 'Altro', value: RefuseReason.OTHER},
  // ];

  const initialValues = {
    wantsToRedate: false,
  } as RefuseFormValues;

  return (
    <Modal show={!!requestToCancel} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Annullamento della prenotazione</Modal.Title>
      </Modal.Header>
      <Modal.Body className="center-vertically">
        <Form
          initialValues={initialValues}
          validate={validateRefuseForm}
          onSubmit={(values) =>
            values.wantsToRedate
              ? onRedate(values.redate!) // validateRefuseForm grants values.redate presence
              : onRefuse(
                  refuseOptions.find(
                    (opt) => opt.id === values.motivation
                  ) as PredefinedOption,
                  values.motivationNote
                )
          }
          render={(formProps) => (
            <form>
              <RadioInput
                name="motivation"
                label={"Motivazione"}
                options={refuseOptions.map((option) => ({
                  label: capitalize(option.value).replace(/_/gi, " "),
                  value: option.id,
                }))}
              />
              <TextInput
                label="Note aggiuntive"
                name="motivationNote"
                placeholder="Inserisci una motivazione (opzionale)"
              />
              <div className="center-vertically">
                <Button
                  type="button"
                  variant="danger"
                  onClick={() => {
                    formProps.form.change("wantsToRedate", false);
                    formProps.handleSubmit();
                  }}
                  className={"submitFormBtt"}
                >
                  {t("common.confirm")}
                </Button>
                {/* {formProps.values.motivation === RefuseReason.VISIT_REDATED && (
                  <>
                    <div style={{margin: '1.6rem 0'}}>
                      &mdash; oppure &mdash;
                    </div>
                    <DateTimeInput
                      name="redate"
                      label="Inserisci una nuova data"
                    />
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        formProps.form.change('wantsToRedate', true);
                        formProps.handleSubmit();
                      }}
                      className={'submitFormBtt'}>
                      {t('changeDepartureDate')}
                    </Button>
                  </>
                )} */}
              </div>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};

const validateRefuseForm: ValidateForm<RefuseFormValues> = (values) => {
  if (!values.motivation) {
    return {
      motivation: "Indicare una motivazione",
    };
  }

  // if (
  //   values.motivation === RefuseReason.VISIT_REDATED &&
  //   values.wantsToRedate &&
  //   !values.redate
  // ) {
  //   // TODO: more check on date validity
  //   return {
  //     redate: 'Inserire una data valida',
  //   };
  // }

  return {};
};
