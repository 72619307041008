import React from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiArrowRight, mdiArrowLeftCircle } from "@mdi/js";
import { useStores } from "../hooks/useStores";

type Props = {
  goPrev?: () => void;
  goNext?: () => void;
  goNextLabel?: string;
  // TODO isConfirm
  disabled?: boolean;
};

export const WizardPaginationControls: React.FC<Props> = ({
  goPrev,
  goNext,
  goNextLabel,
  disabled,
}) => {
  const { t } = useTranslation();
  const { transports } = useStores();

  return (
    <div className="pagination-controls">
      {goPrev && (
        <Button
          type={"button"}
          onClick={goPrev}
          disabled={disabled}
          className={"back-btt"}
        >
          <Icon path={mdiArrowLeftCircle} title={t("common.goBack")} />
        </Button>
      )}
      {goNext && (
        <Button
          type={"button"}
          variant="primary"
          onClick={goNext}
          // disabled={disabled || !transports.vehiclesAvailablePublic}
          className={"submitFormBtt"}
        >
          <div>
            {goNextLabel || t("common.continue")}
            <Icon path={mdiArrowRight} />
          </div>
        </Button>
      )}
    </div>
  );
};
