import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";

import { PredefinedOption } from "../types";
import { ToggleInputList } from "./ToggleInputList";
import { poValueToLabel } from "../utils/predefinedOptions";

export type RefuseValues = {
  reasons: Array<PredefinedOption["value"]>;
};

type Props = {
  show: boolean;
  quotationCancelReasons: PredefinedOption[];
  onHide: () => void;
  onSubmit: (values: RefuseValues) => void;
};

export const RefuseQuotationModal: React.FC<Props> = ({
  show,
  onHide,
  onSubmit,
  quotationCancelReasons,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby={"refuse-quotation-modal"}
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={"refuse-quotation-modal"}>
          {t("quotation.refuseQuotation")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <strong>{t("quotation.confirmRefuseQuotation")}</strong>
        </p>
        <p>{t("quotation.refuseQuotationMotivation")}</p>
        <Form
          initialValues={{}}
          onSubmit={onSubmit}
          render={(formProps) => (
            <form
              className="center-vertically"
              onSubmit={formProps.handleSubmit}
            >
              <ToggleInputList
                name="reasons"
                options={quotationCancelReasons.map((option) => ({
                  value: option.value,
                  label: poValueToLabel(option.value),
                }))}
                block={true}
              />

              <Button className="submitFormBtt" variant="primary" type="submit">
                {t("quotation.button")}
              </Button>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};
