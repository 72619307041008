import React, { useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import { Form as BSForm, Button } from "react-bootstrap";
// NOTE: Google Maps API libraries will be loaded
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import Icon from "@mdi/react";
import { mdiMapMarker } from "@mdi/js";

import {
  FullAddress,
  mapGeocoderResultToFullAddress,
  maps,
} from "../services/maps";

import { ErrorLabel } from "./ErrorLabel";
import { useTranslation } from "react-i18next";

export type FullAddressInputSuggestion = Omit<FullAddress, "coords"> & {
  name?: string;
  /**
   * If set this id will be set on the field with the name `names.id`.
   * This can be useful for autofill from user suggestions, which will likely
   * come from its saved places.
   */
  id?: string;
};

export type AddressInputSuggestions = FullAddressInputSuggestion[];

type Props = {
  name: string;
  names: {
    address: string;
    city: string;
    province: string;
    country: string;
    zipCode: string;
    /** @see FullAddressInputSuggestion.id for a use case  */
    id?: string;
  };
  label?: string;
  placeholder?: string;
  // Predefined options, for example saved addresses of the user
  suggestions?: AddressInputSuggestions;
  onSelect?: (address: string) => void;
  onSelected?: (address: string) => void;
  disabled?: boolean;
};

/**
 * Address input form field, with autocompletion from Google Places.
 * On submit or when picking an address from the suggestions calls `onSelect`,
 * useful for updating the map marker position.
 */
export const AddressInput: React.FC<Props> = ({
  name,
  names,
  label,
  placeholder,
  suggestions: userSuggestions,

  disabled,
}) => {
  const { t } = useTranslation();
  const form = useForm();

  const addressField = useField(names.address);
  const cityField = useField(names.city);
  const provinceField = useField(names.province);

  const [placesSuggestionsPersonal, setPlacesSuggestionsPersonal] =
    useState<FullAddress[]>();
  const [isLoading, setIsLoading] = useState(false);

  const [isChanging, setIsChanging] = useState(false);

  let address: string =
    addressField.input.value && addressField.input.value !== undefined
      ? addressField.input.value + ", "
      : "";
  let city: string =
    cityField.input.value && cityField.input.value !== undefined
      ? cityField.input.value + ", "
      : "";
  let province: string =
    provinceField.input.value && provinceField.input.value !== undefined
      ? provinceField.input.value
      : "";

  const [value, setValue] = useState<string>(address + city + province);
  const [hasChoosen, setHasChoosen] = useState(false);
  const [isHoverSuggestions, setIsHoverSuggestions] = useState(false);

  return (
    <Field
      name={name}
      render={({ input }) => {
        const onSelectPlace = async (address1: string, placeId: string) => {
          const suggestion = await geocodeByPlaceId(placeId);
          const result = mapGeocoderResultToFullAddress(suggestion[0]);

          form.batch(() => {
            form.change(names.address, result.address);
            form.change(names.city, result.city);
            form.change(names.country, result.country);
            form.change(names.province, result.province);
            form.change(names.zipCode, result.zipCode);
            if (names.id && placeId) {
              form.change(names.id, placeId);
            }
          });

          let address =
            result.address && result.address !== undefined
              ? result.address + ", "
              : "";
          let city =
            result.city && result.city !== undefined ? result.city + ", " : "";
          let province =
            result.province && result.province !== undefined
              ? result.province
              : "";

          setValue(address + city + province);
          setHasChoosen(true);
          setPlacesSuggestionsPersonal([]);
        };

        const onSelectPlaceCustom = async (suggestion: any) => {
          form.batch(() => {
            form.change(names.address, suggestion.address);
            form.change(names.city, suggestion.city);
            form.change(names.country, suggestion.country);
            form.change(names.province, suggestion.province);
            form.change(names.zipCode, suggestion.zipCode);
            if (names.id && suggestion.id) {
              form.change(names.id, suggestion.id);
            }
          });

          let address =
            suggestion.address && suggestion.address !== undefined
              ? suggestion.address + ", "
              : "";
          let city =
            suggestion.city && suggestion.city !== undefined
              ? suggestion.city + ", "
              : "";
          let province =
            suggestion.province && suggestion.province !== undefined
              ? suggestion.province
              : "";

          setValue(address + city + province);
          setHasChoosen(true);
          setPlacesSuggestionsPersonal([]);
        };

        const onLocalizeMyPosition = async () => {
          setPlacesSuggestionsPersonal(undefined);
          setIsLoading(true);
          try {
            const position = await maps.locateMyPosition();
            if (position) {
              const consistentPositions = position.filter(
                (item) =>
                  item.address &&
                  item.city &&
                  item.country &&
                  item.province &&
                  item.zipCode
              );

              setPlacesSuggestionsPersonal(consistentPositions);
              setIsLoading(false);
            }
          } catch (err: any) {
            setPlacesSuggestionsPersonal([]);
            setIsLoading(false);
          }
        };

        let shouldShowAutocompleteList =
          isLoading ||
          (!hasChoosen &&
            (addressField.meta.active ||
              isHoverSuggestions ||
              placesSuggestionsPersonal?.length));

        const shouldShowAutocompleteListUerSuggestion =
          !hasChoosen && (addressField.meta.active || isHoverSuggestions);

        return (
          <BSForm.Group className="autocomplete--container">
            {label && <BSForm.Label>{label}</BSForm.Label>}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PlacesAutocomplete
                value={value}
                onChange={(value) => {
                  setValue(value);
                  setHasChoosen(false);
                  if (value === "") {
                    setIsChanging(false);
                  } else {
                    setIsChanging(true);
                  }
                }}
                onSelect={onSelectPlace}
                debounce={500}
                children={({
                  getInputProps,
                  getSuggestionItemProps,
                  suggestions: placesSuggestions,
                  loading,
                }) => {
                  if (placesSuggestions) {
                    shouldShowAutocompleteList = false;
                  }
                  return (
                    <div className="autocomplete-root" style={{ flexGrow: 1 }}>
                      <BSForm.Control
                        {...getInputProps()}
                        onFocus={addressField.input.onFocus}
                        onBlur={addressField.input.onBlur}
                        placeholder={placeholder}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && (
                          <div className="autocomplete--list-item">
                            {t("common.loading")}...
                          </div>
                        )}
                        {placesSuggestions.map((suggestion) => (
                          <div
                            className="autocomplete--list-item"
                            {...getSuggestionItemProps(suggestion)}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                }}
              />

              <Button
                size="sm"
                type="button"
                variant="outline-secondary"
                onClick={onLocalizeMyPosition}
                disabled={disabled}
              >
                <Icon path={mdiMapMarker} size={1} />
              </Button>
            </div>

            {!isChanging && (
              <div
                className={`autocomplete--root ${
                  shouldShowAutocompleteList ? "show" : ""
                }`}
                onMouseOver={() => setIsHoverSuggestions(true)}
                onMouseOut={() => setIsHoverSuggestions(false)}
                style={{ flexGrow: 1 }}
              >
                {isLoading && (
                  <div className="autocomplete--list-item">
                    {t("common.loading")}...
                  </div>
                )}
                {!isLoading && placesSuggestionsPersonal?.length
                  ? placesSuggestionsPersonal &&
                    placesSuggestionsPersonal.map((suggestion: any) => (
                      <div
                        className="autocomplete-root"
                        style={{ border: "1px dotted lightgrey" }}
                        key={suggestion.id}
                      >
                        <div
                          onClick={() => onSelectPlaceCustom(suggestion)}
                          className="autocomplete--list-item"
                        >
                          {suggestion.name && suggestion.name != undefined && (
                            <em>{suggestion.name} - </em>
                          )}
                          {suggestion.address &&
                            suggestion.address != undefined && (
                              <span>
                                {suggestion.address} {", "}
                              </span>
                            )}
                          {suggestion.city && suggestion.city != undefined && (
                            <span>
                              {suggestion.city} {", "}
                            </span>
                          )}
                          {suggestion.province &&
                            suggestion.province != undefined && (
                              <span>{suggestion.province}</span>
                            )}
                        </div>
                      </div>
                    ))
                  : !isLoading &&
                    shouldShowAutocompleteListUerSuggestion &&
                    userSuggestions &&
                    userSuggestions.map((suggestion: any) => (
                      <div
                        className="autocomplete-root"
                        style={{ border: "1px dotted lightgrey" }}
                        key={suggestion.id}
                      >
                        <div
                          onClick={() => onSelectPlaceCustom(suggestion)}
                          className="autocomplete--list-item"
                        >
                          {suggestion.name && suggestion.name != undefined && (
                            <em>{suggestion.name} - </em>
                          )}
                          {suggestion.address &&
                            suggestion.address != undefined && (
                              <span>
                                {suggestion.address} {", "}
                              </span>
                            )}
                          {suggestion.city && suggestion.city != undefined && (
                            <span>
                              {suggestion.city} {", "}
                            </span>
                          )}
                          {suggestion.province &&
                            suggestion.province != undefined && (
                              <span>{suggestion.province}</span>
                            )}
                        </div>
                      </div>
                    ))}
              </div>
            )}

            <ErrorLabel name={names.address} />
          </BSForm.Group>
        );
      }}
    />
  );
};
