import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { TFunction, useTranslation } from "react-i18next";
import { PredefinedOption, Quotation } from "../types";
import Icon from "@mdi/react";
import { mdiCash, mdiClose } from "@mdi/js";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { ErrorBoundary } from "react-error-boundary";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { stripePromise } from "../services/stripe";
import { Stripe, StripeElements } from "@stripe/stripe-js/types/stripe-js";
import ConfirmedPic from "../assets/images/checked.png";

export type RefuseValues = {
  reasons: Array<PredefinedOption["value"]>;
};

type Props = {
  show: boolean;
  quotation?: Quotation;
  quotationId?: string;
  onHide: () => void;
};

export const PaymentModal: React.FC<Props> = ({
  show,
  quotation,
  quotationId,
  onHide,
}) => {
  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();
  const [paypalError, setPaypalError] = useState(false);
  const [paymentError, setPaymentError] = useState("");

  const [{ isPending, isRejected }] = usePayPalScriptReducer();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = 'https://staging.online.satispay.com/web-button.js';
    // // script['data-payment-id'] = '8f27fbbc-ff4b-45eb-98d0-1cadb3a0afaa';
    // script.async = true;
    // document.body.appendChild(script);
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  const handleSubmitSatispay = () => {
    // var satispay = SatispayWebButton.configure({
    //   paymentId: '8f27fbbc-ff4b-45eb-98d0-1cadb3a0afaa',
    //   completed: function () {
    //     // executed on payment success or failure
    //   },
    // });
  };

  return (
    <Modal
      aria-labelledby={"confirm-quotation-modal"}
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id={"confirm-quotation-modal"}>
          {paymentSuccess
            ? t("quotation.payWithSuccess")
            : t("quotation.confirmQuotation")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {paymentSuccess ? (
          <>
            <div className={"confirmation-container"}>
              <img src={ConfirmedPic} alt={"Payment success"} />
            </div>
            <div className="text-center">
              <Button variant="primary" onClick={onHide}>
                {t("common.close")}
              </Button>
            </div>
          </>
        ) : (
          <>
            {paymentError && (
              <p className={"form--error-label"}>{paymentError}</p>
            )}
            <ErrorBoundary
              FallbackComponent={(err) => {
                return (
                  <p className={"form--error-label"}>
                    {t("quotation.paypalError")}
                  </p>
                );
              }}
            >
              {quotation?.cost && (
                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            // value: quotation.cost.toString(),
                            value: "0.01",
                          },
                        },
                      ],
                    });
                  }}
                  onError={(err) => {
                    setPaypalError(true);
                  }}
                  onApprove={(data, actions) => {
                    if (actions.order) {
                      return actions.order.capture().then((details) => {
                        setPaymentSuccess(true);
                      });
                    } else {
                      return new Promise(() => {
                        setPaymentError(t("quotation.paymentError"));
                      });
                    }
                  }}
                  style={{ layout: "horizontal" }}
                  disabled={isPending || isRejected}
                />
              )}
            </ErrorBoundary>
            {(paypalError || isRejected || !quotation?.cost) && (
              <p className={"form--error-label"}>
                {t("quotation.paypalError")}
              </p>
            )}
            <form
              action={`https://test.hagile.it/checkoutsession.aspx?idpreventivo=${quotationId}`}
              method="POST"
            >
              <button type="submit">Carta di credito</button>
            </form>
            {/* <Button type="button" variant="primary" className="submitFormBtt" onClick={handlePayWithStripe}>
              Carta di credito
              <Icon path={mdiCash} />
            </Button> */}
            {/* 
        <img
          src="https://online.satispay.com/images/en-pay-red.svg"
          alt="Pay with Satispay"
          id="pay-with-satispay"
          style="height: 50px; cursor: pointer;"
        /> */}
            {/* <ErrorBoundary
              FallbackComponent={() => {
                return (
                  <p className={'form--error-label'}>
                    {t('quotation.stripeError')}
                  </p>
                );
              }}>
             <CheckoutForm stripe={stripe} elements={elements} t={t} />
            </ErrorBoundary> */}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

interface CheckoutFormProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  t: TFunction;
}

const CheckoutForm: React.FunctionComponent<CheckoutFormProps> = ({
  stripe,
  elements,
  t,
}) => {
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (elements == null || stripe == null) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements}>
        {t("quotation.payWithStripe")}
      </button>
    </form>
  );
};
