import React from "react";
import { Spinner } from "react-bootstrap";

type Props = {
  description?: string;
};

export const LoadingMessage: React.FC<Props> = ({ description }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexFlow: "column",
        padding: "4rem 2rem",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner animation="border" role="status" />
      <br />
      {description && <strong>{description}</strong>}
    </div>
  );
};
