import React, { useState } from "react";
import { observer } from "mobx-react";
import { Form } from "react-final-form";
import { Form as BSForm, Container, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Redirect, Link } from "react-router-dom";
import { mdiAccount, mdiAccountTie } from "@mdi/js";

import { useStores } from "../hooks/useStores";
import { ValidateForm, FormErrors, UserRole } from "../types";

import { TextInput } from "../components/TextInput";
import {
  TabbedRadioInputSeparator,
  TabbedRadioInputContainer,
  TabbedRadioInput,
} from "../components/TabbedRadioInput";

type LoginValues = {
  email: string;
  password: string;
  role: UserRole;
};

export const LoginScreen: React.FC = observer((props) => {
  const { session, transports } = useStores();
  const { t } = useTranslation();

  const [isLogging, setIsLoggging] = useState(false);

  const handleSubmit = (values: LoginValues) => {
    // TODO: where to use the role?
    setIsLoggging(true);
    session
      .login(values.email, values.password)
      .catch((err) => {
        console.error("Failed login", err);
        window.alert(err.message);
      })
      .then(() => setIsLoggging(false));
  };

  if (session.isLogged) {
    // If the not signed user was drafting a transport request, bring him back there
    if (transports.requestDraft) {
      return <Redirect to={{ pathname: "/transport", state: { stage: 1 } }} />;
    }
    return <Redirect to={"/"} />;
  }

  return (
    <Container className="main--container">
      <div style={{ margin: "0 auto", width: "100%", maxWidth: "340px" }}>
        <Form
          initialValues={{ role: UserRole.USER }}
          validate={validateForm}
          onSubmit={handleSubmit}
          render={(formProps) => (
            <BSForm onSubmit={formProps.handleSubmit}>
              <TabbedRadioInputContainer>
                <TabbedRadioInput
                  name="role"
                  value={UserRole.USER}
                  label={t("session.forUser")}
                  icon={mdiAccount}
                  iconSize={1.5}
                  filled
                />
                <TabbedRadioInputSeparator />
                <TabbedRadioInput
                  name="role"
                  value={UserRole.PARTNER}
                  label={t("session.forPartner")}
                  icon={mdiAccountTie}
                  iconSize={1.5}
                  filled
                />
              </TabbedRadioInputContainer>
              <h2 className="text-center" style={{ margin: "3rem 0 1.5rem" }}>
                {t("session.login")}
              </h2>
              <TextInput
                name="email"
                type="email"
                label={t("session.email")}
                placeholder="mario.rossi@example.com"
                autocomplete="username"
              />
              <TextInput
                name="password"
                type="password"
                label={t("session.password")}
                autocomplete="current-password"
              />
              <Button
                variant="primary"
                type="submit"
                block
                disabled={
                  (formProps.dirtySinceLastSubmit &&
                    formProps.hasValidationErrors) ||
                  isLogging
                }
              >
                {t("session.login")}
                {isLogging && (
                  <>
                    &nbsp;
                    <Spinner animation="border" size="sm" />
                  </>
                )}
              </Button>
              <br />
              <br />
              {formProps.values.role === UserRole.USER && (
                <div className="text-center">
                  <Link to="/registration">
                    <h4>
                      <small>{t("common.or")} </small>
                      {t("session.signMeUp")}
                    </h4>
                  </Link>
                </div>
              )}
              {formProps.values.role === UserRole.PARTNER && (
                <p className="text-center">
                  {t("session.notRegisteredPartner")}
                </p>
              )}
            </BSForm>
          )}
        />
      </div>
    </Container>
  );
});

const validateForm: ValidateForm<LoginValues> = (values) => {
  const errors: FormErrors<LoginValues> = {};
  if (!values.role) {
    errors.role = "Selezionare il tipo di account";
  }
  if (!values.email) {
    errors.email = "L'email non può essere vuota";
  }
  if (!values.password) {
    errors.password = "La password non può essere vuota";
  }
  return errors;
};
