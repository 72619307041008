import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { mdiAccount, mdiAccountPlus } from "@mdi/js";

import { useStores } from "../../hooks/useStores";

import {
  TabbedRadioInputContainer,
  TabbedRadioInput,
  TabbedRadioInputSeparator,
} from "../../components/TabbedRadioInput";

export function withLoginGuard<P>(Component: React.ComponentType<P>) {
  const guarded: React.FC<P> = observer((props) => {
    const { session } = useStores();

    if (!session.isLogged) {
      return <LoginGuardStageForm />;
    } else {
      return <Component {...props} />;
    }
  });
  return guarded;
}

export const LoginGuardStageForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="login-guard-stage-form--container">
      <h2>{t("common.patient")}</h2>
      <p>{t("transport.loginGuardDescription")}</p>
      <div className="form-container-sm">
        <TabbedRadioInputContainer>
          <Link to="/login" className="secondary-link">
            <TabbedRadioInput
              name="access"
              value="login"
              icon={mdiAccount}
              iconSize={2}
              label={
                <p style={{ textAlign: "center" }}>
                  <strong>{t("common.enter")}</strong>
                  <br />
                  {t("session.alreadySignedUp")}
                </p>
              }
            />
          </Link>
          <TabbedRadioInputSeparator />
          <Link to="/registration" className="secondary-link">
            <TabbedRadioInput
              name="access"
              value="register"
              icon={mdiAccountPlus}
              iconSize={2}
              label={
                <p style={{ textAlign: "center" }}>
                  <strong>{t("session.signMeUp")}</strong>
                  <br />
                  {t("session.wantToRegister")}
                </p>
              }
            />
          </Link>
        </TabbedRadioInputContainer>
      </div>
    </div>
  );
};
