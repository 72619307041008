import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Container, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiCancel, mdiSync } from "@mdi/js";

import history from "../history";
import { TransportRequest } from "../models/TransportRequest";
import { formatDateTime } from "../utils/date";
import { useStores } from "../hooks/useStores";

import { ReplicateRequestModal } from "../components/ReplicateRequestModal";
import { RefuseRequestModal } from "../components/RefuseRequestModal";
import {
  StageStatusSection,
  StageStatusHeader,
  StageStatusList,
} from "../components/StageStatus";
import { AllPredefinedOptions } from "../types";
import { makePlaceStatusValues } from "../utils/stageStatus";
import { LoadingMessage } from "../components/LoadingMessage";
import { poValueToLabel } from "../utils/predefinedOptions";

export const UserBookingDetails: React.FC = observer(() => {
  const [requestToReplicate, setRequestToReplicate] =
    useState<TransportRequest>();
  const [requestToCancel, setRequestToCancel] = useState<TransportRequest>();
  const [request, setRequest] = useState<TransportRequest>();

  const params = useParams<{ bookingId: string }>();
  const { transports, session } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    if (!params.bookingId) {
      return history.replace("/reserved-area/bookings");
    }

    session.fetchAllPredefinedOptions().catch(console.warn);

    transports
      .fetchTransportRequest(params.bookingId)
      .then(setRequest)
      // TODO: Show error when request not found
      .catch(console.log);
  }, [transports, params, session]);

  const isLoading = !request;

  console.log(request);

  return (
    <Container>
      <div className="main-title">
        <h4 style={{ flexGrow: 1 }}>{t("bookings.bookingDetails")}</h4>

        <Button
          variant="outline-secondary"
          onClick={() => setRequestToReplicate(request)}
          disabled={isLoading}
        >
          <Icon
            path={mdiSync}
            title={t("common.replicate")}
            size={1}
            className="mr-2"
          />
          {t("common.replicate")}
        </Button>
        {request?.status !== "RIFIUTATO" && (
          <Button
            variant="danger"
            onClick={() => setRequestToCancel(request)}
            disabled={isLoading || !request?.isCancelable}
          >
            {t("common.cancel")}
            <Icon
              path={mdiCancel}
              title={t("common.cancel")}
              size={1}
              className="ml-2"
            />
          </Button>
        )}
      </div>
      <div style={{ height: "1rem" }} />

      {!request && <LoadingMessage />}
      {request && (
        <TransportRequestDetails
          request={request}
          allPredefinedOptions={session.predefinedOptions}
        />
      )}

      <ReplicateRequestModal
        requestToReplicate={requestToReplicate}
        onCancel={() => setRequestToReplicate(undefined)}
        onReplicate={(values) =>
          history.push(
            `/transport/new?replicateFromId=${values.requestId}&replicateDepartureTime=${values.departureTime}`
          )
        }
      />

      <RefuseRequestModal
        requestToCancel={requestToCancel}
        onCancel={() => setRequestToCancel(undefined)}
        onRedate={(date) => requestToCancel?.redate(date)}
        onRefuse={(motivation, note) => {
          requestToCancel?.refuse(motivation, note);
          history.replace("/reserved-area/bookings");
        }}
        refuseOptions={session.predefinedOptions.quotationCancelReason}
      />
    </Container>
  );
});

const TransportRequestDetails: React.FC<{
  request: TransportRequest;
  allPredefinedOptions: AllPredefinedOptions;
}> = ({ request, allPredefinedOptions }) => {
  const { t } = useTranslation();

  return (
    <div className="stage-status--container">
      <StageStatusSection>
        <StageStatusHeader
          title={t("common.status")}
          titleValue={request.status || ""}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader
          title={t("common.code")}
          titleValue={request.data.number || request.id}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader title={t("transport.stages.route")} />
        <StageStatusList
          values={[
            {
              label: t("trip.from"),
              value: request.data.departurePlace.address,
            },
            { label: t("trip.to"), value: request.data.arrivalPlace.address },
            {
              label: t("trip.departure"),
              value: formatDateTime(request.data.trip.departureTime),
            },
          ]}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader
          title={t("transport.stages.patient")}
          titleValue={`${request.data.patient.name} ${request.data.patient.surname}`}
        />
        <StageStatusList
          values={[
            {
              label: t("person.bornOn"),
              value: request.data.patient.birthDate,
            },
            {
              label: t("person.bornAt"),
              value: request.data.patient.birthPlace,
            },
            {
              label: t("person.place"),
              value: request.data.patient.residentialAddress,
            },
            {
              label: t("person.fiscalCode"),
              value: request.data.patient.fiscalCode,
            },
          ]}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader title={t("transport.stages.transport")} />
        <StageStatusList
          values={[
            {
              label: t("trip.type"),
              value: request.data.trip.reason
                ? poValueToLabel(request.data.trip.reason)
                : "",
            },
            {
              label: t("trip.reason"),
              value:
                request.data.trip.examinations &&
                request.data.trip.examinations
                  .map((e) => poValueToLabel(e))
                  .join(", "),
            },
          ]}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader title={t("transport.stages.departurePlace")} />
        <StageStatusList
          values={makePlaceStatusValues(
            request.data.departurePlace,
            allPredefinedOptions
          )}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader title={t("transport.stages.arrivalPlace")} />
        <StageStatusList
          values={makePlaceStatusValues(
            request.data.arrivalPlace,
            allPredefinedOptions
          )}
        />
      </StageStatusSection>

      <StageStatusSection>
        <StageStatusHeader title={t("transport.stages.notes")} />
        <StageStatusList
          values={[{ label: t("common.note"), value: request.data.trip.notes }]}
        />
      </StageStatusSection>
    </div>
  );
};
