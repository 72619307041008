import React from "react";
import { useTranslation } from "react-i18next";
import {
  mdiMapMarkerDistance,
  mdiAccount,
  mdiAmbulance,
  mdiMapMarkerRadius,
  mdiOfficeBuilding,
  mdiMessageText,
} from "@mdi/js";
import { observer } from "mobx-react";

import { useStores } from "../../hooks/useStores";
import { formatDateTime, formatDate } from "../../utils/date";

import {
  StageStatusList,
  StageStatusHeader,
  StageStatusSection,
  StageStatusSectionSeparator,
} from "../../components/StageStatus";

import { lastStage } from "./TransportRequestWizard";
import { poValueToLabel } from "../../utils/predefinedOptions";
import { makePlaceStatusValues } from "../../utils/stageStatus";

type Props = {
  currentStage: number;
  onChangeStage: (stage: number) => void;
};

const STAGES = [
  {
    key: "route",
    steps: [0],
    icon: mdiMapMarkerDistance,
  },
  {
    key: "patient",
    steps: [1, 2, 3],
    icon: mdiAccount,
  },
  {
    key: "transport",
    steps: [4],
    icon: mdiAmbulance,
  },
  {
    key: "departurePlace",
    steps: [5],
    icon: mdiMapMarkerRadius,
  },
  {
    key: "arrivalPlace",
    steps: [6],
    icon: mdiOfficeBuilding,
  },
  {
    key: "notes",
    steps: [7],
    icon: mdiMessageText,
  },
];

export const TransportRequestStatus: React.FC<Props> = observer(
  ({ currentStage, onChangeStage }) => {
    const { transports, session } = useStores();
    const { t } = useTranslation();

    const renderStageData = (stageKey: string) => {
      if (!transports.requestDraft) {
        return null;
      }

      const values = transports.requestDraft.values;
      let component;
      switch (stageKey) {
        case "route":
          // let name_departure =
          //   values.departurePlace?.name &&
          //   values.departurePlace?.name !== undefined
          //     ? values.departurePlace?.name + ' - '
          //     : '';
          // let address_departure =
          //   values.departurePlace?.address &&
          //   values.departurePlace?.address !== undefined
          //     ? values.departurePlace?.address + ', '
          //     : '';
          // let city_departure =
          //   values.departurePlace?.city &&
          //   values.departurePlace?.city !== undefined
          //     ? values.departurePlace?.city + ', '
          //     : '';
          // let province_departure =
          //   values.departurePlace?.province &&
          //   values.departurePlace?.province !== undefined
          //     ? values.departurePlace?.province
          //     : '';

          // let name_arrivalPlace =
          //   values.arrivalPlace?.name && values.arrivalPlace?.name !== undefined
          //     ? values.arrivalPlace?.name + ' - '
          //     : '';
          // let address_arrivalPlace =
          //   values.arrivalPlace?.address &&
          //   values.arrivalPlace?.address !== undefined
          //     ? values.arrivalPlace?.address + ', '
          //     : '';
          // let city_arrivalPlace =
          //   values.arrivalPlace?.city && values.arrivalPlace?.city !== undefined
          //     ? values.arrivalPlace?.city + ', '
          //     : '';
          // let province_arrivalPlace =
          //   values.arrivalPlace?.province &&
          //   values.arrivalPlace?.province !== undefined
          //     ? values.arrivalPlace?.province
          //     : '';

          component = (
            <StageStatusList
              values={[
                // TODO: convert constant values to i18n strings
                // {
                //   label: t('trip.from'),
                //   value:
                //     name_departure +
                //     address_departure +
                //     city_departure +
                //     province_departure,
                // },
                // {
                //   label: t('trip.to'),
                //   value:
                //     name_arrivalPlace +
                //     address_arrivalPlace +
                //     city_arrivalPlace +
                //     province_arrivalPlace,
                // },
                {
                  label: t("trip.departure"),
                  value:
                    values.trip.departureTime &&
                    formatDateTime(values.trip.departureTime),
                },
                {
                  label: t("trip.roundTrip"),
                  value: values.trip?.roundTrip
                    ? t("trip.roundTripYes")
                    : t("trip.roundTripNo"),
                },
              ]}
            />
          );
          break;
        case "patient":
          component = (
            <StageStatusList
              values={[
                {
                  label: t("person.bornOn"),
                  value:
                    values.patient?.birthDate &&
                    formatDate(values.patient.birthDate),
                },
                {
                  label: t("person.bornAt"),
                  value: values.patient?.birthPlace,
                },
                {
                  label: t("person.place"),
                  value: values.patient?.residentialAddress,
                },
                {
                  label: t("person.fiscalCode"),
                  value: values.patient?.fiscalCode,
                },
              ]}
            />
          );
          break;
        case "transport":
          component = (
            <StageStatusList
              values={[
                {
                  label: t("trip.reason"),
                  value: values.trip.reason
                    ? poValueToLabel(values.trip.reason)
                    : "",
                },
                // {
                //   label: t('trip.examinations'),
                //   value:
                //     values.trip.examinations &&
                //     values.trip.examinations &&
                //     poValueToLabel(values.trip.examinations),
                // },
              ]}
            />
          );
          break;
        case "departurePlace":
          component = (
            <StageStatusList
              values={makePlaceStatusValues(
                values.departurePlace || {},
                session.predefinedOptions
              )}
            />
          );
          break;
        case "arrivalPlace":
          component = (
            <StageStatusList
              values={makePlaceStatusValues(
                values.arrivalPlace || {},
                session.predefinedOptions
              )}
            />
          );
          break;
        case "notes":
          component = (
            <StageStatusList
              values={[{ label: t("common.note"), value: values.trip.notes }]}
            />
          );
          break;
      }

      return component;
    };

    return (
      <div className="stage-status--container">
        {STAGES.map((stage) => (
          <>
            <StageStatusSection key={stage.key}>
              <StageStatusHeader
                icon={stage.icon}
                title={t(`transport.stages.${stage.key}`)}
                active={stage.steps.includes(currentStage)}
                // Disable stage > current to prevent the user from jumping back
                // and forth and leaving a previous stage with invalid data
                disabled={stage.steps[0] > currentStage}
                onClick={() => onChangeStage(stage.steps[0])}
              />
              {renderStageData(stage.key)}
            </StageStatusSection>
            <StageStatusSectionSeparator />
          </>
        ))}
      </div>
    );
  }
);
